<template>
  <div class="fill-height mb-12">
    <DialogAuth :dialogauth="dialog.auth" @close="dialog.auth = ''" />
    <DialogMessage :dialogmessage="dialog.message" :message="message" @close="dialog.message = ''" />

    <v-dialog v-model="dialog.share"
      width="375"
      persistent>
      <v-card 
        color="white"
        class="border-radius pa-5">
        <v-list-item class="pa-0">
          <v-list-item-avatar size="50" class="ma-0">
            <v-icon large class="mr-1">mdi-share-variant-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="body-1 font-weight-bold text-second">
              Share {{ detail.kelas_nama }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="mt-1 text-third">
              Klik icon di bawah untuk share event ini!
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-row justify="center" align="center" class="pt-3 pb-5">
          <v-col cols="3" class="text-center"
            v-for="(item, index) in sosmed"
            :key="index">
            <v-avatar
              tile
              width="50"
              height="50">
              <ShareNetwork
                :network="item.network"
                :url="`${host}${$route.path}`"
                :title="detail.kelas_slug"
                :description="detail.kelas_nama"
                hashtags="e-learning,bidankita">
                <v-img
                  width="45"
                  height="45"
                  contain
                  alt="e-learning"
                  :src="item.icon">
                </v-img>
              </ShareNetwork>
            </v-avatar>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn
            rounded
            block
            color="#333333"
            elevation="3"
            class="body-2 font-weight-bold white--text text-capitalize"
            @click="dialog.share = false">
            Tidak Sekarang
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="dialog.snackbar"
      top
      color="#333333"
      vertical>
      <div class="body-1 font-weight-light white--text"
        v-html="dialog.stock">
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          class="body-1 text-capitalize"
          color="red"
          text
          v-bind="attrs"
          @click="dialog.snackbar = false">
          Ya, Saya mengerti
        </v-btn>
      </template>
    </v-snackbar>

    <v-layout wrap>
      <v-flex>
        <section>
          <v-container class="pa-0">
            <v-row>
              <v-col>
                <v-card flat>
                  <v-card-text class="pb-0">
                    <v-alert v-if="detail.kelas_terkait_note.length > 0"
                      text
                      dense
                      color="info"
                      icon="mdi-information-outline"
                      border="left">
                      {{ detail.kelas_terkait_note }}
                    </v-alert>

                    <div class="font-weight-bold text-second">
                      <v-icon>mdi-seal</v-icon>
                      {{ detail.kelas_type_title }}
                      <v-icon small>mdi-chevron-double-right</v-icon>
                      {{ detail.kelas_kategori_title }}
                      <span v-if="detail.kelas_kategori_child_title !== ''">
                        <v-icon small>mdi-chevron-double-right</v-icon>
                        {{ detail.kelas_kategori_child_title }}
                      </span>
                    </div>
                  </v-card-text>
                  
                  <v-card-title class="text-capitalize">
                    {{ detail.kelas_nama }}
                  </v-card-title>

                  <v-card-text class="body-1 font-weight-light line-text-second">
                    <span v-html="detail.kelas_deskripsi"></span>
                  </v-card-text>

                  <v-card-text v-if="detail.kelas_terkait_elearning.length > 0 || detail.kelas_terkait_zoom.length > 0"
                    class="body-1 font-weight-light font-italic text-decoration-underline py-0">
                    #Dengan membeli kelas ini Anda akan memdapatkan harga khusus / spesial untuk kelas terkait.
                  </v-card-text>

                  <v-card-text class="py-0" v-if="detail.kelas_terkait_zoom.length > 0">
                    <v-row>
                      <v-col cols="12">
                        <p class="title font-weight-bold mb-0">
                          <span class="color-first mr-1">
                            Zoom
                          </span>
                          Class Terkait
                        </p>

                        <p class="body-1 font-weight-light text-second mb-0">
                          Zoom class lainnya yang berkaitan dengan kelas ini dengan penawaran menarik.
                        </p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="2" sm="6"
                        v-for="(item, index) in detail.kelas_terkait_zoom"
                        :key="index"
                        v-show="index < 4">
                        <v-card
                          class="border-radius box-shadow"
                          :to="`/streaming/${item.kelas_slug}`">
                          <v-img
                            width="100%"
                            height="150"
                            :src="item.kelas_image_cover_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div class="pa-2">
                            <v-card-text class="pa-2">
                              <p class="body-1 font-weight-bold line-text-first text-first text-capitalize mb-0" style="height: 25px;">
                                <span class="one-line">
                                  {{ item.kelas_nama }}
                                </span>
                              </p>
                              <p class="body-2 text-second mb-0">
                                <span class="one-line">
                                  {{ item.kelas_kategori_title }}
                                </span>
                              </p>
                            </v-card-text>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row v-if="detail.kelas_terkait_zoom.length > 6">
                      <v-col cols="12" class="text-right">
                        <nuxt-link
                          class="color-first"
                          to="/streaming">
                          <span>
                            Lihat kelas lainnya
                          </span>
                          <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                        </nuxt-link>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text class="pt-0 pb-2" v-if="detail.kelas_terkait_elearning.length > 0">
                    <v-row>
                      <v-col cols="12">
                        <p class="title font-weight-bold mb-0">
                          <span class="color-first mr-1">
                            Kelas
                          </span>
                          E-Learning Terkait
                        </p>

                        <p class="body-1 font-weight-light text-second mb-0">
                          Kelas e-learning lainnya yang berkaitan dengan kelas ini dengan penawaran menarik.
                        </p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="2" sm="6"
                        v-for="(item, index) in detail.kelas_terkait_elearning"
                        :key="index"
                        v-show="index < 4">
                        <v-card
                          class="border-radius box-shadow"
                          href="https://elearning.bidankita.com/elearning/${item.kelas_slug}"
                          target="_blank">
                          <v-img
                            width="100%"
                            height="150"
                            :src="item.kelas_image_cover_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div class="pa-2">
                            <v-card-text>
                              <p class="body-1 font-weight-bold line-text-first text-first text-capitalize mb-0" style="height: 25px;">
                                <span class="one-line">
                                  {{ item.kelas_nama }}
                                </span>
                              </p>
                              <p class="body-2 text-second mb-0">
                                <span class="one-line">
                                  {{ item.kelas_kategori_title }}
                                </span>
                              </p>
                            </v-card-text>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row v-if="detail.kelas_terkait_elearning.length > 6">
                      <v-col cols="12" class="text-right">
                        <a
                          href="https://elearning.bidankita.com/elearning"
                          target="_blank" 
                          class="color-first">
                          <span>
                            Lihat kelas lainnya
                          </span>
                          <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                        </a>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-row class="mt-6" v-if="detail.kelas_total_materi > 0">
                  <v-col cols="12" class="py-0">
                    <div class="d-flex align-end mb-1">
                      <div class="d-flex align-center">
                        <v-icon size="30" class="mr-1">mdi-bookshelf</v-icon>
                        <span class="title font-weight-bold mb-0">
                          <span class="color-first mr-1">
                            {{ detail.kelas_total_materi }}
                          </span>
                          Materi
                        </span>
                      </div>

                      <v-spacer/>

                      <!-- <div v-if="detail.kelas_total_materi > 5">
                        <div class="color-first cursor-pointer"
                          @click="changeTab('materi')">
                          <span>
                            {{ detail.kelas_total_materi - 5 }} Materi lainnya
                          </span>
                          <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                        </div>
                      </div> -->
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-expansion-panels flat v-model="selected.panel">
                      <v-expansion-panel
                        v-for="(item, index) in detail.kelas_materi_jsonarray" 
                        :key="index"
                        class="border-radius mb-3"
                        :class="selected.panel === index ? 'box-shadow' : ''">
                        <v-expansion-panel-header class="border-radius" style="background: #f0f0f0;">
                          <v-list dense class="py-0" color="transparent">
                            <v-list-item class="py-0 pl-0">
                              <v-list-item-content :style="rps === 'xs' ? 'width: 100px;' : 'width: 400px;'">
                                <span class="body-1 font-weight-bold text-capitalize"
                                  :class="selected.panel !== index ? 'one-line' : ''">
                                  {{ item.title }}
                                </span>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <template v-slot:actions>
                            <div class="d-flex align-center" style="border-radius: 5px; padding: 5px 15px;">
                              <span>
                                <v-icon size="20" class="mr-1" color="#000">mdi-book-open-blank-variant</v-icon>
                              </span>
                              <span class="body-2 font-weight-bold mr-1">
                                {{ item.total_sub_materi > 0 ? item.total_sub_materi : 1 }}
                              </span>
                              <span class="caption text-first mr-1">
                                Materi
                              </span>
                              <v-icon color="#000" small>
                                $expand
                              </v-icon>
                            </div>
                          </template>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content class="pa-0 pb-4"
                          :class="rps === 'xs' ? 'px-0' : 'px-4'">
                          <v-list flat three-line dense class="py-0" color="transparent">
                            <v-list-item-group>
                              <v-list-item 
                                :class="{
                                  'my-6': item.total_sub_materi > 0,
                                  'mt-6': item.total_sub_materi < 1,
                                  'px-0': rps === 'xl' || rps === 'lg'
                                }"
                                @click="item.content_jsonobject.type !== '' && item.preview ? changePlay(item) : ''">
                                <v-list-item-content>
                                  <div class="d-flex align-center">
                                    <div v-if="item.content_jsonobject.type !== ''"
                                      class="text-center">
                                      <v-icon v-if="item.title !== player.data.title || !player.media.is_play"
                                        large
                                        :color="item.preview ? '#FF3177' : '#cacaca'">
                                        {{ 
                                          item.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                          item.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                          item.content_jsonobject.type === 'video' ? 'mdi-play-circle' :
                                          item.content_jsonobject.type === 'youtube' ? 'mdi-youtube' : 'mdi-google-drive'
                                        }}
                                      </v-icon>

                                      <v-icon v-else
                                        large
                                        color="#333333">
                                        mdi-stop-circle-outline
                                      </v-icon>

                                      <p class="caption text-capitalize color-first mb-0"
                                        :class="(item.title !== player.data.title || !player.media.is_play) && item.preview ? 'color-first' : 'text-first'">
                                        <span v-if="item.content_jsonobject.type !== 'docs'">
                                          <span v-if="!item.preview">
                                            play
                                          </span>

                                          <span v-else>
                                            {{ item.title !== player.data.title || !player.media.is_play ? 'view' : 'stop' }}
                                          </span>
                                        </span>

                                        <span v-else>
                                          <span v-if="!item.preview">
                                            baca
                                          </span>

                                          <span v-else>
                                            {{ item.title !== player.data.title || !player.media.is_play ? 'view' : 'tutup' }}
                                          </span>
                                        </span>
                                      </p>
                                    </div>

                                    <div v-if="item.content_jsonobject.type !== ''"
                                      class="mx-3"
                                      style="border-left: 7px solid #f0f0f0; border-radius: 5px; height: 75px;">
                                    </div>
                                    
                                    <div class="mr-6">
                                      <v-img
                                        width="75"
                                        height="75"
                                        class="d-flex align-center justify-center"
                                        gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                        :src="item.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail"
                                        style="border-radius: 5px;">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>

                                        <div class="d-flex align-center justify-center" v-if="!item.preview && item.content_jsonobject.type !== ''">
                                          <v-icon style="font-size: 25px;" color="#FF3177">mdi-lock</v-icon>
                                        </div>
                                      </v-img>
                                    </div>

                                    <div class="body-1 font-weight-light text-second"
                                      style="line-height: 1.75;">
                                      <span
                                        class="three-line"
                                        v-html="item.deskripsi === '' ? `Materi ${item.title}` : item.deskripsi.replace(/<\/?[^>]+>/ig, '')">
                                      </span>
                                    </div>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>

                              <div v-if="item.total_sub_materi > 0">
                                <v-list-item :class="rps === 'xl' || rps === 'lg' ? 'px-0' : ''"
                                  v-for="(sub, key) in item.sub_materi" :key="key"
                                  @click="sub.content_jsonobject.type !== '' && sub.preview ? changePlay(sub) : ''">
                                  <v-list-item-content>
                                    <div class="d-flex align-center">
                                      <div v-if="sub.content_jsonobject.type !== ''"
                                        class="text-center">
                                        <v-icon v-if="sub.title !== player.data.title || !player.media.is_play"
                                          large
                                          :color="sub.preview ? '#FF3177' : '#cacaca'">
                                          {{ 
                                            sub.content_jsonobject.type === 'docs' ? 'mdi-text-box' :
                                            sub.content_jsonobject.type === 'audio' ? 'mdi-music-circle' :
                                            sub.content_jsonobject.type === 'video' ? 'mdi-play-circle' :
                                            sub.content_jsonobject.type === 'youtube' ? 'mdi-youtube' : 'mdi-google-drive'
                                          }}
                                        </v-icon>

                                        <v-icon v-else
                                          large
                                          color="#333333">
                                          mdi-stop-circle-outline
                                        </v-icon>

                                        <p class="caption text-capitalize color-first mb-0"
                                          :class="(sub.title !== player.data.title || !player.media.is_play) && sub.preview ? 'color-first' : 'text-first'">
                                          <span v-if="sub.content_jsonobject.type !== 'docs'">
                                            <span v-if="!sub.preview">
                                              play
                                            </span>

                                            <span v-else>
                                              {{ sub.title !== player.data.title || !player.media.is_play ? 'view' : 'stop' }}
                                            </span>
                                          </span>

                                          <span v-else>
                                            <span v-if="!sub.preview">
                                              baca
                                            </span>

                                            <span v-else>
                                              {{ sub.title !== player.data.title || !player.media.is_play ? 'view' : 'tutup' }}
                                            </span>
                                          </span>
                                        </p>
                                      </div>
                                      
                                      <div v-if="sub.content_jsonobject.type !== ''"
                                        class="mx-3" 
                                        style="border-left: 7px solid #f0f0f0; border-radius: 5px; height: 50px;">
                                      </div>

                                      <div class="mr-6">
                                        <v-img
                                          width="50"
                                          height="50"
                                          class="d-flex align-center justify-center"
                                          gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.2)"
                                          :src="sub.content_jsonobject.thumbnail === '' ? detail.kelas_image_jsonobejct.kelas_image_cover_url : sub.content_jsonobject.thumbnail"
                                          style="border-radius: 5px;">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular 
                                                indeterminate 
                                                color="#FF3177">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>

                                          <div class="d-flex align-center justify-center" v-if="!sub.preview && sub.content_jsonobject.type !== ''">
                                            <v-icon style="font-size: 25px;" color="#FF3177">mdi-lock</v-icon>
                                          </div>
                                        </v-img>
                                      </div>
                                      
                                      <div>
                                        <p class="body-1 font-weight-bold text-capitalize mb-1"
                                          :class="rps !== 'xs' ? 'body-1' : 'body-1'">
                                          <span class="one-line">
                                            {{ sub.title }}
                                          </span>
                                        </p>

                                        <p class="body-1 font-weight-light line-text-first text-second mb-0">
                                          <span 
                                            class="one-line" 
                                            v-html="sub.deskripsi === '' ? sub.title : sub.deskripsi.replace(/<\/?[^>]+>/ig, '')">
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </div>
                            </v-list-item-group>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div class="d-flex align-center mb-4">
                      <div class="d-flex align-center">
                        <v-icon size="30" class="mr-1">mdi-calendar-month</v-icon>
                        <span class="title font-weight-bold mb-0">
                          <span class="color-first mr-1">
                            {{ detail.kelas_schedule.length }}
                          </span>
                          Jadwal
                        </span>
                      </div>

                      <v-spacer/>

                      <!-- <div v-if="detail.kelas_schedule.length > 5">
                        <div class="color-first cursor-pointer"
                          @click="changeTab('schedule')">
                          <span>
                            {{ detail.kelas_schedule.length - 5 }} Jadwal lainnya
                          </span>
                          <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                        </div>
                      </div> -->
                    </div>

                    <v-card
                      v-for="(item, index) in detail.kelas_schedule"
                      :key="index"
                      class="box-shadow mb-6"
                      style="border-radius: 5px;"
                      :color="select === index ? '#ff31770d' : ''"
                      :style="item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime ? 'opacity: 50%;' : ''">
                      <v-card-text class="pa-0">
                        <v-row>
                          <v-col :cols="rps !== 'xs' ? 2 : 4" class="d-flex align-center justify-center"
                            :class="rps !== 'xs' ? 'px-5' : ''">
                            <div v-if="item.schedule_is_purchased === '1' || detail.kelas_is_discount"
                              :class="item.schedule_is_purchased === '1' ? 'badge-overlay-buy' : 'badge-overlay-discount'">
                              <span class="font-weight-bold"
                                :class="item.schedule_is_purchased === '1' ? 'top-left-buy badge-buy buy' : 'top-left-discount badge-discount discount'">
                                <v-icon v-if="item.schedule_is_purchased === '1'"
                                  color="white"
                                  style="transform: translateX(0%) translateY(15%) rotate(44deg);">
                                  mdi-check-bold
                                </v-icon>

                                <div v-else
                                  style="transform: translateX(0%) translateY(15%) rotate(44deg);">
                                  -{{ detail.kelas_diskon_jsonobject.percent }}%
                                </div>
                              </span>
                            </div>
                            
                            <div style="position: absolute;" :style="rps !== 'xs' ? 'left: -18px;' : 'left: -8px;'">
                              <v-icon :large="rps !== 'xs'" :small="rps === 'xs'" color="#f9f9f9">mdi-circle</v-icon>
                            </div>
                            
                            <v-spacer/>

                            <div class="text-center">
                              <p class="font-weight-bold text-first mb-0"
                                :class="rps !== 'xs' ? 'text-h5' : 'title'">
                                {{ item.schedule_start_datetime | day }}

                                <span v-if="item.schedule_time_jsonarray.length > 1">
                                  - {{ item.schedule_end_datetime | day }}
                                </span>
                              </p>

                              <p class="text-second text-uppercase mb-0"
                                :class="rps !== 'xs' ? 'body-1' : 'body-2'">
                                {{ item.schedule_start_datetime | month }}
                              </p>

                              <p class="text-second mb-0"
                                :class="rps !== 'xs' ? 'body-1' : 'body-2'">
                                {{ item.schedule_start_datetime | year }}
                              </p>
                            </div>

                            <v-spacer/>

                            <v-divider vertical style="border-style: dashed;"/>
                          </v-col>

                          <v-col :cols="rps !== 'xs' ? 5 : 8" class="d-flex align-center"
                            :class="rps !== 'xs' ? 'px-5' : ''">
                            <v-row align="start">
                              <v-col :cols="rps !== 'xs' ? 9 : 12" class="py-0">
                                <div class="mb-2">
                                  <div v-if="dateNow <= item.schedule_end_datetime">
                                    <div v-if="item.schedule_status !== 'Selesai'">
                                      <v-chip v-if="dateNow > item.schedule_early_price_end_datetime"
                                        style="border: 1px solid #6161614d;"
                                        color="#61616133"
                                        label
                                        :small="rps !== 'xs'"
                                        :x-small="rps === 'xs'"
                                        text-color="#616161"
                                        class="font-weight-bold text-uppercase">
                                        NORMAL PRICE
                                      </v-chip>

                                      <v-chip v-else
                                        style="border: 1px solid #0080004d"
                                        color="#4caf5033"
                                        label
                                        :small="rps !== 'xs'"
                                        :x-small="rps === 'xs'"
                                        text-color="green"
                                        class="font-weight-bold text-uppercase">
                                        EARLY PRICE
                                      </v-chip>

                                      <span class="text-second ml-2" :class="rps !== 'xs' ? '' : 'caption'">
                                        s.d. 
                                        <span v-if="dateNow >= item.schedule_early_price_end_datetime">
                                          {{ item.schedule_normal_price_end_datetime | date }}
                                        </span>

                                        <span v-else>
                                          {{ item.schedule_early_price_end_datetime | date }}
                                        </span>
                                      </span>
                                    </div>

                                    <div v-else>
                                      <v-chip
                                        style="border: 1px solid #f443364d;"
                                        color="#f4433633"
                                        label
                                        :small="rps !== 'xs'"
                                        :x-small="rps === 'xs'"
                                        text-color="red"
                                        class="font-weight-bold text-uppercase">
                                        {{ item.schedule_status }}
                                      </v-chip>

                                      <span class="text-second ml-2" :class="rps !== 'xs' ? '' : 'caption'">
                                        pada {{ item.schedule_end_datetime | date }} Pukul {{ item.schedule_end_datetime | time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div v-else>
                                    <v-chip
                                      style="border: 1px solid #f443364d;"
                                      color="#f4433633"
                                      label
                                      :small="rps !== 'xs'"
                                      :x-small="rps === 'xs'"
                                      text-color="red"
                                      class="font-weight-bold text-uppercase">
                                      {{ item.schedule_status !== 'Selesai' ? 'Pendaftaran Berakhir' : item.schedule_status }}
                                    </v-chip>

                                    <span class="text-second ml-2" :class="rps !== 'xs' ? '' : 'caption'">
                                      pada {{ item.schedule_end_datetime | date }} Pukul {{ item.schedule_end_datetime | time }}
                                    </span>
                                  </div>
                                </div>

                                <div class="burst_special_price_mini" v-if="rps === 'xs' && detail.kelas_is_special_price"
                                  style="position: absolute; top: 40px; right: 20px;">
                                  <span>
                                    Harga Spesial
                                  </span>
                                </div>
                              </v-col>

                              <v-col cols="3" class="d-flex justify-center" v-if="rps !== 'xs'">
                                <div class="burst_special_price" v-if="detail.kelas_is_special_price">
                                  <span>
                                    Harga Spesial
                                  </span>
                                </div>
                              </v-col>

                              <v-col cols="12" class="pb-0" v-if="rps !== 'xs'">
                                <v-row align="center">
                                  <v-col :cols="item.schedule_is_purchased === '0' ? 9 : 7" class="d-flex align-center py-0">
                                    <div>
                                      <p class="caption text-third text-uppercase mb-0">
                                        JAM ZOOM CLASS
                                      </p>

                                      <p class="body-2 font-weight-bold text-second mb-0">
                                        {{ item.schedule_start_datetime | time }} - {{ item.schedule_end_datetime | time }}
                                      </p>
                                    </div>

                                    <v-spacer v-if="item.schedule_is_purchased === '0'"/>

                                    <v-divider vertical v-if="item.schedule_is_purchased === '0'"/>
                                    
                                    <v-spacer v-if="item.schedule_is_purchased === '0'"/>

                                    <div v-if="item.schedule_is_purchased === '0'">
                                      <p class="caption text-third text-uppercase mb-0">
                                        HARGA KELAS
                                      </p>

                                      <p class="body-2 font-weight-bold mb-0">
                                        <span v-if="!item.kelas_is_discount">
                                          <span class="green--text" v-if="parseInt(item.schedule_early_price) < 1 && parseInt(item.schedule_normal_price) < 1">
                                            Gratis
                                          </span>

                                          <span class="color-first" v-else>
                                            <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                              {{ parseInt(item.schedule_early_price) | price }}
                                            </span>

                                            <span v-else>
                                              {{ parseInt(item.schedule_normal_price) | price }}
                                            </span>
                                          </span>
                                        </span>

                                        <span class="orange--text" v-if="item.kelas_is_discount">
                                          <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                            {{ parseInt(item.schedule_early_price) - ((parseInt(item.schedule_early_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                          </span>

                                          <span v-else>
                                            {{ parseInt(item.schedule_normal_price) - ((parseInt(item.schedule_normal_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                          </span>
                                        </span>
                                      </p>
                                    </div>

                                    <v-spacer/>

                                    <v-divider vertical/>

                                    <v-spacer/>

                                    <div v-if="item.schedule_is_purchased === '0'">
                                      <p class="caption text-third text-uppercase mb-0">
                                        SISA KUOTA
                                      </p>

                                      <p class="body-2 text-second mb-0">
                                        <span class="font-weight-bold blue--text">{{ item.schedule_quota_left }}</span> dari {{ item.schedule_quota }}
                                      </p>
                                    </div>

                                    <div v-if="item.schedule_is_purchased === '1'">
                                      <p class="caption text-third text-uppercase mb-0">
                                        INFO ZOOM CLASS
                                      </p>

                                      <p class="body-2 font-weight-bold mb-0">
                                        <span
                                          :class="item.schedule_status === 'Belum Mulai' ? 'blue--text' : 
                                                  item.schedule_status === 'Sedang Berlangsung' ? 'green--text' : 'red--text'">
                                          {{ item.schedule_status }}
                                        </span>
                                      </p>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-col>

                              <v-col cols="12" class="d-flex align-center pb-0" v-if="rps === 'xs'">
                                <div>
                                  <p class="caption text-third text-uppercase mb-0">
                                    HARGA KELAS
                                  </p>

                                  <p class="body-2 font-weight-bold mb-0">
                                    <span v-if="!item.kelas_is_discount">
                                      <span class="green--text" v-if="parseInt(item.schedule_early_price) < 1 && parseInt(item.schedule_normal_price) < 1">
                                        Gratis
                                      </span>

                                      <span class="color-first" v-else>
                                        <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                          {{ parseInt(item.schedule_early_price) | price }}
                                        </span>

                                        <span v-else>
                                          {{ parseInt(item.schedule_normal_price) | price }}
                                        </span>
                                      </span>
                                    </span>

                                    <span class="orange--text" v-if="item.kelas_is_discount">
                                      <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                        {{ parseInt(item.schedule_early_price) - ((parseInt(item.schedule_early_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                      </span>

                                      <span v-else>
                                        {{ parseInt(item.schedule_normal_price) - ((parseInt(item.schedule_normal_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                      </span>
                                    </span>
                                  </p>
                                </div>

                                <v-spacer/>

                                <div class="pr-3" v-if="item.schedule_is_purchased === '0'">
                                  <p class="caption text-third text-uppercase mb-0">
                                    SISA KUOTA
                                  </p>

                                  <p class="body-2 text-second mb-0">
                                    <span class="font-weight-bold blue--text">{{ item.schedule_quota_left }}</span> dari {{ item.schedule_quota }}
                                  </p>
                                </div>

                                <div class="pr-3" v-if="item.schedule_is_purchased === '1'">
                                  <p class="caption text-third text-uppercase mb-0">
                                    INFO ZOOM CLASS
                                  </p>

                                  <p class="body-2 font-weight-bold mb-0">
                                    <span
                                      :class="item.schedule_status === 'Belum Mulai' ? 'blue--text' : 
                                              item.schedule_status === 'Sedang Berlangsung' ? 'green--text' : 'red--text'">
                                      {{ item.schedule_status }}
                                    </span>
                                  </p>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col :cols="rps !== 'xs' ? 5 : 12" 
                            class="d-flex align-center justify-center"
                            :class="rps !== 'xs' ? 'px-5' : ''">
                            <div class="d-flex align-center" style="position: absolute;" :style="rps !== 'xs' ? 'right: -18px;' : 'right: -8px;'">
                              <v-icon :large="rps !== 'xs'" :small="rps === 'xs'" color="#f9f9f9">mdi-circle</v-icon>
                            </div>

                            <div class="d-flex align-center">
                              <div v-if="item.schedule_is_purchased === '0'">
                                <v-btn
                                  block
                                  rounded
                                  elevation="0"
                                  color="#FF3177"
                                  :small="rps === 'xs'"
                                  class="text-capitalize white--text"
                                  :class="rps === 'xs' ? 'caption' : 'body-1'"
                                  :disabled="process.cart || process.buynow || (item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime)"
                                  :loading="process.cart && selected.index === index"
                                  @click="selected.index = index; addToCart('cart');">
                                  <v-icon size="16" class="mr-1">mdi-plus</v-icon>
                                  Ke Keranjang
                                </v-btn>
                              </div>

                              <div v-if="item.schedule_is_purchased === '1'">
                                <v-btn
                                  style="opacity: 0%;"
                                  block
                                  rounded
                                  disabled
                                  elevation="0"
                                  :small="rps === 'xs'"
                                  color="transparent"
                                  class="text-capitalize white--text"
                                  :class="rps === 'xs' ? 'caption' : 'body-1'">
                                  <v-icon size="16" class="mr-1">mdi-plus</v-icon>
                                  Ke Keranjang
                                </v-btn>
                              </div>

                              <v-spacer/>

                              <div class="mx-3" v-if="item.schedule_is_purchased === '0'">
                                <v-btn
                                  outlined
                                  block
                                  rounded
                                  elevation="0"
                                  :small="rps === 'xs'"
                                  color="#FF3177"
                                  class="text-capitalize color-first"
                                  :class="rps === 'xs' ? 'caption' : 'body-1'"
                                  :disabled="process.buynow || process.cart || (item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime)"
                                  :loading="process.buynow && selected.index === index"
                                  @click="selected.index = index; addToCart('buynow');">
                                  Beli Sekarang
                                </v-btn>
                              </div>

                              <div class="mx-3" v-if="item.schedule_is_purchased === '1'">
                                <v-btn
                                  outlined
                                  block
                                  rounded
                                  elevation="0"
                                  :small="rps === 'xs'"
                                  color="#FF3177"
                                  class="text-capitalize color-first"
                                  :class="rps === 'xs' ? 'caption' : 'body-1'"
                                  :to="`/collection/streaming/${item.kelas_id}?schedule_id=${item.schedule_id}`">
                                  Masuk Kelas
                                </v-btn>
                              </div>
                              
                              <v-spacer/>

                              <div>
                                <v-btn
                                  fab
                                  color="transparent"
                                  :width="rps === 'xs' ? 30 : 40"
                                  :height="rps === 'xs' ? 30 : 40"
                                  elevation="0"
                                  :disabled="process.buynow || process.cart || (item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime)"
                                  @click="dialog.share = true">
                                  <v-icon :size="rps === 'xs' ? 20 : 30">mdi-share-variant</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      },
      panel: {
        type: Number,
        default: 0
      },
      play: {
        type: Object,
        default: {}
      },
      select: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        dateNow: moment().format('YYYY-MM-DD HH:mm:ss'),
        dialog: {
          auth: '',
          message: '',
          share: false,
          snackbar: false,
          stock: ''
        },
        selected: {
          panel: 0,
          index: ''
        },
        player: {
          media: {
            type: '',
            is_play: false
          },
          data: {}
        },
        sosmed: [
          { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
          { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
          { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
          { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
        ],
        process: {
          cart: false,
          buynow: false
        },
        message: ''
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      DialogMessage: () => import('@/components/dialog/message')
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      headers() {
        return this.$store.state.header
      },
      host() {
        return process.env.HOST_SHARE_PROD
      }
    },
    mounted () {
      this.selected.panel = this.panel
      this.player = this.play
    },
    methods: {
      changePlay(item) {
        item.content_jsonobject.thumbnail = item.content_jsonobject.thumbnail === '' ? this.detail.kelas_image_jsonobejct.kelas_image_cover_url : item.content_jsonobject.thumbnail

        let status = false
        if (item.title === this.player.data.title) {
          if(!this.player.media.is_play) {
            status = true
          } else {
            status = false
          }
        } else {
          status = true
        }

        this.player.media = {
          type: item.content_jsonobject.type,
          is_play: status
        }
        this.player.data = item

        this.$emit('changePlay', item)
        this.$emit('changePanel', this.selected.panel)
      },
      changeTab(type) {
        this.$emit('changeTab', type === 'schedule' ? 1 : 2)
      },
      async addToCart(type) {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          if (this.detail.kelas_schedule[this.selected.index].schedule_quota_left < 1) {
            this.dialog.stock = 'Kuota habis, silahkan pilih jadwal lainnya.'
            this.dialog.snackbar = true
          } else {
            this.saveCart(type)
          }
        }
      },
      async saveCart(type) {
        this.process.cart = type === 'cart' ? true : false
        this.process.buynow = type === 'buynow' ? true : false

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/cart/add`, {
          kelas_id: this.detail.kelas_id,
          schedule_id: this.detail.kelas_schedule[this.selected.index].schedule_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.cart = false
            this.process.buynow = false

            this.$store.commit('header/setCart', this.headers.cart + 1)

            if (type === 'cart') {
              this.dialog.message = 'success'
              this.message = 'Berhasil ditambahkan ke Keranjang.'
            } else {
              this.$router.push('/cart')
            }
          } else {
            this.process.cart = false
            this.process.buynow = false
            
            this.dialog.message = 'error'
            this.message = response.message
          }
        })
      }
    }
  }
</script>