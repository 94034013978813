<template>
  <div>
    <v-dialog v-model="dialog.success"
      width="350"
      persistent>
      <v-card 
        color="#f3f3f3"
        class="border-radius">
        <v-row class="ma-0" justify="center" align="center">
          <v-col cols="12" class="pa-0">
            <v-card
              flat
              class="border-radius pa-2">
              <v-card-text class="pa-0 text-right">
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  class="background-first white--text"
                  @click="dialog.success = false; $emit('close');">
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
              <div class="pa-5 pt-0">
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="mx-1 font-weight-bold text-second">BERHASIL</div>
                  <v-divider color="white"></v-divider>
                </div>
                <div class="d-flex justify-center">
                  <div class="sa">
                    <div class="sa-success">
                      <div class="sa-success-tip"></div>
                      <div class="sa-success-long"></div>
                      <div class="sa-success-placeholder"></div>
                      <div class="sa-success-fix"></div>
                    </div>
                  </div>
                </div>
                <div class="text-center body-1 font-weight-light text-second line-text-first mb-8">
                  {{ success.message }}
                </div>
                <div class="text-center body-1 font-weight-light mt-3">
                  <div class="text-second">
                    Lihat Keranjang ?
                    <nuxt-link to="/cart" class="color-first cursor-pointer">
                      Klik disini
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.error"
      width="350"
      persistent>
      <v-card 
        color="#f3f3f3"
        class="border-radius">
        <v-row class="ma-0" justify="center" align="center">
          <v-col cols="12" class="pa-0">
            <v-card
              flat
              class="border-radius pa-2">
              <v-card-text class="pa-0 text-right">
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  class="background-first white--text"
                  @click="dialog.error = false; $emit('close');">
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
              <div class="pa-5 pt-0">
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="mx-1 font-weight-bold text-second">GAGAL</div>
                  <v-divider color="white"></v-divider>
                </div>
                <div class="d-flex justify-center my-8">
                  <v-icon color="red" style="font-size: 100px;">mdi-cart-remove</v-icon>
                </div>
                <div class="text-center body-1 font-weight-light red--text line-text-first mb-8">
                  {{ error.message }}
                </div>
                <div class="text-center body-1 font-weight-light mt-3">
                  <div class="text-second">
                    Lihat Kelas Lain ?
                    <a
                      href="https://elearning.bidankita.com/elearning"
                      target="_blank"
                      class="color-first cursor-pointer">
                      Klik disini
                    </a>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.wishlist"
      width="350"
      persistent>
      <v-card 
        color="#f3f3f3"
        class="border-radius">
        <v-row class="ma-0" justify="center" align="center">
          <v-col cols="12" class="pa-0">
            <v-card
              flat
              class="border-radius pa-2">
              <v-card-text class="pa-0 text-right">
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  class="background-first white--text"
                  @click="dialog.wishlist = false; $emit('close');">
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-card-text>
              <div class="pa-5 pt-0">
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="mx-1 font-weight-bold text-second">BERHASIL</div>
                  <v-divider color="white"></v-divider>
                </div>
                <div class="d-flex justify-center">
                  <div class="sa">
                    <div class="sa-success">
                      <div class="sa-success-tip"></div>
                      <div class="sa-success-long"></div>
                      <div class="sa-success-placeholder"></div>
                      <div class="sa-success-fix"></div>
                    </div>
                  </div>
                </div>
                <div class="text-center body-1 font-weight-light text-second line-text-first mb-8">
                  {{ success.message }}
                </div>
                <div class="text-center body-1 font-weight-light mt-3" v-if="success.message === 'Berhasil ditambahkan ke Wishlist.'">
                  <div class="text-second">
                    Lihat Wishlist ?
                    <nuxt-link to="/wishlist" class="color-first cursor-pointer">
                      Klik disini
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      dialogmessage: {
        type: String,
        default: ''
      },
      message: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        dialog: {
          success: false,
          error: false,
          wishlist: false
        },
        success: {
          message: ''
        },
        error: {
          message: ''
        }
      }
    },
    watch: {
      'dialogmessage': function(val) {
        if (val === 'success') {
          this.success.message = this.message !== '' ? this.message : ''
          this.dialog.success = true
        } else if (val === 'error') {
          this.error.message = this.message !== '' ? this.message : ''
          this.dialog.error = true
        } else if (val === 'wishlist') {
          this.success.message = this.message !== '' ? this.message : ''
          this.dialog.wishlist = true
        }
      }
    }
  }
</script>

<style lang="scss">
.sa {
  width: 140px;
  height: 140px;
  padding: 26px;
  background-color: #fff;
  &-success {
    border-radius: 50%;
    border: 4px solid #FF3177;
    box-sizing: content-box;
    height: 80px;
    padding: 0;
    position: relative;
    background-color: #fff;
    width: 80px;
    &:after, &:before {
      background: #fff;
      content: '';
      height: 120px;
      position: absolute;
      transform: rotate(45deg);
      width: 60px;
    }
    &:before {
      border-radius: 40px 0 0 40px;
      width: 26px;
      height: 80px;
      top: -17px;
      left: 5px;
      transform-origin: 60px 60px;
      transform: rotate(-45deg);
    }
    &:after {
      border-radius: 0 120px 120px 0;
      left: 30px;
      top: -11px;
      transform-origin: 0 60px;
      transform: rotate(-45deg);
      animation: rotatePlaceholder 4.25s ease-in;
    }
    &-placeholder {
      border-radius: 50%;
      border: 4px solid rgb(255 62 142 / 25%);
      box-sizing: content-box;
      height: 80px;
      left: -4px;
      position: absolute;
      top: -4px;
      width: 80px;
      z-index: 2;
    }
    &-fix {
      background-color: #fff;
      height: 90px;
      left: 28px;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 5px;
      z-index: 1;
    }
    &-tip, &-long {
      background-color: #FF3177;
      border-radius: 2px;
      height: 5px;
      position: absolute;
      z-index: 2;
    }
    &-tip {
      left: 14px;
      top: 46px;
      transform: rotate(45deg);
      width: 25px;
      animation: animateSuccessTip .75s;
    }
    &-long {
      right: 8px;
      top: 38px;
      transform: rotate(-45deg);
      width: 47px;
      animation: animateSuccessLong .75s;
    }
  }
}
@keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes rotatePlaceholder {
  0%, 5% {
    transform: rotate(-45deg);
  }
  100%, 12% {
    transform: rotate(-405deg);
  }
}
</style>