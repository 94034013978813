<template>
  <div class="fill-height py-12">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container class="pb-0">
            <v-row>
              <v-col class="pb-0">
                <v-breadcrumbs :items="items" class="pb-0">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-container class="px-8">
            <v-row>
              <v-col cols="12" md="8" sm="8">
                <p class="title mb-2">
                  Transaksi
                </p>
                <p class="body-1 font-weight-light">
                  Silahkan selesaikan pembayaran Anda.
                </p>

                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="#FF3177">
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index">
                    <span class="text-capitalize body-1 font-weight-light"
                      :class="selected.menu === index ? 'font-weight-bold' : ''">
                      {{ item }}
                    </span>
                  </v-tab>
                </v-tabs>

                <v-alert v-if="!selected.first && !process.trans && user.user_is_verified !== 'verified'"
                  class="mt-4"
                  dense
                  text
                  color="#FF3177"
                  icon="mdi-seal">
                  Dapatkan free akun premium di <b>keluarga.bidankita.com</b>, untuk pembelian kelas berbayar pertama.
                </v-alert>

                <v-divider/>

                <v-card flat
                  v-for="(item, index) in process.trans ? 5 : list.trans"
                  :key="index">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.trans"
                    type="article">
                    <div class="d-flex align-center">
                      <div v-if="selected.trans_id === item.transaction_id"
                        class="mr-4" 
                        style="border-left: 10px solid #ff3177; border-radius: 5px; height: 110px;">
                      </div>

                      <div style="width: 100%;" v-if="!process.trans && list.trans.length > 0">
                        <v-list three-line class="py-0">
                          <v-list-item class="px-0"
                            @click="selected.trans_id = item.transaction_id; fetchDetail();">
                            <v-list-item-content>
                              <v-list-item-title>
                                <p class="body-1 font-weight-bold mb-2">
                                  #{{ item.transaction_code }}
                                </p>

                                <p class="body-1 font-weight-bold mb-2">
                                  <span class="color-first" v-if="parseInt(item.transaction_price) > 0">
                                    {{ parseInt(item.transaction_price) | price }}
                                  </span>

                                  <span class="green--text" v-else>
                                    Gratis
                                  </span>
                                </p>
                              </v-list-item-title>
                              <v-list-item-subtitle class="body-2 text-second">
                                {{ item.transaction_datetime | datetime }}
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon v-if="selected.trans_id === item.transaction_id">
                              <v-icon color="#ff3177">mdi-check-bold</v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list>

                        <div class="d-flex align-center mb-4">
                          <div class="caption text-capitalize d-flex align-center primary--text cursor-pointer">
                            <a
                              :href="item.transaction_detail_jsonarray[0].schedule_id === '0' ? `https://elearning.bidankita.com/elearning` : `https://kelas.bidankita.com/streaming`"
                              target="_blank">
                              <v-icon small class="mr-1" color="primary">mdi-google-classroom</v-icon>
                              {{ item.transaction_type }}
                            </a>
                          </div>
                          <v-spacer/>
                          <div class="caption d-flex align-center">
                            <v-chip
                              small
                              class="white--text"
                              :color="item.transaction_status === 'Menunggu Pembayaran' ? 'orange' : item.transaction_status === 'Diterima' ? 'green' : 'red'">
                              {{ item.transaction_status === 'Diterima' ? 'Lunas' : item.transaction_status }}
                            </v-chip>
                          </div>
                        </div>
                      </div>
                    </div>

                    <v-divider v-if="index < list.trans.length - 1"/>
                  </v-skeleton-loader>
                </v-card>

                <!-- KETIKA KOSONG -->
                <div class="mt-6 mb-12" v-if="!process.trans && list.trans.length < 1">
                  <v-img 
                    class="mx-auto"
                    contain
                    width="175"
                    height="175"
                    :src="require('@/assets/image/empty.png')">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#FF3177">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <p class="text-h5 text-second font-weight-bold text-center">
                    Transaksi mu masih kosong!
                  </p>
                  <p class="body-1 font-weight-light mb-6 text-center">
                    Yuk lihat kelas belajar yang menarik.
                  </p>

                  <div class="text-center">
                    <v-btn
                      rounded
                      elevation="0"
                      color="#FF3177"
                      class="text-capitalize white--text"
                      href="https://elearning.bidankita.com/elearning"
                      target="_blank">
                      <v-icon class="mr-1">mdi-magnify</v-icon>
                      Cari Kelas Belajar
                    </v-btn>
                  </div>
                </div>
                
                <v-divider v-if="pagination.trans.total_page > 1"/>

                <!-- PAGING -->
                <v-row v-if="pagination.trans.total_page > 1">
                  <v-col cols="6">
                    <v-btn
                      color="#FF3177"
                      elevation="0"
                      text
                      min-width="36"
                      class="text-capitalize px-0"
                      :disabled="pagination.trans.current < 2"
                      @click="selected.pagination = selected.pagination-1">
                      <v-icon class="mr-1">mdi-arrow-left</v-icon>
                      Prev
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      color="#FF3177"
                      elevation="0"
                      text
                      min-width="36"
                      class="text-capitalize px-0"
                      :disabled="pagination.trans.current >= pagination.trans.total_page"
                      @click="selected.pagination = selected.pagination+1">
                      Next
                      <v-icon class="ml-1">mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <!-- KELAS LAINNYA  -->
                <div class="mt-6 mb-12" v-if="!process.trans && list.trans.length < 1">
                  <p class="body-1 font-weight-bold text-first">
                    Kelas Belajar
                    <span class="color-first">
                      Untuk Anda
                    </span>
                  </p>

                  <v-row :class="process.classes ? 'mt-12' : ''">
                    <v-col cols="12" md="4"
                      v-for="(item, index) in process.classes ? 8 : list.classes"
                      :key="index">
                      <v-skeleton-loader
                        class="ma-auto"
                        :loading="process.classes"
                        transition="scale-transition"
                        :tile="false"
                        type="card">
                        <div>
                          <div v-if="!process.classes && list.classes.length > 0">
                            <v-card
                              class="border-radius box-shadow pa-2"
                              :href="`https://elearning.bidankita.com/elearning/${item.kelas_slug}`"
                              target="_blank">
                              <v-img
                                width="100%"
                                height="200"
                                class="border-radius"
                                :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#FF3177">
                                    </v-progress-circular>
                                  </v-row>
                                </template>

                                <v-chip v-if="item.kelas_is_special_price"
                                  small
                                  class="font-weight-bold white--text px-2 ma-2"
                                  color="primary"
                                  style="position: absolute; top: 0; right: 0px;">
                                  Special Price
                                </v-chip>
                              </v-img>

                              <div class="burst ma-1" v-if="item.kelas_is_discount">
                                <span>
                                  OFF {{ item.kelas_diskon_jsonobject.percent }}%
                                </span>
                              </div>

                              <div class="burst_free" v-if="!item.kelas_berbayar">
                                <span>
                                  FREE
                                </span>
                              </div>

                              <div class="text-center" style="height: 60px;">
                                <p class="body-2 line-text-first text-capitalize font-weight-bold text-first justify-center pa-3 mb-0">
                                  <span class="two-line">
                                    {{ item.kelas_nama }}
                                  </span>
                                </p>
                              </div>
                            </v-card>
                          </div>
                        </div>
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>

                  <v-row class="mt-6" v-if="!process.classes && pagination.classes.total_page > 1">
                    <v-col cols="12" class="text-right">
                      <a
                        class="color-first"
                        href="https://elearning.bidankita.com/elearning"
                        target="_blank">
                        <span>
                          Lihat kelas lainnya
                        </span>
                        <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                      </a>
                    </v-col>
                  </v-row>
                </div>
              </v-col>

              <v-col cols="12" md="4" sm="4">
                <v-card class="box-shadow border-radius pa-6" :flat="!process.detail && Object.keys(detail).length < 1" style="position: sticky; top: 90px;">
                  <v-skeleton-loader
                    v-for="i in (process.trans || process.detail) ? 3 : 1"
                    :key="i"
                    class="ma-auto"
                    :loading="process.trans || process.detail"
                    type="article">
                    <div>
                      <v-card-text class="pa-0" v-if="!process.detail && Object.keys(detail).length > 0">
                        <div :style="detail.transaction_detail_jsonarray.length > 3 ? 'height: 215px; overflow-y: scroll;' :''">
                          <v-list three-line class="py-0">
                            <v-list-item class="px-0"
                              v-for="(item, index) in detail.transaction_detail_jsonarray"
                              :key="index"
                              :href="item.type === 'Kelas' && item.schedule_id === undefined ? 
                                `https://elearning.bidankita.com/elearning/${item.slug}` : item.type === 'Kelas' && item.schedule_id === '0' ? `https://elearning.bidankita.com/elearning/${item.slug}` : item.type === 'Kelas' && item.schedule_id != '0' ? `https://kelas.bidankita.com/streaming/${item.slug}` : `https://elearning.bidankita.com/dashboard/profile`"
                              target="_blank">
                              <v-list-item-avatar tile size="75">
                                <v-img 
                                  style="border-radius: 10px;"
                                  :src="item.gambar !=='' ? item.gambar : require('@/assets/image/member-default.jpg')">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#FF3177">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>

                                  <div class="badge-overlay-premium" v-if="item.diskon_value > 0">
                                    <span class="top-left-premium badge-premium premium font-weight-bold">
                                      {{ parseInt(item.diskon_persen) }}%
                                    </span>
                                  </div>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title class="body-1 font-weight-bold">
                                  <v-chip
                                    x-small
                                    class="white--text"
                                    color="#FF3177">
                                    {{ item.type === 'Kelas' ? item.kategori_nama : 'Free Akun Premium' }}
                                  </v-chip>
                                  <p class="text-capitalize mt-3 mb-0">
                                    {{ item.nama }}
                                  </p>
                                </v-list-item-title>
                                <v-list-item-subtitle class="body-2">
                                  <div v-if="item.type === 'Kelas'">
                                    <span class="text-decoration-line-through text-second mr-2" v-if="item.diskon_value > 0">
                                      {{ parseInt(item.harga) | price }}
                                    </span>
                                    <span class="font-weight-bold">
                                      <span class="color-first" v-if="parseInt(item.harga_after_diskon) > 0">
                                        {{ parseInt(item.harga_after_diskon) | price }}
                                      </span>

                                      <span class="green--text" v-else>
                                        Gratis
                                      </span>
                                    </span>
                                  </div>

                                  <div v-if="item.type === 'Registrasi'">
                                    <span class="text-decoration-line-through text-second">
                                      {{ parseInt(item.harga_after_diskon) | price }}
                                    </span>
                                  </div>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                        
                        <v-divider class="mt-2 mb-4"/>

                        <p class="body-1 font-weight-bold">
                          Tagihan
                        </p>

                        <div class="d-flex align-center mb-2">
                          <div class="body-1 font-weight-light">
                            Harga
                          </div>
                          <v-spacer/>
                          <div class="body-1 font-weight-light">
                            {{ parseInt(pricing.price) | price }}
                          </div>
                        </div>

                        <div class="d-flex align-center mb-2">
                          <div class="body-1 font-weight-light">
                            Diskon
                            <span v-if="parseInt(pricing.discount.value) > 0 && detail.transaction_detail_jsonarray.length < 2">
                              ({{ parseInt(pricing.discount.percent) }})%
                            </span>
                          </div>
                          <v-spacer/>
                          <div class="body-1 font-weight-light red--text">
                            -{{ parseInt(pricing.discount.value) | price }}
                          </div>
                        </div>

                        <div class="d-flex align-center" v-if="detail.transaction_detail_jsonarray.length < 2">
                          <div class="body-1 font-weight-light">
                            Langganan
                          </div>
                          <v-spacer/>
                          <div class="body-1 font-weight-light">
                            <span v-if="!pricing.subcribe.is_lifetime">
                              {{ parseInt(pricing.subcribe.value) }}
                              {{ pricing.subcribe.type === 'day' ? 'Hari' : pricing.subcribe.type === 'month' ? 'Bulan' : 'Tahun' }}
                            </span>

                            <span v-else>
                              Lifetime
                            </span>
                          </div>
                        </div>

                        <v-divider class="my-4"/>

                        <div class="d-flex align-center mb-4">
                          <div class="body-1 font-weight-bold">
                            Total
                          </div>
                          <v-spacer/>
                          <div class="body-1 font-weight-bold">
                            {{ parseInt(pricing.total) | price }}
                          </div>
                        </div>

                        <div v-if="detail.transaction_status === 'Menunggu Pembayaran'">
                          <p class="body-2 font-italic mb-2">
                            *Pembayaran paling lambat
                          </p>
                          <p class="body-2 font-weight-light red--text mb-6">
                            Tanggal <b>{{ detail.transaction_expired_datetime | date }}</b> 
                            Pukul <b>{{ detail.transaction_expired_datetime | time }}</b>
                          </p>

                          <v-alert 
                            type="error" 
                            text
                            dense
                            prominent
                            v-show="error.message.length > 0"
                            v-html="error.message">
                          </v-alert>
                          
                          <v-btn
                            elevation="0"
                            block
                            color="green"
                            class="body-1 font-weight-bold text-capitalize white--text"
                            @click="payment(detail.transaction_midtrans_data.token)">
                            Bayar Sekarang
                          </v-btn>
                        </div>

                        <div v-if="detail.transaction_status === 'Diterima'">
                          <p class="body-2 font-italic mb-2">
                            <span v-if="parseInt(pricing.total) > 0">
                              *Pembayaran Selesai
                            </span>

                            <span v-else>
                              *Ikut Kelas Selesai
                            </span>
                          </p>
                          <p class="body-2 font-weight-light green--text mb-6">
                            Tanggal <b>{{ detail.transaction_approval_datetime | date }}</b> 
                            Pukul <b>{{ detail.transaction_approval_datetime | time }}</b>
                          </p>

                          <v-btn v-if="detail.transaction_detail_jsonarray.length < 2"
                            elevation="0"
                            block
                            color="green"
                            class="body-1 font-weight-bold text-capitalize white--text"
                            :to="detail.transaction_detail_jsonarray[0].schedule_id === '0' ? '/collection/elearning' : '/collection/streaming'">
                            Lihat Kelas Saya
                          </v-btn>
                        </div>

                        <div v-if="detail.transaction_status === 'Ditolak'">
                          <p class="body-2 font-italic mb-2">
                            *Pembayaran Ditolak
                          </p>
                          <p class="body-2 font-weight-light red--text">
                            Tanggal <b>{{ detail.transaction_rejected_datetime | date }}</b> 
                            Pukul <b>{{ detail.transaction_rejected_datetime | time }}</b>
                          </p>
                        </div>
                      </v-card-text>
                    </div>
                  </v-skeleton-loader>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        items: [
          {
            text: 'Beranda',
            disabled: false,
            href: '/home'
          },
          {
            text: 'Transaksi',
            disabled: true,
            href: '#'
          },
        ],
        menu: ['Menunggu Pembayaran', 'Pembayaran Selesai', 'Pembayaran Ditolak'],
        selected: {
          menu: 0,
          first: true,
          trans_id: '',
          pagination: 1
        },
        detail: {},
        pricing: {
          price: 0,
          discount: {
            percent: 0,
            value: 0
          },
          subcribe: {
            type: 'day',
            value: 0,
            is_lifetime: false
          },
          total: 0
        },
        list: {
          trans: [],
          classes: []
        },
        pagination: {
          trans: {},
          classes: {}
        },
        process: {
          trans: false,
          detail: false,
          classes: false
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: 'dashboard/transaction',
          title: 'Transaksi',
          description: 'Transaksi E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content),
        script: [
          {
            type: 'text/javascript',
            src: process.env.MIDTRANS_SRC_LIVE,
            'data-client-key': process.env.MIDTRANS_KEY_LIVE
          }
        ]
      }
    },
    components: {
      
    },
    watch: {
      'selected.menu': function(val) {
        this.fetch()
      },
      'selected.pagination': function(val) {
        this.fetch(val)
      }
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      }
    },
    beforeCreate () {
      let params = {
        filter: [
          {
            type: 'string',
            field: 'transaction_type',
            value: 'Kelas'
          },
          {
            type: 'numeric',
            field: 'transaction_price',
            comparison: '>',
            value: 0
          },
          {
            type: 'string',
            field: 'transaction_status',
            value: 'Diterima'
          }
        ]
      }

      this.$axios.$get(`${process.env.PREFIX_PROXY}/member/transaction`, { params })
      .then((response) => {
        this.selected.first = response.data.results.length > 0 ? true : false
      })
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch(p) {
        this.process.trans = true
        
        let params = {
          page: p,
          limit: 8,
          sort: 'transaction_datetime',
          dir: 'DESC',
          filter: [
            {
              type: 'string',
              field: 'transaction_status',
              value: this.selected.menu === 0 ? 'Menunggu Pembayaran' : this.selected.menu  === 1 ? 'Diterima' : 'Ditolak'
            }
          ]
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/member/transaction`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.trans = false
            this.list.trans = response.data.results
            this.pagination.trans = response.data.pagination

            if (this.list.trans.length < 1) {
              this.detail = {}

              this.pricing = {
                price: 0,
                discount: {
                  percent: 0,
                  value: 0
                },
                subcribe: {
                  type: 'day',
                  value: 0,
                  is_lifetime: false
                },
                total: 0
              }
              
              this.fetchClass()
            } else {
              this.selected.trans_id = response.data.results[0].transaction_id

              this.fetchDetail()
            }
          }
        })
      },
      async fetchDetail() {
        this.process.detail = true

        let params = {
          id: this.selected.trans_id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/member/transaction/detail`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.detail = false
            this.detail = response.data

            this.pricing = {
              price: 0,
              discount: {
                percent: 0,
                value: 0
              },
              subcribe: {
                type: 'day',
                value: 0,
                is_lifetime: false
              },
              total: 0
            }

            this.fetchPricing(this.detail.transaction_detail_jsonarray)
          }
        })
      },
      fetchPricing(data) {
        for (let i = 0; i < data.length; i++) {
          let item = data[i]

          if (item.type === 'Kelas') {
            this.pricing = {
              price: this.pricing.price += parseInt(item.harga),
              discount: {
                percent: this.pricing.discount.percent += parseInt(item.diskon_persen),
                value: this.pricing.discount.value += parseInt(item.diskon_value)
              },
              subcribe: {
                type: item.subcribe_tipe,
                value: item.subcribe_value,
                is_lifetime: item.is_lifetime
              },
              total: this.pricing.total += parseInt(item.harga_after_diskon)
            }
          }
        }
      },
      async fetchClass(val = '') {
        this.process.classes = true

        let params = {
          limit: 6,
          sort: 'kelas_create_datetime',
          dir: 'DESC',
          filter: [
            {
              type: 'string',
              field: 'kelas_id',
              value: val
            }
          ]
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.classes = false
            this.list.classes = response.data.results
            this.pagination.classes = response.data.pagination
            
            if (val.length > 0) {
              window.open(`https://elearning.bidankita.com/elearning/${response.data.results[0].kelas_slug}`, '_blank')
            }
          }
        })
      },
      payment(token) {
        let t = this
        snap.pay(token, {
          onSuccess: function (result) {
            console.log('success', result)
            t.$router.push(`/payment/finish`)
          },
          onPending: function (result) {
            console.log('pending', result)
            t.$router.push(`/payment/finish`)
          },
          onError: function(result){
            console.log('pending', result)
            t.$router.push(`/payment/error`)
          }
        })
      }
    }
  }
</script>