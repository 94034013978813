<template>
  <div class="pb-3 px-6">
    <v-row :justify="flex">
      <v-col cols="12" class="d-flex align-end"
        :class="`justify-${flex} ${padding}`">
        <div class="d-flex align-end">
          <div class="body-2 font-weight-light black--text mb-1">
            {{ rps !== 'xs' ? 'Per Halaman' : 'Per' }}
          </div>

          <div class="ml-6">
            <v-select
              :style="selected.row < 10 ? 'width: 45px;' : selected.row > 50 ? 'width: 60px;' : 'width: 55px;'"
              single-line
              hide-details
              color="#00ACC1"
              :items="[
                {
                  text: 5,
                  value: 5
                },
                {
                  text: 10,
                  value: 10
                },
                {
                  text: 15,
                  value: 15
                },
                {
                  text: 20,
                  value: 20
                },
                {
                  text: 50,
                  value: 50
                },
                {
                  text: 100,
                  value: 100
                }
              ]"
              item-text="text"
              item-value="value"
              item-color="cyan darken-1"
              v-model="selected.row">
            </v-select>
          </div>
        </div>

        <div v-if="rps !== 'xs'"
          class="body-2 font-weight-light black--text mx-6 mb-1">
          {{ pagination.current > 1 ? (pagination.prev * selected.row) : 1 }}
          -
          {{ pagination.total_display >= selected.row ? pagination.current * selected.row : pagination.total_data }} 
          dari 
          {{ pagination.total_data }}
        </div>

        <div class="d-flex align-end">
          <div>
            <v-btn
              small
              icon
              :disabled="pagination.current === 1"
              @click="selected.pagination = 1; changePage(selected.row, selected.pagination);">
              <v-icon>mdi-chevron-double-left</v-icon>
            </v-btn>
          </div>

          <div>
            <v-btn
              small
              icon
              :disabled="pagination.current < 2"
              @click="selected.pagination = selected.pagination-1; changePage(selected.row, selected.pagination);">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>

          <div class="mx-3">
            <v-select
              :style="selected.pagination < 10 ? 'width: 45px;' : selected.pagination > 50 ? 'width: 60px;' : 'width: 55px;'"
              single-line
              hide-details
              color="#00ACC1"
              :items="pagination.detail"
              item-color="cyan darken-1"
              v-model="selected.pagination"
              v-on:change="changePage(selected.row, selected.pagination)">
            </v-select>
          </div>

          <div>
            <v-btn
              small
              icon
              :disabled="pagination.current >= pagination.total_page"
              @click="selected.pagination = selected.pagination+1; changePage(selected.row, selected.pagination);">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          
          <div>
            <v-btn
              small
              icon
              :disabled="pagination.current === pagination.total_page"
              @click="selected.pagination = pagination.total_page; changePage(selected.row, selected.pagination);">
              <v-icon>mdi-chevron-double-right</v-icon>
            </v-btn>
          </div>

          <div class="ml-3">
            <v-btn
              small
              icon
              @click="reloadPage(selected.row, selected.pagination);">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'pagination-data',
    data () {
      return {
        selected: {
          row: 15,
          pagination: 1
        }
      }
    },
    props: {
      flex: {
        type: String,
        default: 'center'
      },
      padding: {
        type: String,
        default: 'pt-0'
      }
    },
    watch: {
      'selected.row': function (val) {
        this.selected.pagination = 1

        this.changePage(val, this.selected.pagination)
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      pagination () {
        this.selected.pagination = this.$attrs.model.current

        this.$attrs.model.detail = this.$attrs.model.detail.length < 1 ? [1] : this.$attrs.model.detail

        return this.$attrs.model
      }
    },
    methods: {
      changePage (row, page) {
        this.$emit('changePage', { row: row, page: page })
      },
      reloadPage (row, page) {
        this.$emit('reloadPage', { row: row, page: page })
      }
    }
  }
</script>