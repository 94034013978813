<template>
  <div class="fill-height" :class="rps === 'sm' || rps === 'xs' ? 'mt-8' : 'mt-12'">
    <DialogAuth :dialogauth="dialog.auth" @close="dialog.auth = ''" />
    <DialogMessage :dialogmessage="dialog.message" :message="message" @close="dialog.message = ''" />

    <v-dialog v-model="dialog.share"
      width="375"
      persistent>
      <v-card 
        color="white"
        class="border-radius pa-5">
        <v-list-item class="pa-0">
          <v-list-item-avatar size="50" class="ma-0">
            <v-icon large class="mr-1">mdi-share-variant-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="body-1 font-weight-bold text-second">
              Share {{ basic.kelas_nama }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="mt-1 text-third">
              Klik icon di bawah untuk share event ini!
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-row justify="center" align="center" class="pt-3 pb-5">
          <v-col cols="3" class="text-center"
            v-for="(item, index) in list.sosmed"
            :key="index">
            <v-avatar
              tile
              width="50"
              height="50">
              <ShareNetwork
                :network="item.network"
                :url="`${host}${$route.path}`"
                :title="basic.kelas_slug"
                :description="basic.kelas_nama"
                hashtags="e-learning,bidankita">
                <v-img
                  width="45"
                  height="45"
                  contain
                  alt="e-learning"
                  :src="item.icon">
                </v-img>
              </ShareNetwork>
            </v-avatar>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn
            rounded
            block
            color="#333333"
            elevation="3"
            class="body-2 font-weight-bold white--text text-capitalize"
            @click="dialog.share = false">
            Tidak Sekarang
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-layout wrap>
      <v-flex>
        <section>
          <div class="d-flex align-center">
            <no-ssr>
              <carousel-3d 
                ref="carousel3dBanner"
                :autoplay="false"
                :perspective="0"
                :border="0"
                :space="3400"
                :width="1700"
                :height="rps === 'sm' ? 1125 : rps === 'xs' ? 1000 : 600"
                :count="list.banner.length"
                :display="3"
                @after-slide-change="onAfterSlideChange">
                <slide v-for="(item, index) in process.banner ? 3 : list.banner" :key="index" :index="index"
                  style="background: none; border-radius: 0px;">
                  <v-skeleton-loader
                    class="ma-auto skeleton-banner"
                    :loading="process.banner"
                    type="image">
                    <div> 
                      <v-card v-if="!process.banner && list.banner.length > 0"
                        :height="rps === 'sm' ? 575 : rps === 'xs' ? 450 : 600"
                        color="transparent"
                        flat
                        style="border-radius: 0px;">
                        <v-img v-if="item.banner_content_jsonobject.file.type === 'image'"
                          class="ma-auto"
                          :height="rps === 'sm' ? 575 : rps === 'xs' ? 450 : 600"
                          :class="rps === 'sm' ? 'pa-10' : rps === 'xs' ? 'px-6 py-3' : 'pa-16'"
                          :gradient="selected.banner === index ? 'to left, rgba(225,225,225,0), rgba(0,0,0,0.75)' : ''"
                          :src="item.banner_content_jsonobject.file.value.url">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>

                          <v-row align="center" :class="selected.banner === index ? 'white--text' : ''">
                            <v-col cols="12" md="7">
                              <p 
                                :class="rps !== 'xs' ? 'title mb-6' : 'body-1 mb-2'"
                                class="font-weight-light text-capitalize">
                                Yesie Aprillia
                              </p>

                              <p class="text-capitalize" 
                                :class="rps !== 'xs' ? 'text-h4 line-text-first' : 'body-1 font-weight-bold line-text-second mb-2'"
                                :style="rps !== 'xs' ? 'height: 100px;' : 'height: 30px;'">
                                <span :class="rps !== 'xs' ? 'two-line' : 'one-line'">
                                  {{ item.banner_title }}
                                </span>
                              </p>

                              <p v-if="item.banner_content_jsonobject.desc !== '' && (rps !== 'sm' && rps !== 'xs')"
                                :class="rps !== 'xs' ? 'title mt-6 mb-12' : 'body-1 mb-2'"
                                class="font-weight-light line-text-second" 
                                :style="rps === 'sm' ? 'height: 100px;' : rps === 'xs' ? 'height: 70px;' : 'height: 155px;'">
                                <span :class="rps === 'sm' ? 'three-line' : rps === 'xs' ? 'two-line' : 'four-line'">
                                  {{ item.banner_content_jsonobject.desc }}
                                </span>
                              </p>

                              <v-btn v-if="item.banner_content_jsonobject.action !==''"
                                elevation="0"
                                rounded
                                :small="rps === 'xs'"
                                color="white"
                                class="body-2 text-capitalize color-first"
                                :href="`https://elearning.bidankita.com/elearning/${item.banner_content_jsonobject.action_data.kelas_slug}`"
                                target="_blank">
                                Selengkapnya
                                <v-icon small class="ml-3" color="#FF3177">mdi-arrow-right</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-img>

                        <v-img v-if="item.banner_content_jsonobject.file.type !== 'image' && (selected.player === '' || selected.player_index !== index)"
                          class="ma-auto"
                          :height="rps === 'sm' ? 575 : rps === 'xs' ? 450 : 600"
                          :class="rps === 'sm' ? 'pa-10' : rps === 'xs' ? 'px-6 py-3' : 'pa-16'"
                          :gradient="selected.banner === index ? 'to left, rgba(225,225,225,0), rgba(0,0,0,0.75)' : ''"
                          :src="item.banner_content_jsonobject.thumbnail">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>

                          <v-row align="center" :class="selected.banner === index ? 'white--text' : ''">
                            <v-col cols="12" md="7">
                              <p 
                                :class="rps !== 'xs' ? 'title mb-6' : 'body-1 mb-2'"
                                class="font-weight-light text-capitalize">
                                Yesie Aprillia
                              </p>

                              <p class="text-capitalize" 
                                :class="rps !== 'xs' ? 'text-h4 line-text-first' : 'body-1 font-weight-bold line-text-second mb-2'"
                                :style="rps !== 'xs' ? 'height: 100px;' : 'height: 30px;'">
                                <span :class="rps !== 'xs' ? 'two-line' : 'one-line'">
                                  {{ item.banner_title }}
                                </span>
                              </p>

                              <p v-if="item.banner_content_jsonobject.desc !== '' && (rps !== 'sm' && rps !== 'xs')"
                                :class="rps !== 'xs' ? 'title mt-6 mb-12' : 'body-1 mb-2'"
                                class="font-weight-light line-text-second" 
                                :style="rps === 'sm' ? 'height: 100px;' : rps === 'xs' ? 'height: 70px;' : 'height: 155px;'">
                                <span :class="rps === 'sm' ? 'three-line' : rps === 'xs' ? 'two-line' : 'four-line'">
                                  {{ item.banner_content_jsonobject.desc }}
                                </span>
                              </p>

                              <div class="d-flex align-center">
                                <div>
                                  <v-btn v-if="item.banner_content_jsonobject.action !==''"
                                    elevation="0"
                                    rounded
                                    :small="rps === 'xs'"
                                    color="white"
                                    class="body-2 text-capitalize color-first"
                                    :href="`https://elearning.bidankita.com/elearning/${item.banner_content_jsonobject.action_data.kelas_slug}`"
                                    target="_blank">
                                    Selengkapnya
                                    <v-icon small class="ml-3" color="#FF3177">mdi-arrow-right</v-icon>
                                  </v-btn>
                                </div>

                                <v-spacer/>

                                <div v-if="rps === 'sm' || rps === 'xs'">
                                  <v-btn
                                    elevation="0"
                                    color="white"
                                    fab
                                    width="50"
                                    height="50"
                                    @click="selected.player = item.banner_content_jsonobject.file.type; selected.player_index = index;">
                                    <v-icon size="25" color="#FF3177">mdi-play</v-icon>
                                  </v-btn>
                                </div>
                              </div>
                            </v-col>

                            <v-col cols="12" md="5" class="d-flex align-enter justify-center" v-if="rps !== 'sm' && rps !== 'xs'">
                              <v-btn
                                elevation="0"
                                color="white"
                                fab
                                width="90"
                                height="90"
                                @click="selected.player = item.banner_content_jsonobject.file.type; selected.player_index = index;">
                                <v-icon size="50" color="#FF3177">mdi-play</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-img>

                        <div v-if="item.banner_content_jsonobject.file.type === 'video' && selected.player === 'video' && selected.player_index === index">
                          <video
                            controls 
                            width="100%" 
                            :height="rps === 'sm' ? 575 : rps === 'xs' ? 450 : 600"
                            style="object-fit: cover;"
                            :poster="item.banner_content_jsonobject.file.thumbnail">
                            <source :src="item.banner_content_jsonobject.file.value.url">
                          </video>
                        </div>

                        <div v-if="item.banner_content_jsonobject.file.type === 'youtube' && selected.player === 'youtube' && selected.player_index === index">
                          <iframe 
                            width="100%"
                            :height="rps === 'sm' ? 575 : rps === 'xs' ? 450 : 600"
                            :src="`https://www.youtube.com/embed/${item.banner_content_jsonobject.file.value.id}?autoplay=1`" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowtransparency
                            allowfullscreen>
                          </iframe>
                        </div>
                      </v-card>
                    </div>
                  </v-skeleton-loader>
                </slide>
              </carousel-3d>
            </no-ssr>
          </div>

          <div 
            class="text-center"
            style="position: relative; top: -150px;"> 
            <v-btn 
              v-for="(item, index) in list.banner" 
              :key="index"
              :width="selected.banner === index ? 12 : 15"
              :height="selected.banner === index ? 17 : 15"
              elevation="0"
              x-small
              :rounded="selected.banner === index"
              :fab="selected.banner !== index"
              :color="selected.banner === index ? '#FF3177' : 'grey lighten-2'"
              class="mx-2"
              @click="goToSlide(index)">
            </v-btn>

            <v-container>
              <v-row>
                <v-col
                  cols="12">
                  <v-card
                    color="#f6f6f6"
                    class="box-shadow border-radius">
                    <v-card-text
                      class="text-center">
                      <p class="text-h5 text-first mt-4 mb-2">
                        <span class="color-first">
                          Pertanyaan
                        </span>
                        Bidan Kita
                      </p>
                      <p class="text-first body-1 font-weight-light line-text-second mb-0">
                        Anda berhak mendapatkan program edukasi yang telah disesuaikan dengan kebutuhan Anda.
                      </p>
                    </v-card-text>

                    <v-card-text
                      class="pa-0">
                      <no-ssr>
                        <swiper class="swiper" 
                          ref="swiperQuestion" 
                          :options="swiperOption"
                          @slideChange="onSlideChangeQuestion">
                          <swiper-slide v-for="(item, index) in list.question" :key="index" 
                            class="pt-6 pb-4"
                            :class="index < 1 ? 'pl-5 pr-3' : index >= list.question.length - 1 ? 'pl-3 pr-5' : 'px-3'" 
                            style="width: auto;">
                            <v-skeleton-loader
                              class="ma-auto"
                              width="300"
                              height="150"
                              type="card, article">
                              <div>
                                <v-card
                                  width="300"
                                  height="150"
                                  class="box-shadow border-radius pa-6">
                                  <v-card-text class="pa-0 text-center">
                                    <v-avatar size="50" color="#f0f0f0">
                                      <v-img 
                                        contain
                                        width="75"
                                        height="75"
                                        :src="item.icon">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-avatar>
                                  </v-card-text>

                                  <div class="body-2 line-text-second text-first font-weight-light mt-6 mb-5" style="height: 80px;">
                                    "{{ item.desc }}"
                                  </div>
                                </v-card>
                              </div>
                            </v-skeleton-loader>
                          </swiper-slide>
                        </swiper>
                      </no-ssr>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

          </div>
        </section>

        <section
          style="position: relative; top: -80px;">
          <v-container>
            <v-row justify="center" v-if="process.streaming || list.streaming.length > 0">
              <v-col cols="12" md="8" sm="6" class="text-center">
                <p class="text-h5 text-first mb-2">
                  <span class="color-first">
                    Kelas
                  </span>
                  Bidan Kita
                </p>
                <p class="text-first body-1 font-weight-light line-text-second mb-0">
                 Berikut ini berbagai program layanan kelas yang bisa Anda ikuti.
                </p>
              </v-col>
            </v-row>

            <v-row 
              class="py-6">
              <v-col 
                cols="12" 
                md="4"
                class="pa-1"
                v-for="(item, index) in process.streaming ? 12 : list.streaming"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.streaming"
                  type="image, article">
                  <div>
                    <v-hover
                      v-slot="{ hover }"
                      open-delay="200">
                      <div>
                        <v-card v-if="!process.streaming && list.streaming.length > 0"
                          class="box-shadow"
                          width="100%"
                          height="250"
                          style="border-radius: 0px;"
                          :style="item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime ? 'opacity: 50%;' : ''"
                          :to="rps !== 'xs' ? '' : item.schedule_is_purchased === '0' ? 
                              `/streaming/${item.kelas_slug}?schedule_id=${item.schedule_id}` : 
                              `/collection/streaming/${item.kelas_id}?schedule_id=${item.schedule_id}`">
                          <v-img
                            width="100%"
                            height="100%"
                            gradient="to left, rgba(0,0,0,0.25), rgba(0,0,0,0.75)"
                            :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <v-container
                              class="py-0">
                              <v-row
                                align="start"
                                style="height: 190px;">
                                <v-col
                                  cols="4"
                                  class="pr-0">
                                  <div
                                    class="text-center white--text my-4">
                                    <div 
                                      class="font-weight-bold mb-2"
                                      :class="rps !== 'xs' ? 'text-h3' : 'title'">
                                      {{ item.schedule_start_datetime | day }}
                                    </div>

                                    <div 
                                      class="text-uppercase my-1"
                                      :class="rps !== 'xs' ? 'title' : 'body-2'">
                                      {{ item.schedule_start_datetime | month }} {{ item.schedule_start_datetime | year }}
                                    </div>

                                    <div 
                                      class="font-weight-bold"
                                      :class="rps !== 'xs' ? 'text-h4' : 'body-1'">
                                      {{ item.schedule_start_datetime | time }}
                                    </div>
                                  </div>
                                </v-col>

                                <v-col
                                  cols="1"
                                  class="text-center">
                                  <v-divider
                                    vertical
                                    style="border: 1px solid #FFFFFF; background: #FFFFFF; border-radius: 50px; height: 165px;" />
                                </v-col>

                                <v-col
                                  cols="7"
                                  class="pl-0">
                                  <div class="mb-2">
                                    <div v-if="dateNow <= item.schedule_end_datetime">
                                      <div v-if="item.schedule_status !== 'Selesai'">
                                        <v-chip v-if="dateNow > item.schedule_early_price_end_datetime"
                                          style="border: 1px solid #ffffffb3 !important;"
                                          color="#616161"
                                          label
                                          :small="rps !== 'xs'"
                                          :x-small="rps === 'xs'"
                                          text-color="white"
                                          class="font-weight-bold text-uppercase">
                                          NORMAL PRICE
                                        </v-chip>

                                        <v-chip v-else
                                          style="border: 1px solid #ffffffb3 !important;"
                                          color="green"
                                          label
                                          :small="rps !== 'xs'"
                                          :x-small="rps === 'xs'"
                                          text-color="white"
                                          class="font-weight-bold text-uppercase">
                                          EARLY PRICE
                                        </v-chip>

                                        <div 
                                          class="text-second caption white--text mt-2">
                                          s.d. 
                                          <span v-if="dateNow >= item.schedule_early_price_end_datetime">
                                            {{ item.schedule_normal_price_end_datetime | date }}
                                          </span>

                                          <span v-else>
                                            {{ item.schedule_early_price_end_datetime | date }}
                                          </span>
                                        </div>
                                      </div>

                                      <div v-else>
                                        <v-chip
                                          style="border: 1px solid #ffffffb3 !important;"
                                          color="red"
                                          label
                                          :small="rps !== 'xs'"
                                          :x-small="rps === 'xs'"
                                          text-color="white"
                                          class="font-weight-bold text-uppercase">
                                          {{ item.schedule_status }}
                                        </v-chip>

                                        <div 
                                          class="text-second caption white--text mt-2">
                                          pada {{ item.schedule_end_datetime | date }} Pukul {{ item.schedule_end_datetime | time }}
                                        </div>
                                      </div>
                                    </div>

                                    <div v-else>
                                      <v-chip
                                        style="border: 1px solid #ffffffb3 !important;"
                                        color="red"
                                        label
                                        :small="rps !== 'xs'"
                                        :x-small="rps === 'xs'"
                                        text-color="white"
                                        class="font-weight-bold text-uppercase">
                                        {{ item.schedule_status !== 'Selesai' ? 'Pendaftaran Berakhir' : item.schedule_status }}
                                      </v-chip>

                                      <div 
                                        class="text-second caption white--text mt-2">
                                        pada {{ item.schedule_end_datetime | date }} Pukul {{ item.schedule_end_datetime | time }}
                                      </div>
                                    </div>
                                  </div>
                                  
                                  <div
                                    class="text-h5 white--text font-weight-bold two-line mb-2">
                                    {{ item.kelas_nama }}
                                  </div>

                                  <div class="d-flex align-center">
                                    <v-rating
                                      :value="parseFloat(item.kelas_total_rating)"
                                      background-color="yellow darken-2"
                                      color="yellow darken-2"
                                      dense
                                      readonly
                                      size="18">
                                    </v-rating>
                                    <span class="white--text caption font-weight-bold ml-1">
                                      ({{ parseFloat(item.kelas_total_rating).toFixed(1) }})
                                    </span>
                                  </div>
                                </v-col>
                              </v-row>

                              <v-row
                                align="center">
                                <v-col
                                  cols="8">
                                  <v-chip
                                    color="#ffffffb3"
                                    style="min-width: 100%;">
                                    <!-- <span
                                      class="mr-1">
                                      <v-icon size="20">mdi-currency-usd</v-icon>
                                    </span> -->

                                    <span v-if="!item.kelas_is_discount"
                                      class="font-weight-bold">
                                      <span class="green--text" v-if="parseInt(item.schedule_early_price) < 1 && parseInt(item.schedule_normal_price) < 1">
                                        Gratis
                                      </span>

                                      <span class="color-first" v-else>
                                        <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                          {{ parseInt(item.schedule_early_price) | price }}
                                        </span>

                                        <span v-else>
                                          {{ parseInt(item.schedule_normal_price) | price }}
                                        </span>
                                      </span>
                                    </span>

                                    <span class="orange--text" v-if="item.kelas_is_discount">
                                      <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                        {{ parseInt(item.schedule_early_price) - ((parseInt(item.schedule_early_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                      </span>

                                      <span v-else>
                                        {{ parseInt(item.schedule_normal_price) - ((parseInt(item.schedule_normal_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                      </span>
                                    </span>
                                  </v-chip>
                                </v-col>

                                <v-col
                                  cols="4">
                                  <v-chip
                                    color="#ffffffb3"
                                    style="min-width: 100%;">
                                    <span v-if="item.schedule_is_purchased === '0'"
                                      class="body-2">
                                      <span class="font-weight-bold blue--text">{{ item.schedule_quota_left }}</span> dari {{ item.schedule_quota }}
                                    </span>

                                    <span v-if="item.schedule_is_purchased === '1'"
                                      class="body-2 font-weight-bold">
                                      <span
                                        :class="item.schedule_status === 'Belum Mulai' ? 'blue--text' : 
                                                item.schedule_status === 'Sedang Berlangsung' ? 'green--text' : 'red--text'">
                                        {{ item.schedule_status }}
                                      </span>
                                    </span>
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-img>
                        </v-card>

                        <v-expand-transition>
                          <div v-if="hover && rps !== 'xs'">
                            <v-card
                              width="100%"
                              height="510"
                              outlined
                              style="border-radius: 0px !important;"
                              :class="index === 2 || index === 5 || index === 8 || index === 11 ? 'on-hover-right arrow-right' : 'on-hover-left arrow-left'">
                              <v-card-text style="width: auto;">
                                <p class="body-1 font-weight-bold line-text-first text-first text-capitalize">
                                  <span class="two-line">
                                    {{ item.kelas_nama }}
                                  </span>
                                </p>

                                <p class="body-2 text-second mb-0">
                                  <span class="one-line">
                                    {{ item.kelas_kategori_title }}
                                  </span>
                                </p>

                                <v-list flat dense>
                                  <v-list-item class="px-0">
                                    <v-list-item-avatar size="30" class="mr-2">
                                      <v-img :src="item.kelas_author.kelas_author_photo">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              size="25"
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ item.kelas_author.kelas_author_nama }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>

                                <div style="height: 265px;">
                                  <p class="body-2 font-weight-light text-first line-text-first">
                                    <span class="nine-line" v-html="item.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                  </p>

                                  <p class="body-2 font-weight-light text-first line-text-first mb-0">
                                    <v-icon small class="mr-1">mdi-calendar-check</v-icon>
                                    Terakhir update <b>{{ item.kelas_update_datetime | date }}</b>
                                  </p>
                                </div>
                              </v-card-text>

                              <v-card-text class="d-flex align-center justify-center"
                                style="position: absolute; bottom: 10px; width: auto;">
                                <v-btn
                                  rounded
                                  width="160"
                                  elevation="0"
                                  color="#FF3177"
                                  class="text-capitalize white--text mr-2"
                                  :disabled="item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime"
                                  :to="item.schedule_is_purchased === '0' ? 
                                      `/streaming/${item.kelas_slug}?schedule_id=${item.schedule_id}` : 
                                      `/collection/streaming/${item.kelas_id}?schedule_id=${item.schedule_id}`">
                                  {{  item.schedule_is_purchased === '0' ? 'Lihat Jadwal' : 'Masuk Kelas' }}
                                </v-btn>

                                <v-btn
                                  fab
                                  width="40"
                                  height="40"
                                  elevation="0"
                                  :disabled="process.wishlist || item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime"
                                  :loading="process.wishlist"
                                  @click="item.kelas_is_wishlist = item.kelas_is_wishlist === '0' ? '1' : '0'; 
                                    addWishlist(item.kelas_id, item.kelas_is_wishlist)">
                                  <v-icon color="#FF3177">
                                    {{ !token ? 'mdi-heart-outline' : item.kelas_is_wishlist === '0' ? 'mdi-heart-outline' : 'mdi-heart' }}
                                  </v-icon>
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-expand-transition>
                      </div>
                    </v-hover>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <div 
              v-if="!process.streaming && list.streaming.length > 7"
              class="text-center mt-6">
              <v-btn
                elevation="0"
                rounded
                color="#FF3177"
                to="/streaming"
                class="text-capitalize white--text">
                Lihat Semuanya
                <v-icon small class="ml-3" color="#FFFFFF">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-container>
        </section>
        
        <section
          class="mt-8">
          <v-container>
            <p class="text-h5 text-first">
              <span class="color-first">
                Pengajar
              </span>
              Bidan Kita
            </p>

            <v-row justify="center">
              <v-col cols="12" md="6" sm="6">
                <v-row>
                  <v-col cols="12" md="7" sm="7">
                    <v-skeleton-loader
                      class="ma-auto"
                      :loading="process.mentor"
                      type="card, article">
                      <div>
                        <v-img v-if="!process.mentor && list.mentor.length > 0"
                          cover
                          :width="rps !== 'xs' ? 375 : '100%'"
                          height="375"
                          class="border-radius box-shadow"
                          :src="list.mentor[0].mentor_image_cover_url">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>

                          <div class="body-1 font-weight-bold white--text text-capitalize pa-6 py-3"
                            style="
                              background: rgb(0 0 0 / 50%);
                              position: absolute;
                              bottom: 0px;
                              width: 100%;">
                            <p class="ml-1 mb-0">
                              {{ list.mentor[0].mentor_kelas_user_nama }}
                            </p>

                            <div class="d-flex align-center">
                              <v-rating dense
                                :value="5"
                                small
                                readonly
                                color="yellow darken-2"
                                background-color="yellow darken-2">
                              </v-rating>
                              <span class="white--text caption font-weight-bold ml-1">
                                ({{ 5 }})
                              </span>
                            </div>
                          </div>
                        </v-img>
                      </div>
                    </v-skeleton-loader>
                  </v-col>

                  <v-col cols="12" md="5" sm="5">
                    <v-row>
                      <v-col cols="6" md="12" sm="12" 
                        v-for="(item, index) in process.mentor ? 2 : list.mentor"
                        :key="index"
                        :class="rps !== 'xs' && index < 2 ? 'pt-0' : ''"
                        v-show="!process.mentor ? index > 0 : 2">
                        <v-skeleton-loader
                          class="ma-auto"
                          :loading="process.mentor"
                          type="image">
                          <div>
                            <v-img v-if="!process.mentor && list.mentor.length > 0"
                              cover
                              :width="rps !== 'xs' ? 175 : '100%'"
                              height="175"
                              class="border-radius box-shadow"
                              :src="item.mentor_image_cover_url">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#FF3177">
                                  </v-progress-circular>
                                </v-row>
                              </template>

                              <div class="caption font-weight-bold white--text text-capitalize pa-2"
                                style="
                                  background: rgb(0 0 0 / 50%);
                                  position: absolute;
                                  bottom: 0px;
                                  width: 100%;">
                                <p class="one-line mb-0">
                                  {{ item.mentor_kelas_user_nama }}
                                </p>
                              </div>
                            </v-img>
                          </div>
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <p class="text-h5 text-first line-text-first mt-2 mb-6">
                  <span class="text-h4">Pengajar Spesialis</span> Yang Siap Membantu Keluhan Anda!
                </p>

                <p class="text-first body-1 font-weight-light line-text-second mb-2">
                  "All About Preparation" Persiapkan diri Anda bersama kami dengan mengikuti berbagai event kelas, kegiatan maupun treatment bersama team Bidan Kita baik ONLINE maupun OFFLINE demi tercapainya cita citamu untuk mendapatkan pengalaman positif lahir minim trauma (Gentle Birth).
                </p>

                <p class="text-first body-1 font-weight-light line-text-second mb-12">
                  Yuk segera ikuti kelas - kelas menarik untuk belajar mu hanya di <b>E-Learning Bidan Kita</b>.
                </p>

                <a 
                  href="https://elearning.bidankita.com/instructor" 
                  target="_blank" 
                  class="color-first">
                  <span>
                    Lihat pengajar lainnya
                  </span>
                  <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                </a>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section 
          class="py-16 my-16">
          <v-container :class="rps !== 'sm' && rps !== 'xs' ? 'pa-8' : 'pa-0'">
            <v-img
              width="100%"
              :height="rps !== 'sm' && rps !== 'xs' ? 'auto' : '600'"
              class="align-center"
              alt="elearning"
              :src="require('@/assets/background/home_download_background_oval.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#FF3177">
                  </v-progress-circular>
                </v-row>
              </template>
              
              <v-row justify="center" align="center">
                <v-col cols="12" md="6" sm="6">
                  <v-card
                    flat
                    color="transparent"
                    :class="rps === 'xs' ? 'px-6' : 'px-4'">
                    <p class="title font-weight-bold white--text">
                      Download Aplikasi nya Sekarang
                    </p>

                    <p class="body-1 font-weight-light white--text line-text-second">
                      Silahkan download aplikasi kami di playstore untuk memudahkan anda mengakses layanan kami.
                    </p>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4" sm="4">
                  <v-row justify="center" v-if="rps !== 'sm' && rps !== 'xs'">
                    <v-col cols="12" md="5"
                      v-for="(item, index) in download"
                      :key="index">
                      <a href="https://play.google.com/store/apps/details?id=com.elearningbidankita" target="_blank">
                        <v-img
                          alt="elearning"
                          contain
                          width="150"
                          height="50"
                          :src="item">
                        </v-img>
                      </a>
                    </v-col>
                  </v-row>

                  <div v-if="rps === 'sm' || rps === 'xs'"
                    class="d-flex justify-center mb-8">
                    <a href="https://play.google.com/store/apps/details?id=com.elearningbidankita" target="_blank"
                      v-for="(item, index) in download"
                      :key="index">
                      <v-img
                        alt="elearning"
                        contain
                        width="150"
                        height="50"
                        :src="item">
                      </v-img>
                    </a>
                  </div>
                </v-col>
              </v-row>
            </v-img>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  import moment from 'moment'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dateNow: moment().format('YYYY-MM-DD HH:mm:ss'),
        dialog: {
          auth: '',
          message: '',
          share: false,
          special: false
        },
        swiperOption: {
          slidesPerView: 'auto'
        },
        selected: {
          banner: 0,
          player: '',
          player_index: '',
          question: 0,
          class: 0,
          streaming: 0,
          discount: 0,
          feedback: 0,
          special: {
            elearning: 0,
            live: 0
          }
        },
        filter: {
          label_type: 'Semua Tipe',
          type: '',
          label_category: 'Semua Kategori',
          category: '',
          category_sub: '',
          title: ''
        },
        download: [
          require("@/assets/logo/play_store.png")
        ],
        basic: {},
        list: {
          banner: {},
          type: [],
          category: [{
            label: 'Semua Kategori',
            value: '',
            sub: []
          }],
          question: [
            {
              icon:"https://freeiconshop.com/wp-content/uploads/edd/person-girl-flat.png",
              desc:"Budhe, aku pengen banget lahiran normal, tapi aku bingung harus mulai belajar dari mana ya?",
            },
            {
              icon:"https://jual-properti.com/wp-content/uploads/2020/12/039_girl_avatar_profile_woman_headband-512-19babd11.png",
              desc:"Budhe, umur kehamilanku sudah 39w, tapi aku belum ada tanda tanda, aku harus ngapain?",
            },
            {
              icon:"https://fastbuildingintegrators.com/wp-content/uploads/2020/03/050_girl_avatar_profile_woman_suit_student_officer-512.png",
              desc:"Budhe, lahiran kemaren aku Sesar, lahiran yang ini aku pengen normal bisa nggak ya?",
            },
            {
              icon:"https://hkgroup.co.id/assets/images/friends-production/works/219961.png",
              desc:"Budhe, biar pas lahiran gak robek tu, caranya bagaimana ya?",
            },
            {
              icon:"https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/7b6f5523240799.5632176f43717.png",
              desc:"Tulang kemaluanku sakit bude! Apalagi kalau aku ganti posisi, Aku kudu pie?",
            },
            {
              icon:"https://i.pinimg.com/originals/a6/58/32/a65832155622ac173337874f02b218fb.png",
              desc:"Budhe, aku udah 37 minggu, tapi kepala janinku belum masuk panggul. katanya harus sesar, aku harus gimana?",
            }
          ],
          class: [],
          streaming: [],
          mentor: [],
          discount: [],
          feedback: [],
          tag: [],
          sosmed: [
            { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
            { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
            { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
            { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
          ],
          special: {
            elearning: [],
            live: []
          }
        },
        pagination: {
          special: {
            elearning: {},
            live: {}
          }
        },
        process: {
          banner: false,
          type: false,
          category: false,
          class: false,
          streaming: false,
          mentor: false,
          discount: false,
          feedback: false,
          tag: false,
          cart: false,
          wishlist: false,
          basic: false,
          special: {
            elearning: false,
            live: false
          }
        },
        message: '',
        // SEO
        content: {
          url: '',
          title: 'Beranda',
          description: 'Beranda E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      DialogMessage: () => import('@/components/dialog/message')
    },
    watch: {
      'filter.type': function(val) {
        this.filter.label_category = 'Semua Kategori'
        this.filter.category = ''
        this.filter.category_sub = ''
        
        this.fetchCategory()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      headers() {
        return this.$store.state.header
      },
      host() {
        return process.env.HOST_SHARE_PROD
      }
    },
    mounted () {
      // this.dialog.special = Cookie.get('special') === undefined ? false : true

      this.fetch()
    },
    methods: {
      fetch() {
        // SPECIAL PRICE
        // this.fetchSpecialElearning()
        // this.fetchSpecialLive()
        
        this.fetchBanner()
        this.fetchType()
        // this.fetchBasic()
        // this.fetchClass()
        this.fetchStreaming()
        this.fetchMentor()
        // this.fetchDiscount()
        // this.fetchTag()
        // this.fetchFeedback()
      },
      async fetchBanner() {
        this.process.banner = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/banner`)
        .then((response) => {
          if (response.status === 200) {
            this.process.banner = false
            this.list.banner = response.data.results
          }
        })
      },
      async fetchType() {
        this.process.type = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/type`)
        .then((response) => {
          if (response.status === 200) {
            this.process.type = false

            let data = response.data.results
            for (let i in data) {
              this.list.type.push({
                label: data[i].kelas_type_title,
                value: data[i].kelas_type_id
              })
            }

            this.filter.label_type = this.list.type[1].label
            this.filter.type = this.list.type[1].value
          }
        })
      },
      async fetchCategory() {
        this.process.category = true

        let params = {
          type_id: this.filter.type,
          sort: 'kelas_kategori_title',
          dir: 'ASC'
        }
        
        this.list.category = [{
          label: 'Semua Kategori',
          value: '',
          sub: []
        }]

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/category_nested`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.category = false
        
            let data = response.data.results
            for (let i in data) {
              this.list.category.push({
                label: data[i].kelas_kategori_title,
                value: data[i].kelas_kategori_id,
                sub: data[i].kelas_kategori_child
              })
            }
          }
        })
      },
      async fetchClass() {
        this.process.class = true

        let params = {
          limit: 8,
          sort: 'kelas_create_datetime',
          dir: 'DESC'
        }

        this.list.class = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.class = false
            response.data.results.filter(v => {
              v.kelas_deskripsi = v.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')
              this.list.class.push(v)
            })
          }
        })
      },
      async fetchStreaming() {
        this.process.streaming = true

        let params = {
          limit: 12,
          sort: 'schedule_start_datetime',
          dir: 'DESC'
        }

        this.list.streaming = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/live`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.streaming = false
            response.data.results.filter(v => {
              v.kelas_deskripsi = v.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')
              this.list.streaming.push(v)
            })
          }
        })
      },
      async fetchMentor() {
        this.process.mentor = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/mentor`)
        .then((response) => {
          if (response.status === 200) {
            this.process.mentor = false

            this.list.mentor = response.data.results
          }
        })
      },
      async fetchDiscount() {
        this.process.discount = true

        let params = {
          limit: 6,
          sort: 'kelas_create_datetime',
          dir: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/kelas_by_diskon`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.discount = false
            this.list.discount = response.data.results
          }
        })
      },
      async fetchTag() {
        this.process.tag = true

        let params = {
          limit: 10,
          sort: 'kelas_create_datetime',
          dir: 'DESC',
          filter: [
            {
              type: 'string',
              field: 'kelas_kategori_id',
              value: this.filter.category
            }
          ]
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.tag = false
            this.list.tag = response.data.results
          }
        })
      },
      async fetchFeedback() {
        this.process.feedback = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/testimony`)
        .then((response) => {
          if (response.status === 200) {
            this.process.feedback = false
            this.list.feedback = response.data.results
          }
        })
      },
      async fetchBasic() {
        this.process.basic = true

        let params = {
          limit: 1,
          filter: [
            {
              type: 'string',
              field: 'kelas_id',
              value: 3,
              // comparison: '<>'
            }
          ]
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.basic = false
            this.basic = response.data.pagination.total_data > 0 ? response.data.results[0] : {}
          }
        })
      },
      onAfterSlideChange(index) {
        this.selected.banner = index

        this.stop()
      },
      goToSlide(index) {
        this.selected.banner = index
        this.$refs.carousel3dBanner.goSlide(index)

        this.stop()
      },
      goToSlidePrev(index) {
        this.selected.banner = index === 0 ? this.list.banner.length - 1 : this.selected.banner - 1
        this.$refs.carousel3dBanner.goSlide(this.selected.banner)

        this.stop()
      },
      goToSlideNext(index) {
        this.selected.banner = index === this.list.banner.length - 1 ? 0 : this.selected.banner + 1
        this.$refs.carousel3dBanner.goSlide(this.selected.banner)

        this.stop()
      },
      search() {
        window.open(`https://elearning.bidankita.com/elearning?title=${this.filter.title}&label_type=${this.filter.label_type}&type=${this.filter.type}&label_category=${this.filter.label_category}&category=${this.filter.category}&category_sub=${this.filter.category_sub}}`, '_blank')
      },
      stop() {
        let iframe = this.$el.querySelector( 'iframe')
        let video = this.$el.querySelector( 'video' )
        if ( iframe ) {
          let iframeSrc = iframe.src
          iframe.src = iframeSrc
        }
        if ( video ) {
          video.pause()
        }

        this.selected.player = ''
        this.selected.player_index = ''
      },
      onSlideChangeQuestion() {
        this.selected.question = this.$refs.swiperQuestion.$swiper.activeIndex
      },
      onSlideChangeQuestionIndex(index) {
        this.$refs.swiperQuestion.$swiper.slideTo(index, 1000, false)
        this.selected.question = index
      },
      onSlideChangeClass() {
        this.selected.class = this.$refs.swiperClass.$swiper.activeIndex
      },
      onSlideChangeClassIndex(index) {
        this.$refs.swiperClass.$swiper.slideTo(index, 1000, false)
        this.selected.class = index
      },
      onSlideChangeStreaming() {
        this.selected.streaming = this.$refs.swiperStreaming.$swiper.activeIndex
      },
      onSlideChangeStreamingIndex(index) {
        this.$refs.swiperStreaming.$swiper.slideTo(index, 1000, false)
        this.selected.streaming = index
      },
      onSlideChangeDiscount() {
        this.selected.discount = this.$refs.swiperDiscount.$swiper.activeIndex
      },
      onSlideChangeDiscountIndex(index) {
        this.$refs.swiperDiscount.$swiper.slideTo(index, 1000, false)
        this.selected.discount = index
      },
      onSlideChangeFeedback() {
        this.selected.feedback = this.$refs.swiperFeedback.$swiper.activeIndex
      },
      onSlideChangeFeedbackIndex(index) {
        this.$refs.swiperFeedback.$swiper.slideTo(index, 1000, false)
        this.selected.feedback = index
      },
      async addToCart(kelas_id) {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveCart(kelas_id)
        }
      },
      async saveCart(kelas_id) {
        this.process.cart = true

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/cart/add`, {
          kelas_id: kelas_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.cart = false

            this.$store.commit('header/setCart', this.headers.cart + 1)

            this.dialog.message = 'success'
            this.message = 'Berhasil ditambahkan ke Keranjang.'
          } else {
            this.process.cart = false
            
            this.dialog.message = 'error'
            this.message = response.message
          }
        })
      },
      async addWishlist(kelas_id, kelas_is_wishlist) {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveWishlist(kelas_id, kelas_is_wishlist)
        }
      },
      async saveWishlist(kelas_id, kelas_is_wishlist) {
        this.process.wishlist = true

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/wishlist/add_remove`, {
          kelas_id: kelas_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.wishlist = false
            
            this.dialog.message = 'wishlist'
            this.message = kelas_is_wishlist === '1' ? 'Berhasil ditambahkan ke Wishlist.' : 'Berhasil dihapus dari Wishlist.'
          }
        })
      },
      closed(type) {
        this.dialog.special = false

        if (type === 'cookie') Cookie.remove('special')
      },
      async fetchSpecialElearning() {
        this.process.special.elearning = true

        let params = {
          limit: 6,
          sort: 'kelas_create_datetime',
          dir: 'DESC'
        }

        this.list.special.elearning = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/basic/special_price`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.special.elearning = false

            response.data.results.filter(v => {
              v.kelas_deskripsi = v.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')
              this.list.special.elearning.push(v)
            })
            this.pagination.special.elearning = response.data.pagination
          }
        })
      },
      onSlideChangeSpecialElearning() {
        this.selected.special.elearning = this.$refs.swiperSpecialElearning.$swiper.activeIndex
      },
      onSlideChangeSpecialElearningIndex(index) {
        this.$refs.swiperSpecialElearning.$swiper.slideTo(index, 1000, false)
        this.selected.special.elearning = index
      },
      async fetchSpecialLive() {
        this.process.special.live = true

        let params = {
          limit: 6,
          sort: 'kelas_create_datetime',
          dir: 'DESC'
        }

        this.list.special.live = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/live/special_price`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.special.live = false

            response.data.results.filter(v => {
              v.kelas_deskripsi = v.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')
              this.list.special.live.push(v)
            })
            this.pagination.special.live = response.data.pagination
          }
        })
      },
      onSlideChangeSpecialLive() {
        this.selected.special.live = this.$refs.swiperSpecialLive.$swiper.activeIndex
      },
      onSlideChangeSpecialLiveIndex(index) {
        this.$refs.swiperSpecialLive.$swiper.slideTo(index, 1000, false)
        this.selected.special.live = index
      }
    }
  }
</script>

<style scoped>
  .search-style.v-text-field--outlined >>> fieldset {
    border-color: #dadada;
    border-left: 0px;
  }
  .on-hover-left {
    position: absolute;
    top: 0px;
    right: -395px;
    z-index: 9;
    border-radius: 5px !important;
  }
  .on-hover-left.arrow-left:after {
    content: "";
    position: absolute;
    left: -10px;
    top: 126px;
    border-top: 10px solid transparent;
    border-right: 10px solid #ffffff;
    border-left: none;
    border-bottom: 10px solid transparent;
  }
  .on-hover-left.arrow-left:before {
    content: "";
    position: absolute;
    left: -11px;
    top: 125px;
    border-top: 11px solid transparent;
    border-right: 11px solid #dadada;
    border-left: none;
    border-bottom: 11px solid transparent;
  }


  .on-hover-right {
    position: absolute;
    top: 0px;
    left: -395px;
    z-index: 9;
    border-radius: 5px !important;
  }
  .on-hover-right.arrow-right:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 126px;
    border-top: 10px solid transparent;
    border-right: none;
    border-left: 10px solid #ffffff;
    border-bottom: 10px solid transparent;
  }
  .on-hover-right.arrow-right:before {
    content: "";
    position: absolute;
    right: -11px;
    top: 125px;
    border-top: 11px solid transparent;
    border-right: none;
    border-left: 11px solid #dadada;
    border-bottom: 11px solid transparent;
  }
</style>

<style>
  .skeleton-banner .v-skeleton-loader__image {
    height: 600px !important;
  }
</style>