<template>
  <div class="fill-height pt-12 pb-6" :class="rps !== 'xs' ? 'mt-4' : 'mt-2'">
    <DialogAuth :dialogauth="dialog.auth" @close="dialog.auth = ''" />
    <DialogMessage :dialogmessage="dialog.message" :message="message" @close="dialog.message = ''" />

    <v-dialog v-model="filter.dialog"
      width="500"
      persistent>
      <v-card 
        color="#f3f3f3"
        class="border-radius pa-2">
        <v-card-text class="pa-0 text-right">
          <v-btn
            fab
            x-small
            elevation="0"
            class="background-first white--text"
            @click="filter.dialog = false; reset();">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text>
          <v-form>   
            <v-row>
              <v-col cols="12" md="8" sm="8" class="py-0">
                <v-select
                  dense
                  outlined
                  color="#FF3177"
                  single-line
                  :items="mentor"
                  item-color="#FF3177"
                  item-text="mentor_kelas_user_nama"
                  item-value="mentor_kelas_user_id"
                  placeholder="Pengajar"
                  clearable
                  v-model="filter.mentor">
                  <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                      <v-avatar size="30">
                        <v-img 
                          :src="item.mentor_image_cover_url !=='' ? item.mentor_image_cover_url : require('@/assets/image/member-default.jpg')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>
                      <span class="text-capitalize ml-2">
                        {{ item.mentor_kelas_user_nama }}
                      </span>
                    </div>
                  </template>

                  <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                      <v-avatar size="30">
                        <v-img 
                          :src="item.mentor_image_cover_url !=='' ? item.mentor_image_cover_url : require('@/assets/image/member-default.jpg')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>
                      <span class="text-capitalize ml-2">
                        {{ item.mentor_kelas_user_nama }}
                      </span>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="6" class="py-0">
                <v-select
                  dense
                  outlined
                  color="#FF3177"
                  single-line
                  :items="[
                    {
                      text: 'Semua Sorting',
                      value: ''
                    },
                    {
                      text: 'Terbaru',
                      value: 'Terbaru'
                    },
                    {
                      text: 'Termahal',
                      value: 'Termahal'
                    },
                    {
                      text: 'Termurah',
                      value: 'Termurah'
                    },
                    {
                      text: 'Review Terbanyak',
                      value: 'Review Terbanyak'
                    }
                  ]"
                  item-text="text"
                  item-value="value"
                  item-color="#FF3177"
                  clearable
                  placeholder="Sorting"
                  v-model="filter.sort">
                </v-select>
              </v-col>
            </v-row>

            <v-row justify="end">
              <v-col cols="12" md="4" sm="4" class="py-0 text-right">
                <v-btn
                  block
                  rounded
                  elevation="0"
                  color="#FF3177"
                  class="text-capitalize white--text"
                  @click="filter.dialog = false; fetch();">
                  <v-icon class="mr-1">mdi-magnify</v-icon>
                  Cari
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-layout wrap>
      <v-flex>
        <section style="background: #fff; box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.05) !important;"
          :style="rps !== 'xs' ? 'position: sticky; top: 64px; z-index: 10;' : ''">
          <v-divider style="border: 1px solid #f9f9f9;"/>

          <v-container class="pb-0" style="background: #fff; box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.05) !important;"
            :class="rps !== 'xs' ? 'pt-2' : 'pt-0'">
            <v-row align="start" justify="end">
              <v-col cols="12" md="7" :class="rps !== 'xs' ? 'py-0' : ''">
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="#FF3177"
                  slider-size="5"
                  dense
                  grow
                  class="d-flex justify-start tabs-stream">
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mx-1"
                    :style="index === 0 || index === 3 ? 'min-width: 55px; max-width: 55px;' : 
                      index === 1 || index === 2 ? 'min-width: 100px; max-width: 100px;' : 'min-width: 75px; max-width: 75px;'">
                    <span class="body-2 text-capitalize mb-2" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                      {{ item.label }}
                    </span>
                  </v-tab>
                </v-tabs>
              </v-col>

              <v-col cols="12" md="5" class="d-flex align-center justify-end" :class="rps !== 'xs' ? 'py-0' : ''">
                <v-text-field
                  filled
                  rounded
                  dense 
                  single-line
                  hide-details
                  color="#7a7a7a"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari jadwal zoom class . . ."
                  clearable
                  v-model="filter.title"
                  @click:clear="filter.title = ''; fetch();"
                  v-on:keyup.enter="fetch()">
                </v-text-field>

                <div class="body-2 text-capitalize text-third cursor-pointer ml-3"
                  @click="filter.dialog = true; fetchMentor();">
                  <v-icon size="20" color="#909090">mdi-filter</v-icon>
                  Filter
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-container>
            <v-row 
              justify="center"
              class="pt-6 pb-2">
              <v-col cols="12" md="3" sm="3" :class="rps === 'xs' ? '' : 'pr-0'">
                <v-menu
                  rounded
                  offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="rps === 'xs' ? 'b_a br_a' : 'b_l b_t b_b br_l'"
                      elevation="0"
                      color="#f0f0f0"
                      block
                      height="40"
                      class="body-2 text-capitalize pr-2 d-flex justify-start"
                      v-bind="attrs"
                      v-on="on">
                      {{ filter.label_type }}
                      <v-icon color="grey darken-1" style="position: absolute; right: 0px;">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list flat dense>
                    <v-list-item-group
                      color="#FF3177">
                      <v-list-item class="hover-list"
                        v-for="(item, index) in type"
                        :key="index"
                        @click="
                          filter.type = item.value; 
                          filter.label_type = item.label;
                          filter.label_category = 'Semua Kategori';
                          filter.category = '';
                          filter.category_sub = '';">
                        <v-list-item-title class="body-2">
                          {{ item.label }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col cols="12" md="3" sm="3" :class="rps === 'xs' ? '' : 'px-0'">
                <v-menu
                  rounded
                  offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="rps === 'xs' ? 'b_a br_a' : 'b_r b_l b_t b_b br_r'"
                      elevation="0"
                      color="#f0f0f0"
                      block
                      height="40"
                      class="body-2 text-capitalize pr-2 d-flex justify-start"
                      v-bind="attrs"
                      v-on="on">
                      <span style="max-width: 200px;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;">
                        {{ filter.label_category }}
                      </span>
                      <v-icon color="grey darken-1" style="position: absolute; right: 0px;">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list flat dense max-height="300">
                    <v-list-item-group
                      style="background: white;"
                      color="#FF3177">
                      <div 
                        v-for="(item, index) in category"
                        :key="index">
                        <v-list-item class="hover-list" v-if="item.sub.length < 1"
                          @click="
                            filter.category = item.value; 
                            filter.label_category = item.label; 
                            filter.category_sub = '';
                            fetch();">
                          <v-list-item-title class="body-2">
                            {{ item.label }}
                          </v-list-item-title>
                        </v-list-item>

                        <div v-if="item.sub.length > 0">
                          <v-list-group no-action color="#FF3177" @click.stop>
                            <template v-slot:activator>
                              <v-list-item class="px-0">
                                <v-list-item-title>
                                  {{ item.label }}
                                </v-list-item-title>
                              </v-list-item>
                            </template>

                            <v-list-item
                              class="px-0 hover-list"
                              v-for="(sub, i) in item.sub"
                              :key="i"
                              @click="
                                filter.category_sub = sub.kelas_kategori_id; 
                                filter.label_category = sub.kelas_kategori_title; 
                                filter.category = '';
                                fetch();">
                              <v-list-item-title class="pl-8">
                                {{ sub.kelas_kategori_title }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list-group>
                        </div>
                      </div>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

            <v-row
              class="py-6">
              <v-col 
                cols="12" 
                md="4"
                class="pa-1"
                v-for="(item, index) in process.run ? 15 : list"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.run"
                  type="image, article">
                  <div>
                    <v-hover
                      v-slot="{ hover }"
                      open-delay="200">
                      <div>
                        <v-card v-if="!process.run && list.length > 0"
                          class="box-shadow"
                          width="100%"
                          height="250"
                          style="border-radius: 0px;"
                          :style="item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime ? 'opacity: 50%;' : ''"
                          :to="rps !== 'xs' ? '' : item.schedule_is_purchased === '0' ? 
                              `/streaming/${item.kelas_slug}?schedule_id=${item.schedule_id}` : 
                              `/collection/streaming/${item.kelas_id}?schedule_id=${item.schedule_id}`">
                          <v-img
                            width="100%"
                            height="100%"
                            gradient="to left, rgba(0,0,0,0.25), rgba(0,0,0,0.75)"
                            :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <v-container
                              class="py-0">
                              <v-row
                                align="start"
                                style="height: 190px;">
                                <v-col
                                  cols="4"
                                  class="pr-0">
                                  <div
                                    class="text-center white--text my-4">
                                    <div 
                                      class="font-weight-bold mb-2"
                                      :class="rps !== 'xs' ? 'text-h3' : 'title'">
                                      {{ item.schedule_start_datetime | day }}
                                    </div>

                                    <div 
                                      class="text-uppercase my-1"
                                      :class="rps !== 'xs' ? 'title' : 'body-2'">
                                      {{ item.schedule_start_datetime | month }} {{ item.schedule_start_datetime | year }}
                                    </div>

                                    <div 
                                      class="font-weight-bold"
                                      :class="rps !== 'xs' ? 'text-h4' : 'body-1'">
                                      {{ item.schedule_start_datetime | time }}
                                    </div>
                                  </div>
                                </v-col>

                                <v-col
                                  cols="1"
                                  class="text-center">
                                  <v-divider
                                    vertical
                                    style="border: 1px solid #FFFFFF; background: #FFFFFF; border-radius: 50px; height: 165px;" />
                                </v-col>

                                <v-col
                                  cols="7"
                                  class="pl-0">
                                  <div class="mb-2">
                                    <div v-if="dateNow <= item.schedule_end_datetime">
                                      <div v-if="item.schedule_status !== 'Selesai'">
                                        <v-chip v-if="dateNow > item.schedule_early_price_end_datetime"
                                          style="border: 1px solid #ffffffb3 !important;"
                                          color="#616161"
                                          label
                                          :small="rps !== 'xs'"
                                          :x-small="rps === 'xs'"
                                          text-color="white"
                                          class="font-weight-bold text-uppercase">
                                          NORMAL PRICE
                                        </v-chip>

                                        <v-chip v-else
                                          style="border: 1px solid #ffffffb3 !important;"
                                          color="green"
                                          label
                                          :small="rps !== 'xs'"
                                          :x-small="rps === 'xs'"
                                          text-color="white"
                                          class="font-weight-bold text-uppercase">
                                          EARLY PRICE
                                        </v-chip>

                                        <div 
                                          class="text-second caption white--text mt-2">
                                          s.d. 
                                          <span v-if="dateNow >= item.schedule_early_price_end_datetime">
                                            {{ item.schedule_normal_price_end_datetime | date }}
                                          </span>

                                          <span v-else>
                                            {{ item.schedule_early_price_end_datetime | date }}
                                          </span>
                                        </div>
                                      </div>

                                      <div v-else>
                                        <v-chip
                                          style="border: 1px solid #ffffffb3 !important;"
                                          color="red"
                                          label
                                          :small="rps !== 'xs'"
                                          :x-small="rps === 'xs'"
                                          text-color="white"
                                          class="font-weight-bold text-uppercase">
                                          {{ item.schedule_status }}
                                        </v-chip>

                                        <div 
                                          class="text-second caption white--text mt-2">
                                          pada {{ item.schedule_end_datetime | date }} Pukul {{ item.schedule_end_datetime | time }}
                                        </div>
                                      </div>
                                    </div>

                                    <div v-else>
                                      <v-chip
                                        style="border: 1px solid #ffffffb3 !important;"
                                        color="red"
                                        label
                                        :small="rps !== 'xs'"
                                        :x-small="rps === 'xs'"
                                        text-color="white"
                                        class="font-weight-bold text-uppercase">
                                        {{ item.schedule_status !== 'Selesai' ? 'Pendaftaran Berakhir' : item.schedule_status }}
                                      </v-chip>

                                      <div 
                                        class="text-second caption white--text mt-2">
                                        pada {{ item.schedule_end_datetime | date }} Pukul {{ item.schedule_end_datetime | time }}
                                      </div>
                                    </div>
                                  </div>
                                  
                                  <div
                                    class="text-h5 white--text font-weight-bold two-line mb-2">
                                    {{ item.kelas_nama }}
                                  </div>

                                  <div class="d-flex align-center">
                                    <v-rating
                                      :value="parseFloat(item.kelas_total_rating)"
                                      background-color="yellow darken-2"
                                      color="yellow darken-2"
                                      dense
                                      readonly
                                      size="18">
                                    </v-rating>
                                    <span class="white--text caption font-weight-bold ml-1">
                                      ({{ parseFloat(item.kelas_total_rating).toFixed(1) }})
                                    </span>
                                  </div>
                                </v-col>
                              </v-row>

                              <v-row
                                align="center">
                                <v-col
                                  cols="8">
                                  <v-chip
                                    color="#ffffffb3"
                                    style="min-width: 100%;">
                                    <!-- <span
                                      class="mr-1">
                                      <v-icon size="20">mdi-currency-usd</v-icon>
                                    </span> -->

                                    <span v-if="!item.kelas_is_discount"
                                      class="font-weight-bold">
                                      <span class="green--text" v-if="parseInt(item.schedule_early_price) < 1 && parseInt(item.schedule_normal_price) < 1">
                                        Gratis
                                      </span>

                                      <span class="color-first" v-else>
                                        <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                          {{ parseInt(item.schedule_early_price) | price }}
                                        </span>

                                        <span v-else>
                                          {{ parseInt(item.schedule_normal_price) | price }}
                                        </span>
                                      </span>
                                    </span>

                                    <span class="orange--text" v-if="item.kelas_is_discount">
                                      <span v-if="dateNow < item.schedule_early_price_end_datetime">
                                        {{ parseInt(item.schedule_early_price) - ((parseInt(item.schedule_early_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                      </span>

                                      <span v-else>
                                        {{ parseInt(item.schedule_normal_price) - ((parseInt(item.schedule_normal_price) * item.kelas_diskon_jsonobject.percent) / 100) | price }}
                                      </span>
                                    </span>
                                  </v-chip>
                                </v-col>

                                <v-col
                                  cols="4">
                                  <v-chip
                                    color="#ffffffb3"
                                    style="min-width: 100%;">
                                    <span v-if="item.schedule_is_purchased === '0'"
                                      class="body-2">
                                      <span class="font-weight-bold blue--text">{{ item.schedule_quota_left }}</span> dari {{ item.schedule_quota }}
                                    </span>

                                    <span v-if="item.schedule_is_purchased === '1'"
                                      class="body-2 font-weight-bold">
                                      <span
                                        :class="item.schedule_status === 'Belum Mulai' ? 'blue--text' : 
                                                item.schedule_status === 'Sedang Berlangsung' ? 'green--text' : 'red--text'">
                                        {{ item.schedule_status }}
                                      </span>
                                    </span>
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-img>
                        </v-card>

                        <v-expand-transition>
                          <div v-if="hover && rps !== 'xs'">
                            <v-card
                              width="100%"
                              height="510"
                              outlined
                              style="border-radius: 0px !important;"
                              :class="index === 2 || index === 5 || index === 8 || index === 11 ? 'on-hover-right arrow-right' : 'on-hover-left arrow-left'">
                              <v-card-text style="width: auto;">
                                <p class="body-1 font-weight-bold line-text-first text-first text-capitalize">
                                  <span class="two-line">
                                    {{ item.kelas_nama }}
                                  </span>
                                </p>

                                <p class="body-2 text-second mb-0">
                                  <span class="one-line">
                                    {{ item.kelas_kategori_title }}
                                  </span>
                                </p>

                                <v-list flat dense>
                                  <v-list-item class="px-0">
                                    <v-list-item-avatar size="30" class="mr-2">
                                      <v-img :src="item.kelas_author.kelas_author_photo">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              size="25"
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ item.kelas_author.kelas_author_nama }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>

                                <div style="height: 265px;">
                                  <p class="body-2 font-weight-light text-first line-text-first">
                                    <span class="nine-line" v-html="item.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                  </p>

                                  <p class="body-2 font-weight-light text-first line-text-first mb-0">
                                    <v-icon small class="mr-1">mdi-calendar-check</v-icon>
                                    Terakhir update <b>{{ item.kelas_update_datetime | date }}</b>
                                  </p>
                                </div>
                              </v-card-text>

                              <v-card-text class="d-flex align-center justify-center"
                                style="position: absolute; bottom: 10px; width: auto;">
                                <v-btn
                                  rounded
                                  width="160"
                                  elevation="0"
                                  color="#FF3177"
                                  class="text-capitalize white--text mr-2"
                                  :disabled="item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime"
                                  :to="item.schedule_is_purchased === '0' ? 
                                      `/streaming/${item.kelas_slug}?schedule_id=${item.schedule_id}` : 
                                      `/collection/streaming/${item.kelas_id}?schedule_id=${item.schedule_id}`">
                                  {{  item.schedule_is_purchased === '0' ? 'Lihat Jadwal' : 'Masuk Kelas' }}
                                </v-btn>

                                <v-btn
                                  fab
                                  width="40"
                                  height="40"
                                  elevation="0"
                                  :disabled="process.wishlist || item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime"
                                  :loading="process.wishlist"
                                  @click="item.kelas_is_wishlist = item.kelas_is_wishlist === '0' ? '1' : '0'; 
                                    addWishlist(item.kelas_id, item.kelas_is_wishlist)">
                                  <v-icon color="#FF3177">
                                    {{ !token ? 'mdi-heart-outline' : item.kelas_is_wishlist === '0' ? 'mdi-heart-outline' : 'mdi-heart' }}
                                  </v-icon>
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-expand-transition>
                      </div>
                    </v-hover>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row justify="center" align="center" v-if="!process.run && pagination.total_page < 1">
              <v-col cols="12" class="mt-16">
                <v-img 
                  class="mx-auto mt-16"
                  contain
                  width="175"
                  height="175"
                  :src="require('@/assets/image/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <p class="text-h5 text-second font-weight-bold text-center">
                  Kelas yang kamu cari tidak ada!
                </p>
                <p class="body-1 font-weight-light mb-6 text-center">
                  Yuk cari kelas rekaman lain di E-Learning Bidan Kita.
                </p>

                <div class="text-center">
                  <v-btn
                    rounded
                    elevation="0"
                    color="#FF3177"
                    class="text-capitalize white--text"
                    to="/streaming">
                    <v-icon class="mr-1">mdi-magnify</v-icon>
                    Cari Kelas Belajar
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <Pagination v-if="pagination.total_page > 1"
              @changePage="fetch($event)" 
              @reloadPage="fetch($event)"
              :model="pagination" />
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from 'moment'
  export default {
    middleware: false,
    data () {
      return {
        dateNow: moment().format('YYYY-MM-DD HH:mm:ss'),
        dialog: {
          auth: '',
          message: ''
        },
        menu: [
          {
            label: 'Semua',
            value: ''
          },
          {
            label: 'Belum Mulai',
            value: 'Belum Mulai'
          },
          {
            label: 'Berlangsung',
            value: 'Sedang Berlangsung'
          },
          {
            label: 'Selesai',
            value: 'Selesai'
          }
        ],
        menu_week: ['Hari Ini', 'Minggu Ini'],
        picker: false,
        filter: {
          dialog: false,
          label_type: 'Semua Tipe',
          type: '',
          label_category: 'Semua Kategori',
          category: '',
          category_sub: '',
          title: '',
          mentor: '',
          sort: 'Terbaru',
          // month: moment().format('YYYY-MM')
          month: ''
        },
        type: [
          {
            label: 'Semua Tipe',
            value: ''
          }
        ],
        category: [
          {
            label: 'Semua Kategori',
            value: '',
            sub: []
          }
        ],
        selected: {
          menu: 0,
          panel: [1, 2],
          index: '',
          menu_week: 0,
          slide_week: 0
        },
        mentor: [],
        list: [],
        week: {
          today: [],
          week: []
        },
        pagination: {},
        process: {
          run: false,
          wishlist: false,
          type: false,
          category: false,
          week: false
        },
        message: '',
        // SEO
        content: {
          url: 'streaming',
          title: 'Kelas',
          description: 'Kelas Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth'),
      DialogMessage: () => import('@/components/dialog/message'),
      Pagination: () => import('@/components/Pagination')
    },
    watch: {
      'filter.type': function(val) {
        this.fetch()
        // this.fetchCategory()
      },
      'selected.menu': function(val) {
        this.fetch()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      }
    },
    mounted () {
      this.fetchType()
      this.fetchCategory()
      this.fetchWeek()
      this.fetch()
    },
    methods: {
      async fetchType() {
        this.process.type = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/type`)
        .then((response) => {
          if (response.status === 200) {
            this.process.type = false

            let data = response.data.results
            for (let i in data) {
              this.type.push({
                label: data[i].kelas_type_title,
                value: data[i].kelas_type_id
              })
            }
          }
        })
      },
      async fetchCategory() {
        this.process.category = true

        let params = {
          type_id: this.filter.type,
          sort: 'kelas_kategori_title',
          dir: 'ASC'
        }
        
        this.category = [{
          label: 'Semua Kategori',
          value: '',
          sub: []
        }]

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/category_nested`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.category = false

            let data = response.data.results
            for (let i in data) {
              this.category.push({
                label: data[i].kelas_kategori_title,
                value: data[i].kelas_kategori_id,
                sub: data[i].kelas_kategori_child
              })
            }
          }
        })
      },
      async fetchMentor() {
        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/mentor`)
        .then((response) => {
          if (response.status === 200) {
            this.mentor = response.data.results
          }
        })
      },
      reset() {
        this.filter = {
          dialog: false,
          label_type: 'Semua Tipe',
          type: '',
          label_category: 'Semua Kategori',
          category: '',
          category_sub: '',
          title: '',
          mentor: '',
          sort: 'Terbaru',
          month: moment().format('YYYY-MM')
        }

        this.fetch()
      },
      async fetch(pagination = { row: 15, page: 1 }) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        
        this.process.run = true

        let sort = this.filter.sort === 'Terbaru' ? 'schedule_start_datetime' : this.filter.sort === 'Review Terbanyak' ? 'kelas_total_rating' : 'schedule_normal_price',
            dir = this.filter.sort === 'Terbaru' || this.filter.sort === 'Termahal' || this.filter.sort === 'Review Terbanyak' ? 'DESC' : 'ASC'
        let params = {
          limit: pagination.row,
          page: pagination.page,
          sort: this.filter.sort === '' ? '' : sort,
          dir: this.filter.sort === '' ? '' : dir,
          id_mentor: this.filter.mentor,
          filter: [
            {
              type: 'string',
              field: 'kelas_type_id',
              value: this.filter.type
            },
            {
              type: 'string',
              field: 'kelas_kategori_id',
              value: this.filter.category
            },
            {
              type: 'string',
              field: 'kelas_kategori_child_id',
              value: this.filter.category_sub
            },
            {
              type: 'string',
              field: 'kelas_nama',
              value: this.filter.title,
              comparison: '<>'
            },
            {
              type: 'string',
              field: 'schedule_status',
              value: this.menu[this.selected.menu].value
            },
            {
              type: 'string',
              field: 'schedule_start_datetime',
              value: this.filter.month,
              comparison: '<>'
            }
          ]
        }

        this.list = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/live`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            response.data.results.filter(v => {
              v.kelas_deskripsi = v.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')
              this.list.push(v)
            })
            this.pagination = response.data.pagination

            this.selected.panel = this.$vuetify.breakpoint.name !== 'xs' ? [1, 2] : []
          }
        })
      },
      async fetchWeek() {
        this.process.week = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/public/class/live/this_week`)
        .then((response) => {
          if (response.status === 200) {
            this.process.week = false
            
            this.week = response.data
          }
        })
      },
      async addWishlist(kelas_id, kelas_is_wishlist) {
        if (!this.token) {
          this.dialog.auth = 'login'
        } else {
          this.saveWishlist(kelas_id, kelas_is_wishlist)
        }
      },
      async saveWishlist(kelas_id, kelas_is_wishlist) {
        this.process.wishlist = true

        this.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/wishlist/add_remove`, {
          kelas_id: kelas_id
        })
        .then((response) => {
          if (response.status === 200) {
            this.process.wishlist = false

            this.selected.index = ''
            
            this.dialog.message = 'wishlist'
            this.message = kelas_is_wishlist === '1' ? 'Berhasil ditambahkan ke Wishlist.' : 'Berhasil dihapus dari Wishlist.'
          }
        })
      }
    }
  }
</script>

<style scoped>
  .search-style.v-text-field--outlined >>> fieldset {
    border-color: #dadada;
    border-left: 0px;
  }
  .on-hover-left {
    position: absolute;
    top: 0px;
    right: -395px;
    z-index: 9;
    border-radius: 5px !important;
  }
  .on-hover-left.arrow-left:after {
    content: "";
    position: absolute;
    left: -10px;
    top: 126px;
    border-top: 10px solid transparent;
    border-right: 10px solid #ffffff;
    border-left: none;
    border-bottom: 10px solid transparent;
  }
  .on-hover-left.arrow-left:before {
    content: "";
    position: absolute;
    left: -11px;
    top: 125px;
    border-top: 11px solid transparent;
    border-right: 11px solid #dadada;
    border-left: none;
    border-bottom: 11px solid transparent;
  }


  .on-hover-right {
    position: absolute;
    top: 0px;
    left: -395px;
    z-index: 9;
    border-radius: 5px !important;
  }
  .on-hover-right.arrow-right:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 126px;
    border-top: 10px solid transparent;
    border-right: none;
    border-left: 10px solid #ffffff;
    border-bottom: 10px solid transparent;
  }
  .on-hover-right.arrow-right:before {
    content: "";
    position: absolute;
    right: -11px;
    top: 125px;
    border-top: 11px solid transparent;
    border-right: none;
    border-left: 11px solid #dadada;
    border-bottom: 11px solid transparent;
  }
</style>

<style>
  .tabs-stream .v-tabs-slider {
    background-color: currentColor;
    height: 100%;
    width: 100%;
    border-radius: 20px 20px 0px 0px !important;
  }
  .expand-stream .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 40px;
  }
  .list-group-stream .v-list-group__header {
    padding: 0px;
  }
</style>