<template>
  <div>
    <DialogAuth :dialogauth="dialog" :email="form.email" @close="dialog = ''" />

    <v-divider style="border-color: #f9f9f9;"/>

    <v-container>
      <v-row justify="center" class="pt-4">
        <v-col cols="12" sm="2">
          <nuxt-link to="/home">
            <v-img 
              class="mx-auto"
              contain 
              height="100"
              width="100"
              :src="require('@/assets/logo/logo_bidankita_color.svg')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#FF3177">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </nuxt-link>
        </v-col>
        <v-col cols="12" sm="2">
          <p class="body-2 text-second font-weight-bold mb-5">
            Bidan Kita
          </p>
          <div v-for="(item, index) in site" :key="index">
            <nuxt-link :to="`${item.link}`" class="link">
              <p class="body-2 text-second mb-3 hover-link">
                {{ item.label }}
              </p>
            </nuxt-link>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div>
            <p class="body-2 text-second font-weight-bold mb-5">
              Alamat
            </p>
            <p class="body-2 text-second line-text-first mb-6">
              Jl. Piere Tendean No. 20 RT | RW VII Sikenong, Kel. Sidowayah, Kec. Klaten Tengah, Kabupaten. Klaten, Jawa Tengah 57413
            </p>
            <p class="body-2 text-second font-weight-bold">
              Hotline (24 Jam) +62 813-4623-3500
              Admin +62 851-0011-1884
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="1" class="pa-0"></v-col>
        <v-col cols="12" sm="4">
          <p class="body-2 text-second font-weight-bold mb-5">
            Berlangganan
          </p>
          <v-row>
            <v-col cols="9" class="pt-0 pb-0 pr-0">
              <v-text-field
                v-model="form.email"
                dense
                rounded
                prepend-inner-icon="mdi-email-outline"
                placeholder="Alamat Email Anda"
                color="#333333"
                single-line 
                hide-details="auto"
                filled>
              </v-text-field>
            </v-col>
            <v-col cols="1" class="pt-0 pb-0 pl-0">
              <v-btn
                style="position: relative; right: 30px; height: 40px;"
                rounded
                elevation="0"
                color="#333333"
                @click="dialog = 'register'">
                <v-icon color="white">mdi-email-fast-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-avatar 
            v-for="(item, index) in sosmed"
            :key="index"
            tile
            class="mt-6"
            width="40"
            height="40">
            <a :href="item.link" target="_blank">
              <v-img
                width="35"
                height="35"
                contain
                :src="item.icon">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#FF3177">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </a>
          </v-avatar>
        </v-col>
      </v-row>
    </v-container>

    <div class="body-2 white--text background-first">
      <v-container>

      <v-row justify="center">
        <v-col cols="6" class="py-0 px-5">
          <div class="text-left">
            Copyright@{{ new Date().getFullYear() }} <strong>Bidan Kita</strong>
          </div>
        </v-col>
        <v-col cols="6" class="py-0 px-5">
          <div class="text-right">
            Powered by Kode Kreativ
          </div>
        </v-col>
      </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: '',
        site: [
          {
            label: 'Tentang Kami',
            link: '/site/aboutus'
          },
          {
            label: 'Kontak Kami',
            link: '/site/contactus'
          },
          {
            label: 'Kebijakan Privasi',
            link: '/site/privacy'
          },
          // {
          //   label: 'FAQ',
          //   link: '/site/faq'
          // }
        ],
        sosmed: [
          {
            icon: require("@/assets/sosmed/ic_share_fb.svg"),
            link: 'https://www.facebook.com/klinikbidankita'
          },
          {
            icon: require("@/assets/sosmed/ic_share_ig.svg"),
            link: 'https://www.instagram.com/bidankita/?hl=id'
          },
          {
            icon: require("@/assets/sosmed/ic_share_tw.svg"),
            link: 'https://twitter.com/bidankita'
          },
          {
            icon: require("@/assets/sosmed/ic_share_tel.svg"),
            link: 'https://t.me/fabdulrafi'
          },
          {
            icon: require("@/assets/sosmed/ic_share_wa.svg"),
            link: 'https://wa.me/6285100111884'
          }
        ],
        form: {
          email: ''
        }
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth')
    },
    computed: {
      token() {
        return this.$store.state.member.auth.token
      },
      base() {
        return process.env.HOST_NAME_PORTAL_PROD
      }
    }
  }
</script>
