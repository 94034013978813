<template>
  <div class="fill-height py-12">
    <v-dialog v-model="dialog"
      width="450"
      persistent>
      <v-card 
        color="#f3f3f3"
        class="border-radius pa-2">
        <v-card-text class="pa-0 text-right">
          <v-btn
            fab
            x-small
            elevation="0"
            class="background-first white--text"
            @click="dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text>
          <v-row justify="center" align="center">
            <v-col cols="12" class="text-center my-12">
              <v-img 
                class="mx-auto"
                contain
                width="150"
                height="150"
                :src="require('@/assets/image/empty.png')">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#FF3177">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <p class="text-h5 mt-6">
                Coming Soon!
              </p>
              <p class="body-1 font-weight-light mb-6">
                Akan segera hadir voucher menarik untuk mu.
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-layout wrap>
      <v-flex>
        <section>
          <v-container class="pb-0">
            <v-row>
              <v-col class="pb-0">
                <v-breadcrumbs :items="items" class="pb-0">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-container class="px-8">
            <v-row
              justify="center">
              <v-col cols="12" md="8" sm="8">
                <p class="title mb-2">
                  Keranjang
                </p>
                <p class="body-1 font-weight-light">
                  Silahkan selesaikan transaksi untuk menikmati materi-materi menarik.
                </p>

                <v-list flat dense class="py-0" v-if="list.cart.length > 0">
                  <v-list-item class="px-0"
                    @click="checkAll">
                    <v-list-item-content>
                      <v-list-item-title class="body-1 font-weight-bold">
                        Pilih Semua
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon color="grey" v-if="selected.cart.length < 1 || selected.cart.length !== list.cart.length">
                        mdi-checkbox-blank-outline
                      </v-icon>
                      <v-icon color="green" v-if="selected.cart.length > 0 && selected.cart.length === list.cart.length">
                        mdi-checkbox-marked
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>

                <v-divider/>

                <v-card flat
                  v-for="(item, index) in process.cart ? 8 : list.cart"
                  :key="index">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.cart"
                    type="list-item-avatar-three-line">
                    <div>
                      <v-list flat three-line class="py-0" v-if="!process.cart && list.cart.length > 0">
                        <v-list-item-group v-model="selected.cart" multiple>
                          <v-list-item class="px-0" 
                            :value="item.sys_kelas_cart_id"
                            @click="item.sys_kelas_cart_is_checked = item.sys_kelas_cart_is_checked ? false : true; updates('one');">
                            <template v-slot:default="{ active }">
                              <v-list-item-avatar tile size="75">
                                <v-img 
                                  style="border-radius: 10px;"
                                  :src="item.sys_cart_kelas_image_jsonobejct.kelas_image_cover_url">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        color="#FF3177">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>

                                  <div class="badge-overlay-premium" v-if="item.sys_kelas_cart_kelas_is_discount">
                                    <span class="top-left-premium badge-premium premium font-weight-bold">
                                      {{ parseInt(item.sys_kelas_cart_kelas_diskon_jsonobject.percent) }}%
                                    </span>
                                  </div>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-chip
                                    x-small
                                    class="white--text"
                                    :color="item.sys_kelas_cart_schedule_id === '0' ? '#FF3177' : 'primary'">
                                    <b>{{ item.sys_kelas_cart_schedule_id === '0' ? 'E-Learning' : 'Streaming' }}</b>
                                    <v-icon size="10" color="white" class="mx-1">mdi-chevron-double-right</v-icon>
                                    {{ item.sys_kelas_cart_kelas_kategori_title }}
                                  </v-chip>

                                  <p class="body-1 font-weight-bold text-capitalize mt-3"
                                    :class="item.sys_kelas_cart_schedule_id === '0' ? 'mb-0' : 'mb-2'">
                                    {{ item.sys_kelas_cart_kelas_nama }}
                                  </p>

                                  <p class="body-2 font-weight-light text-second mb-2" v-if="item.sys_kelas_cart_schedule_id !== '0'">
                                    Jadwal 
                                    <span class="font-weight-bold">
                                      {{ item.sys_kelas_cart_schedule_start_datetime | date }}
                                    </span>
                                    Pukul
                                    <span class="font-weight-bold">
                                      {{ item.sys_kelas_cart_schedule_start_datetime | time }}
                                    </span>
                                    s/d
                                    <span class="font-weight-bold">
                                      {{ item.sys_kelas_cart_schedule_end_datetime | date }}
                                    </span>
                                    Pukul
                                    <span class="font-weight-bold">
                                      {{ item.sys_kelas_cart_schedule_end_datetime | time }}
                                    </span>
                                  </p>
                                </v-list-item-title>
                                
                                <v-list-item-subtitle class="body-2">
                                  <span class="text-decoration-line-through text-second mr-2" v-if="item.sys_kelas_cart_kelas_is_discount">
                                    {{ parseInt(item.sys_kelas_cart_kelas_harga_jsonobject.value) | price }}
                                  </span>
                                  <span class="font-weight-bold">
                                    <span class="color-first" v-if="parseInt(item.sys_kelas_cart_kelas_total_harga) > 0">
                                      {{ parseInt(item.sys_kelas_cart_kelas_total_harga) | price }}
                                    </span>

                                    <span class="green--text" v-else>
                                      Gratis
                                    </span>
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              
                              <v-list-item-icon>
                                <v-icon color="green" v-if="active">mdi-checkbox-marked</v-icon>
                                <v-icon color="grey" v-else>mdi-checkbox-blank-outline</v-icon>
                              </v-list-item-icon>
                            </template>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>

                      <div class="d-flex align-center mb-4">
                        <a 
                          :href="item.sys_kelas_cart_schedule_id === '0' ? 
                            `https://elearning.bidankita.com/elearning/${item.sys_kelas_cart_kelas_slug}` :
                            `https://kelas.bidankita.com/streaming/${item.sys_kelas_cart_kelas_slug}`" 
                          target="_blank"
                          class="caption d-flex align-center primary--text">
                          <v-icon small class="mr-1" color="primary">mdi-google-classroom</v-icon>
                          Lihat Kelas
                        </a>

                        <v-spacer/>

                        <div class="caption d-flex align-center red--text cursor-pointer"
                          v-if="selected.delete !== item.sys_kelas_cart_id"
                          @click="selected.delete = item.sys_kelas_cart_id; deletes();">
                          Hapus 
                          <v-icon small class="ml-1" color="red">mdi-trash-can-outline</v-icon>
                        </div>
                        <div class="caption d-flex align-center red--text cursor-pointer"
                          v-if="selected.delete === item.sys_kelas_cart_id">
                          Menghapus... 
                        </div>
                      </div>

                      <v-alert v-if="!process.cart && list.cart.length > 0 && item.sys_kelas_cart_note.length > 0"
                        text
                        dense
                        color="info"
                        icon="mdi-information-outline"
                        border="left"
                        class="body-2">
                        {{ item.sys_kelas_cart_note }}
                      </v-alert>
                    </div>

                    <v-divider v-if="index < list.cart.length - 1"/>
                  </v-skeleton-loader>
                </v-card>

                <!-- KETIKA KOSONG -->
                <div class="mt-6 mb-12" v-if="!process.cart && list.cart.length < 1">
                  <v-img 
                    class="mx-auto"
                    contain
                    width="175"
                    height="175"
                    :src="require('@/assets/image/empty.png')">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#FF3177">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <p class="text-h5 text-second font-weight-bold text-center">
                    Keranjang mu masih kosong!
                  </p>
                  <p class="body-1 font-weight-light mb-6 text-center">
                    Yuk lihat kelas belajar yang menarik.
                  </p>

                  <div class="text-center">
                    <v-btn
                      rounded
                      elevation="0"
                      color="#FF3177"
                      class="text-capitalize white--text"
                      href="https://elearning.bidankita.com"
                      target="_blank">
                      <v-icon class="mr-1">mdi-magnify</v-icon>
                      Cari Kelas Belajar
                    </v-btn>
                  </div>
                </div>

                <v-divider v-if="pagination.cart.total_page > 1"/>

                <!-- PAGING -->
                <v-row v-if="pagination.cart.total_page > 1">
                  <v-col cols="6">
                    <v-btn
                      color="#FF3177"
                      elevation="0"
                      text
                      min-width="36"
                      class="text-capitalize px-0"
                      :disabled="pagination.cart.current < 2"
                      @click="selected.pagination = selected.pagination-1">
                      <v-icon class="mr-1">mdi-arrow-left</v-icon>
                      Prev
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      color="#FF3177"
                      elevation="0"
                      text
                      min-width="36"
                      class="text-capitalize px-0"
                      :disabled="pagination.cart.current >= pagination.cart.total_page"
                      @click="selected.pagination = selected.pagination+1">
                      Next
                      <v-icon class="ml-1">mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <!-- KELAS LAINNYA  -->
                <div class="mt-6 mb-12" v-if="!process.cart && list.cart.length < 1">
                  <p class="body-1 font-weight-bold text-first">
                    Kelas Belajar
                    <span class="color-first">
                      Untuk Anda
                    </span>
                  </p>

                  <v-row :class="process.classes ? 'mt-12' : ''">
                    <v-col cols="12" md="4" sm="4"
                      v-for="(item, index) in process.classes ? 8 : list.classes"
                      :key="index">
                      <v-skeleton-loader
                        class="ma-auto"
                        :loading="process.classes"
                        transition="scale-transition"
                        :tile="false"
                        type="card">
                        <div>
                          <div v-if="!process.classes && list.classes.length > 0">
                            <v-card
                              class="border-radius box-shadow pa-2"
                              :href="`https://elearning.bidankita.com/elearning/${item.kelas_slug}`"
                              target="_blank">
                              <v-img
                                width="100%"
                                height="200"
                                class="border-radius"
                                :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#FF3177">
                                    </v-progress-circular>
                                  </v-row>
                                </template>

                                <v-chip v-if="item.kelas_is_special_price"
                                  small
                                  class="font-weight-bold white--text px-2 ma-2"
                                  color="primary"
                                  style="position: absolute; top: 0; right: 0px;">
                                  Special Price
                                </v-chip>
                              </v-img>

                              <div class="burst ma-1" v-if="item.kelas_is_discount">
                                <span>
                                  OFF {{ item.kelas_diskon_jsonobject.percent }}%
                                </span>
                              </div>
                              
                              <div class="burst_free" v-if="!item.kelas_berbayar">
                                <span>
                                  FREE
                                </span>
                              </div>

                              <div class="text-center" style="height: 60px;">
                                <p class="body-2 line-text-first text-capitalize font-weight-bold text-first justify-center pa-3 mb-0">
                                  <span class="two-line">
                                    {{ item.kelas_nama }}
                                  </span>
                                </p>
                              </div>
                            </v-card>
                          </div>
                        </div>
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>

                  <v-row class="mt-6" v-if="!process.classes && pagination.classes.total_page > 1">
                    <v-col cols="12" class="text-right">
                      <a
                        href="https://elearning.bidankita.com/elearning"
                        target="_blank"
                        class="color-first">
                        <span>
                          Lihat kelas lainnya
                        </span>
                        <v-icon color="#FF3177">mdi-arrow-right</v-icon>
                      </a>
                    </v-col>
                  </v-row>
                </div>
              </v-col>

              <v-col cols="12" md="4" sm="4">
                <v-card class="box-shadow border-radius pa-6" :flat="!process.cart && list.cart.length < 1" style="position: sticky; top: 90px;">
                  <v-skeleton-loader
                    v-for="index in process.cart ? 3 : 1"
                    :key="index"
                    class="ma-auto"
                    :loading="process.cart"
                    type="article">
                    <div>
                      <v-card-text class="pa-0" v-if="!process.cart && list.cart.length > 0">
                        <p class="body-1 font-weight-bold">
                          Rincian
                        </p>

                        <v-divider class="my-4"/>

                        <div class="d-flex align-center mb-2">
                          <div class="body-1 font-weight-light">
                            Harga
                          </div>
                          <v-spacer/>
                          <div class="body-1 font-weight-light">
                            {{ parseInt(detail.price) | price }}
                          </div>
                        </div>

                        <div class="d-flex align-center mb-2">
                          <div class="body-1 font-weight-light">
                            Diskon
                            <span v-if="detail.is_discount && selected.cart.length < 2">
                              ({{ parseInt(detail.discount.percent) }})%
                            </span>
                          </div>
                          <v-spacer/>
                          <div class="body-1 font-weight-light red--text">
                            -{{ parseInt(detail.discount.value) | price }}
                          </div>
                        </div>

                        <div class="d-flex align-center" v-if="selected.cart.length < 2">
                          <div class="body-1 font-weight-light">
                            Langganan
                          </div>
                          <v-spacer/>
                          <div class="body-1 font-weight-light">
                            <span v-if="!detail.subcribe.is_lifetime">
                              {{ detail.subcribe.value }}
                              {{ detail.subcribe.type === 'day' ? 'Hari' : detail.subcribe.type === 'month' ? 'Bulan' : 'Tahun' }}
                            </span>

                            <span v-else>
                              Lifetime
                            </span>
                          </div>
                        </div>

                        <v-divider class="my-4"/>

                        <p class="body-2 font-weight-bold mb-1">
                          Punya Kode Voucher ?
                        </p>

                        <v-list dense flat>
                          <v-list-item class="px-0" style="border: 1px solid #bdbdbd; border-radius: 5px;"
                            @click="dialog = true">
                            <v-list-item-avatar class="my-0 mr-1">
                              <v-icon color="#a7a7a7">mdi-ticket-percent-outline</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title class="body-1 font-weight-light text-second">
                                Lihat Kode Voucher
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action class="my-0">
                              <v-btn icon>
                                <v-icon color="#a7a7a7">mdi-chevron-right</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>

                        <div class="d-flex align-center mt-3 mb-6">
                          <div class="body-1 font-weight-bold">
                            Total
                          </div>
                          <v-spacer/>
                          <div class="body-1 font-weight-bold">
                            {{ parseInt(detail.total) | price }}
                          </div>
                        </div>

                        <v-alert 
                          type="error" 
                          text
                          dense
                          prominent
                          v-show="error.message.length > 0"
                          v-html="error.message">
                        </v-alert>
                        
                        <v-btn
                          elevation="0"
                          block
                          color="green"
                          class="body-1 font-weight-bold text-capitalize white--text"
                          :disabled="process.checkout || selected.cart.length < 1"
                          :loading="process.checkout"
                          @click="checkout">
                          {{ selected.cart.length === 0 ? 'Pilih Kelas' : parseInt(detail.total) > 0 ? 'Checkout' : 'Ikuti Sekarang' }}
                        </v-btn>
                      </v-card-text>
                    </div>
                  </v-skeleton-loader>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        items: [
          {
            text: 'Beranda',
            disabled: false,
            href: '/home'
          },
          {
            text: 'Keranjang Saya',
            disabled: true,
            href: '#'
          },
        ],
        dialog: false,
        selected: {
          cart: [],
          delete: '',
          pagination: 1
        },
        list: {
          cart: [],
          classes: []
        },
        pagination: {
          cart: {},
          classes: {}
        },
        detail: {
          price: 0,
          is_discount: false,
          discount: {
            percent: 0,
            value: 0
          },
          subcribe: {
            type: 'day',
            value: 0,
            is_lifetime: false
          },
          total: 0
        },
        process: {
          cart: false,
          classes: false,
          checkout: false,
          update: false
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: 'cart',
          title: 'Keranjang',
          description: 'Keranjang E-Learning Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {
      'selected.cart': function(val) {
        let data = this.list.cart.filter(v => val.some(id => id === v.sys_kelas_cart_id))

        this.detail = {
          price: 0,
          is_discount: false,
          discount: {
            percent: 0,
            value: 0
          },
          subcribe: {
            type: 'day',
            value: 0,
            is_lifetime: false
          },
          total: 0
        }

        this.cartDetail(data)
      },
      'selected.pagination': function(val) {
        this.fetch(val)
      }
    },
    computed: {
      headers() {
        return this.$store.state.header
      }
    },
    mounted () {
      this.fetch()
      this.fetchClass()
    },
    methods: {
      async fetch(p) {
        this.process.cart = this.process.update ? false : true

        let params = {
          page: p,
          limit: 8,
          sort: 'sys_kelas_cart_updated_datetime',
          dir: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/member/cart`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.cart = false
            this.list.cart = response.data.results
            this.pagination.cart = response.data.pagination

            for (let i = 0; i < this.list.cart.length; i++) {
              if (this.list.cart[i].sys_kelas_cart_is_checked) {
                this.selected.cart.push(this.list.cart[i].sys_kelas_cart_id)
              }
            }

            this.selected.cart = [...new Set(this.selected.cart)]

            this.process.update = false
          }
        })
      },
      async fetchClass() {
        this.process.classes = true

        let params = {
          limit: 6,
          sort: 'kelas_create_datetime',
          dir: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.classes = false
            this.list.classes = response.data.results
            this.pagination.classes = response.data.pagination
          }
        })
      },
      checkAll() {
        if (this.selected.cart.length < 1) {
          this.checkFor()
        } else {
          if (this.selected.cart.length !== this.list.cart.length) {
            this.checkFor()
          } else {
            this.selected.cart = []
          }
        }

        this.selected.cart = [...new Set(this.selected.cart)]

        this.updates('all')
      },
      checkFor() {
        for (let i = 0; i < this.list.cart.length; i++) {
          this.selected.cart.push(this.list.cart[i].sys_kelas_cart_id)
        }
      },
      cartDetail(data) {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let item = data[i]

            // JIKA SCHEDULE ID TIDAK SAMA DENGAN "0"
            // if (item.sys_kelas_cart_schedule_id !== '0') {
            //   item.sys_kelas_cart_kelas_diskon_jsonobject.value = (parseInt(item.sys_kelas_cart_kelas_harga_jsonobject.value) * parseInt(item.sys_kelas_cart_kelas_diskon_jsonobject.percent)) / 100
            // }

            this.detail = {
              price: this.detail.price += parseInt(item.sys_kelas_cart_kelas_harga_jsonobject.value),
              is_discount: item.sys_kelas_cart_kelas_is_discount,
              discount: {
                percent: this.detail.discount.percent += parseInt(item.sys_kelas_cart_kelas_diskon_jsonobject.percent),
                value: this.detail.discount.value += parseInt(item.sys_kelas_cart_kelas_diskon_jsonobject.value)
              },
              subcribe: {
                type: item.sys_kelas_cart_kelas_harga_jsonobject.subcribe_type,
                value: item.sys_kelas_cart_kelas_harga_jsonobject.subcribe_value,
                is_lifetime: item.sys_kelas_cart_kelas_harga_jsonobject.is_lifetime
              },
              total: this.detail.total += parseInt(item.sys_kelas_cart_kelas_total_harga)
            }
          }
        } else {
          this.detail = {
            price: 0,
            is_discount: false,
            discount: {
              percent: 0,
              value: 0
            },
            subcribe: {
              type: 'day',
              value: 0,
              is_lifetime: false
            },
            total: 0
          }
        }
      },
      async checkout() {
        this.process.checkout = true

        this.error.message = ''

        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/transaction/checkout`, {
          id_kelas: JSON.stringify(this.selected.cart.map(Number))
        })
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('header/setCart', this.headers.cart - this.selected.cart.length)

            this.$router.push(`/dashboard/transaction`)
          } else {
            this.process.checkout = false
            this.error.message = response.message
          }
        })
      },
      beforeUpdate(check) {
        let data = []

        for (let i = 0; i < this.list.cart.length; i++) {
          if (check === 'all') {
            this.list.cart[i].sys_kelas_cart_is_checked = true
          }

          if (check === 'all' && this.selected.cart.length < 1) {
            this.list.cart[i].sys_kelas_cart_is_checked = false
          }

          data.push({
            id: this.list.cart[i].sys_kelas_cart_id,
            value: this.list.cart[i].sys_kelas_cart_is_checked
          })
        }

        return data
      },
      async updates(check) {
        this.process.update = true
        
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/cart/checked`, {
          data: JSON.stringify(this.beforeUpdate(check))
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response)
          }
        })
      },
      async deletes() {
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/member/cart/delete`, {
          cart_id: this.selected.delete
        })
        .then((response) => {
          if (response.status === 200) {
            this.selected.delete = ''
            this.selected.cart = []

            this.$store.commit('header/setCart', this.headers.cart - 1)

            this.fetch()
            this.fetchClass()
          }
        })
      }
    }
  }
</script>