<template>
  <div>
    <v-app-bar 
      app
      fixed
      :flat="$route.name === 'streaming' || $route.name === 'streaming-special'"
      :class="{
        'px-16': rps !== 'xs' && rps !== 'sm',
        'header': $route.name !== 'streaming' && $route.name !== 'streaming-special',
        'header-streaming': $route.name === 'streaming'
      }">
      <v-container v-if="rps !== 'xs' && rps !== 'sm'">
        <v-row justify="center">
          <v-col cols="2" class="d-flex align-center justify-start">
            <nuxt-link to="/home">
              <v-img
                width="175"
                height="auto"
                :src="require('@/assets/logo/logo_bidan_tagline.png')">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#FF3177">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </nuxt-link>
          </v-col>

          <v-col cols="10" class="d-flex align-center justify-end">
            <!-- <v-btn
              v-for="(item, index) in menu.left"
              :key="index"
              rounded
              text
              color="white"
              class="body-2 menu text-capitalize"
              :to="item.link"
              v-show="index < 4">
              <span :class="$route.path === item.link ? 'text-first' : 'text-third'">
                {{ item.label }}
              </span>
            </v-btn>

            <v-btn
              rounded
              text
              color="white"
              class="body-2 menu text-capitalize"
              href="https://keluarga.bidankita.com/article/free"
              target="_blank">
              <span class="text-third">
                Artikel
              </span>
            </v-btn> -->

            <v-btn
              v-for="(item, index) in menu.left"
              :key="index"
              v-show="index < 5"
              rounded
              text
              color="white"
              class="body-2 menu text-capitalize"
              :to="!item.blank ? item.link : ''"
              :href="item.blank ? item.link : ''"
              :target="item.blank ? '_blank' : ''">
              <span :class="$route.path === item.link ? 'text-first' : 'text-third'">
                {{ item.label }}
              </span>
            </v-btn>

            <v-menu
              open-on-hover
              top
              transition="slide-y-transition"
              offset-y
              style="z-index: 99;">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  rounded
                  text
                  class="body-2 menu text-third text-capitalize"
                  v-bind="attrs"
                  v-on="on">
                  Kelas Saya
                </v-btn>
              </template>

              <v-list flat dense>
                <v-list-item
                  v-for="(item, index) in menu.left"
                  :key="index"
                  :to="!item.blank ? item.link : ''"
                  :href="item.blank ? item.link : ''"
                  :target="item.blank ? '_blank' : ''"
                  v-show="index > 4">
                  <v-list-item-title class="body-2">
                    <span :class="$route.path === item.link ? 'text-first' : 'text-third'">
                      {{ item.label }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <div>
              <v-menu 
                style="z-index: 999;"
                :close-on-click="true"
                :close-on-content-click="true"
                :offset-y="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-list class="py-0 mx-2" color="#f0f0f0" style="border-radius: 50px;">
                    <v-list-item class="pl-1" 
                      v-bind="attrs"
                      v-on="on">
                      <v-list-item-avatar size="42" color="white" class="my-0" style="border: 3px solid #fff;">
                        <v-img :src="user.user_image_url !=='' ? user.user_image_url : require('@/assets/image/member-default.jpg')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content class="py-0 text-second" style=" width: 100px;">
                        <v-list-item-title class="body-2 text-capitalize font-weight-bold pt-0">
                          {{ user.user_name }}
                        </v-list-item-title>

                        <v-list-item-subtitle class="caption text-capitalize font-weight-light text-second pb-0">
                          {{ user.user_email }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action class="my-0">
                        <v-icon color="#7B7B7B">mdi-chevron-down</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </template>
                <v-list dense class="box-shadow">
                  <v-list-item-group color="#FF3177">
                    <v-list-item
                      class="cursor-pointer"
                      v-for="(item, index) in menu.right"
                      :key="index"
                      :to="item.link"
                      @click="selected.right = false">
                      <v-list-item-icon class="mr-2">
                        <v-icon small v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="caption">
                        {{ item.label }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      class="cursor-pointer"
                      @click="logout">
                      <v-list-item-icon class="mr-2">
                        <v-icon small>mdi-logout</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="caption">
                        Logout
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>

            <div>
              <v-menu 
                style="z-index: 999;"
                :close-on-click="true"
                :close-on-content-click="true"
                :offset-y="true"
                nudge-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                    fab
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="fetchCart">
                    <v-badge v-if="headers.cart > 0"
                      color="#FF3177"
                      overlap>
                      <span slot="badge" v-if="headers.cart >= 6">
                        5+
                      </span>
                      <span slot="badge" v-else>
                        {{ headers.cart }}
                      </span>
                      <span slot="default">
                        <v-icon color="#FF3177">mdi-cart-variant</v-icon>
                      </span>
                    </v-badge>
                    
                    <span slot="default" v-if="headers.cart < 1">
                      <v-icon color="#FF3177">mdi-cart-variant</v-icon>
                    </span>
                  </v-btn>
                </template>
                
                <v-card :width="list.cart.length > 0 ? '300' : ''">
                  <v-list flat dense>
                    <v-list-item-group>
                      <v-skeleton-loader
                        v-for="(item, index) in process.cart ? 5 : list.cart"
                        :key="index"
                        class="ma-auto"
                        :loading="process.cart"
                        type="list-item-avatar-two-line">
                        <div>
                          <v-list-item v-if="!process.cart && list.cart.length > 0"
                            to="/cart"
                            class="cursor-pointer">
                            <v-list-item-avatar>
                              <v-img 
                                style="border-radius: 10px;"
                                :src="item.sys_cart_kelas_image_jsonobejct.kelas_image_cover_url">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#FF3177">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title class="mb-1">
                                {{ item.sys_kelas_cart_kelas_nama }}
                              </v-list-item-title>
                              
                              <v-list-item-subtitle>
                                <span class="text-decoration-line-through text-second mr-2" v-if="item.sys_kelas_cart_kelas_is_discount">
                                  {{ parseInt(item.sys_kelas_cart_kelas_harga_jsonobject.value) | price }}
                                </span>
                                <span class="font-weight-bold">
                                  <span class="color-first" v-if="parseInt(item.sys_kelas_cart_kelas_total_harga) > 0">
                                    {{ parseInt(item.sys_kelas_cart_kelas_total_harga) | price }}
                                  </span>

                                  <span class="green--text" v-else>
                                    Gratis
                                  </span>
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-skeleton-loader>

                      <v-divider class="mt-2 mb-1" v-if="!process.cart && pagination.cart.total_page > 1"/>

                      <v-list-item v-if="!process.cart && pagination.cart.total_page > 1"
                        class="cursor-pointer"
                        to="/cart">
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-light text-center">
                            Lihat Semua
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      
                      <v-list-item v-if="!process.cart && pagination.cart.total_page < 1">
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-light text-center">
                            <v-img 
                              class="mx-auto"
                              contain
                              width="75"
                              height="75"
                              :src="require('@/assets/image/empty.png')">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#FF3177">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>

                            <p class="body-2 text-second font-weight-bold text-center my-2">
                              Keranjang mu masih kosong!
                            </p>
                            <p class="caption font-weight-light mb-6 text-center">
                              Yuk lihat kelas belajar yang menarik.
                            </p>

                            <div class="text-center mb-3">
                              <v-btn
                                small
                                rounded
                                elevation="0"
                                color="#FF3177"
                                class="text-capitalize white--text"
                                href="https://elearning.bidankita.com/elearning"
                                target="_blank">
                                <v-icon small class="mr-1">mdi-magnify</v-icon>
                                Cari Kelas Belajar
                              </v-btn>
                            </div>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-menu>
            </div>

            <div>
              <v-menu
                style="z-index: 999;"
                close-on-content-click 
                nudge-width="250" 
                offset-x>
                <template v-slot:activator="{ on }">
                  <div v-on="on"
                    @click="fetchNotif()">
                    <v-badge 
                      v-if="list.notif.length > 0"
                      class="ml-3 cursor-pointer"
                      color="#FF3177"
                      overlap>
                      <span slot="badge" v-if="list.notif.length < 10">
                        {{ list.notif.length }}
                      </span>
                      <span slot="badge" v-else>
                        9+
                      </span>
                      <span slot="default">
                        <v-icon color="#FF3177">mdi-bell</v-icon>
                      </span>
                    </v-badge>
                    <span class="ml-4 cursor-pointer" slot="default"
                      v-else>
                      <v-icon color="#FF3177">mdi-bell</v-icon>
                    </span>
                  </div>
                </template>
                <v-card>
                  <v-list flat dense :max-width="list.notif.length > 0 ? '250' : ''">
                    <v-list-item-group>
                      <v-skeleton-loader
                        v-for="(item, index) in process.notif ? 5 : list.notif"
                        :key="index"
                        class="ma-auto"
                        :loading="process.notif"
                        type="list-item-avatar-two-line">
                        <div>
                          <v-list-item v-if="!process.notif && list.notif.length > 0"
                            class="cursor-pointer"
                            :href="item.type === 'transaksi_kelas' ? `https://kelas.bidankita.com/dashboard/transaction` :
                              item.type === 'kelas_live' || item.type === 'kelas_live_announcement' ? `https://kelas.bidankita.com/collection/streaming/${item.kelas_id}?schedule_id=${item.schedule_id}` : `https://elearning.bidankita.com/collection/elearning/${item.kelas_id}`"
                            target="_balnk">
                            <v-list-item-avatar color="#f0f0f0" class="d-flex align-center justify-center">
                              {{ item.kelas_nama | initial }}
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title class="mb-1">
                                {{ item.kelas_nama }}
                              </v-list-item-title>
                              
                              <v-list-item-subtitle>
                                {{ item.date | date }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </v-skeleton-loader>

                      <v-list-item v-if="!process.notif && list.notif.length < 1">
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-light text-center">
                            <v-img 
                              class="mx-auto"
                              contain
                              width="75"
                              height="75"
                              :src="require('@/assets/image/empty.png')">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#FF3177">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>

                            <p class="body-2 text-second font-weight-bold text-center my-2">
                              Belum ada pemberitahuan!
                            </p>
                            <p class="caption font-weight-light mb-6 text-center">
                              Yuk lihat kelas belajar yang menarik.
                            </p>

                            <div class="text-center mb-3">
                              <v-btn
                                small
                                rounded
                                elevation="0"
                                color="#FF3177"
                                class="text-capitalize white--text"
                                href="https://elearning.bidankita.com/elearning"
                                target="_blank">
                                <v-icon small class="mr-1">mdi-magnify</v-icon>
                                Cari Kelas Belajar
                              </v-btn>
                            </div>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-row justify="center" v-if="rps === 'xs' || rps === 'sm'">
        <v-col cols="3" class="d-flex align-center justify-start">
          <v-menu style="z-index: 999;"
            v-model="selected.menu"
            offset-y 
            :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-list class="py-0" color="transparent" style="border-radius: 50px;">
                <v-list-item class="pl-1"
                  v-bind="attrs"
                  v-on="on">
                  <v-list-item-avatar :size="rps !== 'xs' ? 50 : 42" class="my-0" color="white">
                    <v-icon>mdi-menu</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content class="py-0 text-second">
                    <v-list-item-title class="font-weight-bold"
                      v-if="
                        $nuxt.$route.name !== 'cart' &&
                        $nuxt.$route.name !== 'wishlist' &&
                        $nuxt.$route.name !== 'site-privacy' && 
                        $nuxt.$route.name !== 'site-aboutus' && 
                        $nuxt.$route.name !== 'site-contactus' &&
                        $nuxt.$route.name !== 'site-faq' &&
                        $nuxt.$route.path !== '/500' &&
                        $nuxt.$route.path !== '/404'">
                      <span v-if="$nuxt.$route.name.split('-')[0] !== 'dashboard'">
                        {{ menu.left[selected.left].label }}
                      </span>
                      <span v-if="$nuxt.$route.name.split('-')[0] === 'dashboard'">
                        {{ menu.right[selected.right].label }}
                      </span>
                    </v-list-item-title>

                    <v-list-item-title class="font-weight-bold"
                      v-if="
                        $nuxt.$route.name === 'cart' ||
                        $nuxt.$route.name === 'wishlist' ||
                        $nuxt.$route.name === 'site-privacy' || 
                        $nuxt.$route.name === 'site-aboutus' || 
                        $nuxt.$route.name === 'site-contactus' ||
                        $nuxt.$route.name === 'site-faq'">
                      {{ 
                        $nuxt.$route.name === 'cart' ? 'Keranjang' : 
                        $nuxt.$route.name === 'wishlist' ? 'Wishlist' : 
                        $nuxt.$route.name === 'site-privacy' ? 'Kebijakan Privasi' : 
                        $nuxt.$route.name === 'site-aboutus' ? 'Tentang Kami' :
                        $nuxt.$route.name === 'site-contactus' ? 'Kontak Kami' : 'FAQ'
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
            <v-list flat dense>
              <v-list-item-group color="#FF3177">
                <!-- <v-list-item
                  class="cursor-pointer"
                  v-for="(item, index) in menu.left"
                  :key="index"
                  :to="item.link"
                  @click="selected.left = index; selected.menu = false;"
                  v-show="index < 4">
                  <v-list-item-icon class="mr-2">
                    <v-icon small v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="caption">
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  href="https://keluarga.bidankita.com/article"
                  target="_blank"
                  @click="selected.menu = false">
                  <v-list-item-icon class="mr-2">
                    <v-icon small>mdi-newspaper-variant-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Artikel
                  </v-list-item-title>
                </v-list-item> -->

                <v-list-item
                  v-for="(item, index) in menu.left"
                  :key="index"
                  v-show="index < 5"
                  :to="!item.blank ? item.link : ''"
                  :href="item.blank ? item.link : ''"
                  :target="item.blank ? '_blank' : ''"
                  @click="selected = false">
                  <v-list-item-icon class="mr-2">
                    <v-icon small v-text="item.icon"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-group no-action color="#FF3177">
                  <template v-slot:activator>
                    <v-list-item class="px-0">
                      <v-list-item-icon class="mr-2">
                        <v-icon small>
                          mdi-monitor-dashboard
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Kelas Saya</v-list-item-title>
                    </v-list-item>
                  </template>

                  <v-list-item
                    color="#FF3177"
                    class="pl-12"
                    v-for="(item, index) in menu.left"
                    :key="index"
                    :to="!item.blank ? item.link : ''"
                    :href="item.blank ? item.link : ''"
                    :target="item.blank ? '_blank' : ''"
                    @click="selected.left = index; selected.menu = false;"
                    v-show="index > 4">
                    <v-list-item-content>
                      <v-list-item-title class="caption">
                        {{ item.label }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>

        <v-col cols="9" class="d-flex align-center justify-end">
          <v-menu style="z-index: 999;"
            :close-on-click="true"
            :close-on-content-click="true"
            :offset-y="true">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="42" color="white" class="my-0" style="border: 3px solid #fff;"
                v-bind="attrs"
                v-on="on">
                <v-img :src="user.user_image_url !=='' ? user.user_image_url : require('@/assets/image/member-default.jpg')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </template>
            <v-list flat dense>
              <v-list-item-group color="#FF3177">
                <v-list-item
                  class="cursor-pointer"
                  v-for="(item, index) in menu.right"
                  :key="index"
                  :to="item.link"
                  @click="selected.right = index">
                  <v-list-item-icon class="mr-2">
                    <v-icon small v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="caption">
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  class="cursor-pointer"
                  @click="logout">
                  <v-list-item-icon class="mr-2">
                    <v-icon small>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="caption">
                    Logout
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import moment from 'moment'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        selected: {
          menu: false,
          left: 0,
          right: 0
        },
        menu: {
          left: [
            {
              label: "Beranda",
              link: '/home',
              icon: "mdi-home-circle-outline",
              blank: false
            },
            {
              label: "E-Learning",
              link: "https://elearning.bidankita.com/elearning",
              icon: "mdi-google-classroom",
              blank: true
            },
            {
              label: "Kelas",
              link: "/streaming",
              icon: "mdi-cast-education"
            },
            {
              label: "Agenda",
              link: "https://elearning.bidankita.com/agenda",
              icon: "mdi-calendar-month",
              blank: true
            },
            {
              label: "Artikel",
              link: "https://keluarga.bidankita.com/article/free",
              icon: "mdi-newspaper",
              blank: true
            },
            {
              label: "Materiku",
              link: "https://elearning.bidankita.com/collection/elearning",
              icon: "mdi-google-classroom",
              blank: true
            },
            {
              label: "Rekamanku",
              link: "/collection/streaming",
              icon: "mdi-cast-education",
              blank: false
            }
          ],
          right: [
            {
              label: "Profil Saya",
              link: "/dashboard/profile",
              icon: "mdi-account-circle-outline"
            },
            {
              label: "Wishlist",
              link: "/wishlist",
              icon: "mdi-heart-outline"
            },
            {
              label: "Keranjang",
              link: "/cart",
              icon: "mdi-cart-variant"
            },
            {
              label: "Transaksi",
              link: "/dashboard/transaction",
              icon: "mdi-credit-card-settings-outline"
            }
          ]
        },
        list: {
          cart: [],
          notif: this.$store.state.header.notif
        },
        pagination: {
          cart: {},
          notif: {}
        },
        process: {
          cart: false,
          notif: false
        }
      }
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      user() {
        return this.$store.state.member.auth.user
      },
      headers() {
        return this.$store.state.header
      },
      base() {
        return process.env.HOST_NAME_PORTAL_PROD
      }
    },
    created () {
      this.$axios.$get(`${process.env.PREFIX_PROXY}/member/cart`)
      .then((response) => {
        if (response.status === 200) {
          this.$store.commit('header/setCart', response.data.pagination.total_data)
        }
      })

      if (process.browser) {
        if ('Notification' in window && navigator.serviceWorker) {
          if (this.token) {
            this.$store.commit('header/setNotif', [])


            navigator.serviceWorker.addEventListener('message', event => {
              let data = {
                kelas_id: event.data.data.kelas_id,
                kelas_nama: event.data.data.type === 'transaksi_kelas' ? event.data.data.transaction_code : event.data.data.kelas_nama,
                kelas_slug: event.data.data.kelas_slug,
                schedule_id: event.data.data.schedule_id,
                type: event.data.data.type,
                date: moment().format('YYYY-MM-DD HH:mm:ss')
              }

              this.list.notif.push(data)
              this.$store.commit('header/setNotif', this.list.notif)
              Cookie.set('notif', this.list.notif, { domain: 'bidankita.com' })
            })
          }
        }
      }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) { 
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        } 
      };
      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }
      document.addEventListener('scroll', debounce(storeScroll), { passive: true })
      storeScroll()

      this.selected.left = this.$route.path === '/home' ? 0 : 1

      this.selected.right = 
        this.$route.path === '/dashboard/profile' ? 0 : 
        this.$route.path === '/dashboard/transaction' ? 1 : 0
    },
    methods: {
      async fetchCart() {
        this.process.cart = true

        let params = {
          limit: 5,
          sort: 'sys_kelas_cart_updated_datetime',
          dir: 'DESC'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/member/cart`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.cart = false
            this.list.cart = response.data.results
            this.pagination.cart = response.data.pagination
          }
        })
      },
      async fetchNotif() {
        this.process.notif = true
        setTimeout(() => {
          this.process.notif = false
        }, 500)
      },
      logout() {
        Cookie.remove('token')
        Cookie.remove('user')
        Cookie.remove('profile')
        Cookie.remove('fcm')
        Cookie.remove('notif')

        // localStorage.removeItem('user')
        // localStorage.removeItem('profile')
        // localStorage.removeItem('token')

        this.logoutGoogle()
        // if (window.location.hostname != 'elearning.bidankita.com'){
        //     window.location.href = `https://dev-portal.bidankita.com/logout?ref=${window.location.href}`
        // }else{
        //     window.location.href = `https://keluarga.bidankita.com/logout?ref=${window.location.href}`
        // }
      },
      async logoutGoogle() {
        await this.$firebase.auth().signOut().then(() => {
          window.location = '/home'
        }).catch((error) => {
          console.log(error)
        })
      }
    }
  }
</script>

<style>
.header {
  background-color: #FFFFFF !important;
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.05) !important;
}
.header-streaming {
  background-color: #FFFFFF !important;
}
.v-menu__content {
  border-radius: 20px !important;
}
</style>