<template>
  <div class="fill-height py-12 mt-6">
    <v-dialog v-model="filter.dialog"
      width="500"
      persistent>
      <v-card 
        color="#f3f3f3"
        class="border-radius pa-2">
        <v-card-text class="pa-0 text-right">
          <v-btn
            fab
            x-small
            elevation="0"
            class="background-first white--text"
            @click="filter.dialog = false; reset();">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text>
          <v-form>
            
            <v-row>
              <v-col cols="12" md="8" sm="8" class="py-0">
                <v-select
                  dense
                  outlined
                  color="#FF3177"
                  single-line
                  :items="mentor"
                  item-color="#FF3177"
                  item-text="mentor_kelas_user_nama"
                  item-value="mentor_kelas_user_id"
                  placeholder="Pengajar"
                  clearable
                  v-model="filter.mentor">
                  <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                      <v-avatar size="30">
                        <v-img 
                          :src="item.mentor_image_cover_url !=='' ? item.mentor_image_cover_url : require('@/assets/image/member-default.jpg')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>
                      <span class="text-capitalize ml-2">
                        {{ item.mentor_kelas_user_nama }}
                      </span>
                    </div>
                  </template>

                  <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                      <v-avatar size="30">
                        <v-img 
                          :src="item.mentor_image_cover_url !=='' ? item.mentor_image_cover_url : require('@/assets/image/member-default.jpg')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#FF3177">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>
                      <span class="text-capitalize ml-2">
                        {{ item.mentor_kelas_user_nama }}
                      </span>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="6" class="py-0">
                <v-select
                  dense
                  outlined
                  color="#FF3177"
                  single-line
                  :items="[
                    {
                      text: 'Semua Sorting',
                      value: ''
                    },
                    {
                      text: 'Terbaru',
                      value: 'Terbaru'
                    },
                    {
                      text: 'Termahal',
                      value: 'Termahal'
                    },
                    {
                      text: 'Termurah',
                      value: 'Termurah'
                    },
                    {
                      text: 'Review Terbanyak',
                      value: 'Review Terbanyak'
                    }
                  ]"
                  item-text="text"
                  item-value="value"
                  item-color="#FF3177"
                  clearable
                  placeholder="Sorting"
                  v-model="filter.sort">
                </v-select>
              </v-col>

              <v-col cols="12" md="6" sm="6" class="py-0">
                <v-select
                  dense
                  outlined
                  color="#FF3177"
                  single-line
                  clearable
                  placeholder="Harga"
                  :items="[
                    {
                      text: 'Semua Harga',
                      value: ''
                    },
                    {
                      text: 'Free',
                      value: '0'
                    },
                    {
                      text: 'Premium',
                      value: '1'
                    }
                  ]"
                  item-text="text"
                  item-value="value"
                  item-color="#FF3177"
                  v-model="filter.premium">
                  <template v-slot:append>
                    <v-icon color="#f0f0f0" x-small>mdi-chevron-down</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <div class="d-flex align-center mb-6">
              <div>
                <v-checkbox
                  class="mt-0"
                  hide-details
                  v-model="filter.discount"
                  color="#FF3177">
                </v-checkbox>
              </div>

              <div class="text-first cursor-pointer mt-1"
                @click="filter.discount = !filter.discount ? true : false">
                Cari Kelas Diskon (%) ?
              </div>
            </div>

            <p class="text-first my-2">
              Rentang Harga
            </p>
            <div class="d-flex align-center text-first body-2 font-weight-bold mb-3">
              <div>
                {{ filter.price[0] | price }}
              </div>
              <div class="mx-2">-</div>
              <div>
                {{ filter.price[1] | price }}
              </div>
            </div>

            <v-row>
              <v-col cols="12" md="8" sm="8" class="py-0 pr-3">
                <v-range-slider
                  color="#FF3177"
                  track-color="#E0E0E0"
                  :disabled="filter.premium === '0'"
                  dense
                  hide-details
                  :max="1000000"
                  :min="0"
                  v-model="filter.price">
                  <template v-slot:append v-if="filter.price[0] !== 0 || filter.price[1] !== 0">
                    <v-icon class="cursor-pointer" @click="filter.price = [0, 0]">mdi-close</v-icon>
                  </template>
                </v-range-slider>
              </v-col>

              <v-col cols="12" md="4" sm="4" class="py-0 text-right">
                <v-btn
                  block
                  rounded
                  elevation="0"
                  color="#FF3177"
                  class="text-capitalize white--text"
                  @click="filter.dialog = false; fetch();">
                  <v-icon class="mr-1">mdi-magnify</v-icon>
                  Cari
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row justify="center">
              <v-col class="text-h5 text-center font-weight-bold">
                Wishlist
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" md="3" sm="3" :class="rps === 'xs' ? '' : 'pr-0'">
                <v-menu
                  rounded
                  offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="rps === 'xs' ? 'b_a br_a' : 'b_l b_t b_b br_l'"
                      elevation="0"
                      color="#f0f0f0"
                      block
                      height="40"
                      class="body-2 text-capitalize pr-2 d-flex justify-start"
                      v-bind="attrs"
                      v-on="on">
                      {{ filter.label_type }}
                      <v-icon color="grey darken-1" style="position: absolute; right: 0px;">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list flat dense>
                    <v-list-item-group
                      color="#FF3177">
                      <v-list-item class="hover-list"
                        v-for="(item, index) in type"
                        :key="index"
                        @click="
                          filter.type = item.value; 
                          filter.label_type = item.label;
                          filter.label_category = 'Semua Kategori';
                          filter.category = '';
                          filter.category_sub = '';">
                        <v-list-item-title class="body-2">
                          {{ item.label }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col cols="12" md="3" sm="3" :class="rps === 'xs' ? '' : 'px-0'">
                <v-menu
                  rounded
                  offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="rps === 'xs' ? 'b_a br_a' : 'b_a br_n'"
                      elevation="0"
                      color="#f0f0f0"
                      block
                      height="40"
                      class="body-2 text-capitalize pr-2 d-flex justify-start"
                      v-bind="attrs"
                      v-on="on">
                      <span style="max-width: 200px;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;">
                        {{ filter.label_category }}
                      </span>
                      <v-icon color="grey darken-1" style="position: absolute; right: 0px;">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list flat dense max-height="300">
                    <v-list-item-group
                      style="background: white;"
                      color="#FF3177">
                      <div 
                        v-for="(item, index) in category"
                        :key="index">
                        <v-list-item class="hover-list" v-if="item.sub.length < 1"
                          @click="
                            filter.category = item.value; 
                            filter.label_category = item.label; 
                            filter.category_sub = '';
                            fetch();">
                          <v-list-item-title class="body-2">
                            {{ item.label }}
                          </v-list-item-title>
                        </v-list-item>

                        <div v-if="item.sub.length > 0">
                          <v-list-group no-action color="#FF3177" @click.stop>
                            <template v-slot:activator>
                              <v-list-item class="px-0">
                                <v-list-item-title>
                                  {{ item.label }}
                                </v-list-item-title>
                              </v-list-item>
                            </template>

                            <v-list-item
                              class="px-0 hover-list"
                              v-for="(sub, i) in item.sub"
                              :key="i"
                              @click="
                                filter.category_sub = sub.kelas_kategori_id; 
                                filter.label_category = sub.kelas_kategori_title; 
                                filter.category = '';
                                fetch();">
                              <v-list-item-title class="pl-8">
                                {{ sub.kelas_kategori_title }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list-group>
                        </div>
                      </div>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6" sm="6" :class="rps === 'xs' ? '' : 'pl-0'">
                <div class="d-flex align-center justify-end" style="width: 100%;">
                  <v-text-field
                    :class="rps === 'xs' ? 'br_a' : 'br_r search-style'"
                    outlined
                    dense 
                    single-line
                    hide-details
                    color="#dadada"
                    placeholder="Temukan kelas yang sesuai kebutuhan anda . . ."
                    clearable
                    v-model="filter.title"
                    @click:clear="filter.title = ''; fetch();"
                    v-on:keyup.enter="fetch()">
                  </v-text-field>

                  <v-btn
                    style="position: absolute; right: auto;"
                    elevation="0"
                    rounded
                    height="40"
                    color="#FF3177"
                    class="text-capitalize white--text"
                    @click="fetch()">
                    <v-icon color="white">mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <section>
          <v-container>
            <v-row 
              justify="center"
              align="end">
              <v-col cols="12" md="8" sm="8" :class="rps === 'xs' ? 'text-center' : ''">
                <span class="body-1 font-weight-bold text-second">
                  Semua Kelas
                  {{ filter.type === '' ? '' : filter.label_type }}
                  Di
                  {{ filter.category === '' ? filter.label_category : `Kategori ${filter.label_category}` }}
                </span>
              </v-col>

              <v-col cols="12" md="4" sm="4" class="d-flex align-center" :class="rps === 'xs' ? 'justify-center' : 'justify-end'">
                <v-tabs
                  v-model="selected.menu"
                  background-color="transparent"
                  color="#FF3177"
                  dense
                  grow
                  class="d-flex" :class="rps === 'xs' ? 'justify-start' : 'justify-end'">
                  <v-tab
                    v-for="(item, index) in menu"
                    :key="index"
                    class="px-0 mx-1"
                    style="min-width: 55px; max-width: 55px;">
                    <span class="body-2 text-capitalize" :class="selected.menu === index ? 'font-weight-bold' : 'text-third'">
                      {{ item.label }}
                    </span>
                  </v-tab>
                </v-tabs>
                
                <v-icon class="ml-6 cursor-pointer" @click="filter.dialog = true; fetchMentor();">mdi-filter-variant</v-icon>
              </v-col>
            </v-row>

            <v-row class="py-6">
              <v-col 
                cols="12" 
                md="4"
                class="pa-1"
                v-for="(item, index) in process.run ? 15 : list"
                :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.run"
                  type="image, article">
                  <div>
                    <v-hover
                      v-slot="{ hover }"
                      open-delay="200">
                      <div>
                        <v-card v-if="!process.run && list.length > 0"
                          class="box-shadow"
                          width="100%"
                          height="250"
                          style="border-radius: 0px;"
                          :href="rps !== 'xs' ? '' : item.kelas_is_live ? `https://kelas.bidankita.com/streaming/${item.kelas_slug}` : item.kelas_is_purchased === '0' ? `https://elearning.bidankita.com/elearning/${item.kelas_slug}` : `https://elearning.bidankita.com/collection/elearning/${item.kelas_id}`"
                          target="_blank">
                          <v-img
                            width="100%"
                            height="100%"
                            gradient="to left, rgba(0,0,0,0.25), rgba(0,0,0,0.75)"
                            :src="item.kelas_image_jsonobejct.kelas_image_cover_url">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#FF3177">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <v-container
                              class="py-0">
                              <v-row
                                align="start"
                                style="height: 190px;">
                                <v-col
                                  cols="4"
                                  class="pr-0">
                                  <div
                                    class="text-center white--text my-4">
                                    <div 
                                      class="font-weight-bold mb-2"
                                      :class="rps !== 'xs' ? 'text-h3' : 'title'">
                                      {{ item.kelas_active_datetime | day }}
                                    </div>

                                    <div 
                                      class="text-uppercase my-1"
                                      :class="rps !== 'xs' ? 'title' : 'body-2'">
                                      {{ item.kelas_active_datetime | month }} {{ item.kelas_active_datetime | year }}
                                    </div>

                                    <div 
                                      class="font-weight-bold"
                                      :class="rps !== 'xs' ? 'text-h4' : 'body-1'">
                                      {{ item.kelas_active_datetime | time }}
                                    </div>
                                  </div>
                                </v-col>

                                <v-col
                                  cols="1"
                                  class="text-center">
                                  <v-divider
                                    vertical
                                    style="border: 1px solid #FFFFFF; background: #FFFFFF; border-radius: 50px; height: 165px;" />
                                </v-col>

                                <v-col
                                  cols="7"
                                  class="pl-0">
                                  <div class="mb-2">
                                    <v-chip v-if="!item.kelas_is_live"
                                      style="border: 1px solid #ffffffb3 !important;"
                                      color="#616161"
                                      label
                                      :small="rps !== 'xs'"
                                      :x-small="rps === 'xs'"
                                      text-color="white"
                                      class="font-weight-bold text-uppercase">
                                      E-Learning
                                    </v-chip>

                                    <v-chip v-if="item.kelas_is_live"
                                      style="border: 1px solid #ffffffb3 !important;"
                                      color="green"
                                      label
                                      :small="rps !== 'xs'"
                                      :x-small="rps === 'xs'"
                                      text-color="white"
                                      class="font-weight-bold text-uppercase">
                                      Kelas
                                    </v-chip>

                                    <div 
                                      class="text-second caption white--text mt-2">
                                      {{ item.kelas_type_title }}
                                    </div>
                                  </div>
                                  
                                  <div
                                    class="text-h5 white--text font-weight-bold two-line mb-2">
                                    {{ item.kelas_nama }}
                                  </div>

                                  <div class="d-flex align-center">
                                    <v-rating
                                      :value="parseFloat(item.kelas_total_rating)"
                                      background-color="yellow darken-2"
                                      color="yellow darken-2"
                                      dense
                                      readonly
                                      size="18">
                                    </v-rating>
                                    <span class="white--text caption font-weight-bold ml-1">
                                      ({{ parseFloat(item.kelas_total_rating).toFixed(1) }})
                                    </span>
                                  </div>
                                </v-col>
                              </v-row>

                              <v-row
                                align="center">
                                <v-col
                                  cols="8">
                                  <v-chip
                                    color="#ffffffb3"
                                    style="min-width: 100%;">
                                    <!-- <span
                                      class="mr-1">
                                      <v-icon size="20">mdi-currency-usd</v-icon>
                                    </span> -->

                                    <span v-if="!item.kelas_is_discount  && !item.kelas_is_live">
                                      <span class="green--text font-weight-bold" v-if="!item.kelas_berbayar">
                                        Gratis
                                      </span>

                                      <span class="color-first" v-else>
                                        <span class="font-weight-bold">
                                          {{ parseInt(item.kelas_harga_jsonobject.value_after_discount) | price }}
                                        </span> 
                                        <span class="caption black--text">
                                          /
                                          <span v-if="!item.kelas_harga_jsonobject.is_lifetime">
                                            {{ item.kelas_harga_jsonobject.subcribe_value }} 
                                            {{ item.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : item.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                                          </span>

                                          <span v-else>
                                            Lifetime
                                          </span>
                                        </span>
                                      </span>
                                    </span>

                                    <span v-if="item.kelas_is_discount && !item.kelas_is_live"> 
                                      <span class="body-2 black--text" v-if="item.kelas_is_discount">
                                        <span class="text-decoration-line-through">
                                          {{ parseInt(item.kelas_harga_jsonobject.value) | price }}
                                        </span>
                                      </span>

                                      <v-spacer/>

                                      <span class="color-first">
                                        <span class="font-weight-bold">
                                          {{ parseInt(item.kelas_harga_jsonobject.value_after_discount) | price }}
                                        </span> 
                                        <span class="caption black--text">
                                          /
                                          <span v-if="!item.kelas_harga_jsonobject.is_lifetime">
                                            {{ item.kelas_harga_jsonobject.subcribe_value }} 
                                            {{ item.kelas_harga_jsonobject.subcribe_type === 'day' ? 'Hari' : item.kelas_harga_jsonobject.subcribe_type === 'month' ? 'Bulan' : 'Tahun' }}
                                          </span>

                                          <span v-else>
                                            Lifetime
                                          </span>
                                        </span>
                                      </span>
                                    </span>

                                    <span v-if="item.kelas_is_live"> 
                                      <span v-if="item.kelas_harga_jsonobject.harga_min === item.kelas_harga_jsonobject.harga_max"
                                        class="font-weight-bold color-first">
                                        {{ parseInt(item.kelas_harga_jsonobject.harga_max) | price }}
                                      </span>

                                      <span v-else
                                        class="font-weight-bold color-first">
                                        {{ parseInt(item.kelas_harga_jsonobject.harga_min) | price }}
                                        -
                                        {{ parseInt(item.kelas_harga_jsonobject.harga_max) | price }}
                                      </span>
                                    </span>
                                  </v-chip>
                                </v-col>

                                <v-col
                                  cols="4">
                                  <v-chip
                                    color="#ffffffb3"
                                    style="min-width: 100%;">
                                    <span 
                                      class="body-2">
                                      {{ item.kelas_total_materi }} Materi
                                    </span>
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-img>
                        </v-card>

                        <v-expand-transition>
                          <div v-if="hover && rps !== 'xs'">
                            <v-card
                              width="100%"
                              height="510"
                              outlined
                              style="border-radius: 0px !important;"
                              :class="index === 2 || index === 5 || index === 8 || index === 11 ? 'on-hover-right arrow-right' : 'on-hover-left arrow-left'">
                              <v-card-text style="width: auto;">
                                <p class="body-1 font-weight-bold line-text-first text-first text-capitalize">
                                  <span class="two-line">
                                    {{ item.kelas_nama }}
                                  </span>
                                </p>

                                <p class="body-2 text-second mb-0">
                                  <span class="one-line">
                                    {{ item.kelas_kategori_title }}
                                  </span>
                                </p>

                                <v-list flat dense>
                                  <v-list-item class="px-0">
                                    <v-list-item-avatar size="30" class="mr-2">
                                      <v-img :src="item.kelas_author.kelas_author_photo">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              size="25"
                                              indeterminate 
                                              color="#FF3177">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ item.kelas_author.kelas_author_nama }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>

                                <div style="height: 265px;">
                                  <p class="body-2 font-weight-light text-first line-text-first">
                                    <span class="nine-line" v-html="item.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')"></span>
                                  </p>

                                  <p class="body-2 font-weight-light text-first line-text-first mb-0">
                                    <v-icon small class="mr-1">mdi-calendar-check</v-icon>
                                    Terakhir update <b>{{ item.kelas_update_datetime | date }}</b>
                                  </p>
                                </div>
                              </v-card-text>

                              <v-card-text class="d-flex align-center justify-center"
                                style="position: absolute; bottom: 10px; width: auto;">
                                <v-btn
                                  rounded
                                  width="160"
                                  elevation="0"
                                  color="#FF3177"
                                  class="text-capitalize white--text mr-2"
                                  :disabled="item.schedule_is_purchased === '0' && dateNow > item.schedule_end_datetime"
                                  :href="item.kelas_is_live ? `https://kelas.bidankita.com/streaming/${item.kelas_slug}` : item.kelas_is_purchased === '0' ? `https://elearning.bidankita.com/elearning/${item.kelas_slug}` : `https://elearning.bidankita.com/collection/elearning/${item.kelas_id}`"
                                  target="_blank">
                                  Selengkapnya
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-expand-transition>
                      </div>
                    </v-hover>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row justify="center" align="center" v-if="!process.run && pagination.total_page < 1">
              <v-col cols="12" class="mt-16">
                <v-img 
                  class="mx-auto mt-16"
                  contain
                  width="175"
                  height="175"
                  :src="require('@/assets/image/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#FF3177">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <p class="text-h5 text-second font-weight-bold text-center">
                  Kelas yang kamu cari tidak ada!
                </p>
                <p class="body-1 font-weight-light mb-6 text-center">
                  Yuk cari kelas rekaman lain di E-Learning Bidan Kita.
                </p>

                <div class="text-center">
                  <v-btn
                    rounded
                    elevation="0"
                    color="#FF3177"
                    class="text-capitalize white--text"
                    to="/streaming">
                    <v-icon class="mr-1">mdi-magnify</v-icon>
                    Cari Kelas Belajar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            
            <Pagination v-if="pagination.total_page > 1"
              @changePage="fetch($event)" 
              @reloadPage="fetch($event)"
              :model="pagination" />
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        menu: [
          {
            label: 'Semua',
            value: ''
          },
          {
            label: 'Free',
            value: '0'
          },
          {
            label: 'Premium',
            value: '1'
          }
        ],
        filter: {
          dialog: false,
          label_type: 'Semua Tipe',
          type: '',
          label_category: 'Semua Kategori',
          category: '',
          category_sub: '',
          title: '',
          mentor: '',
          sort: 'Terbaru',
          premium: '',
          price: [0, 0],
          discount: false
        },
        type: [
          {
            label: 'Semua Tipe',
            value: ''
          }
        ],
        category: [
          {
            label: 'Semua Kategori',
            value: '',
            sub: []
          }
        ],
        selected: {
          menu: 0
        },
        mentor: [],
        list: [],
        pagination: {},
        process: {
          run: false
        },
        // SEO
        content: {
          url: 'wishlist',
          title: 'Wishlist Bidan Kita',
          description: 'Wishlist Bidan Kita',
          image: require('@/assets/logo/logo_bidankita_color.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Pagination: () => import('@/components/Pagination')
    },
    watch: {
      'filter.type': function(val) {
        this.fetch()
        this.fetchCategory()
      },
      'filter.premium': function(val) {
        this.selected.menu = val === '' || val === null ? 0 : val === '0' ? 1 : 2

        if (val === '0') {
          this.filter.price = [0, 0]
        }
      },
      'selected.menu': function(val) {
        this.filter.premium = this.menu[val].value

        this.fetch()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      }
    },
    mounted () {
      this.fetchType()
      this.fetchCategory()
      this.fetch()
    },
    methods: {
      async fetchType() {
        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/type`)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data.results
            for (let i in data) {
              this.type.push({
                label: data[i].kelas_type_title,
                value: data[i].kelas_type_id
              })
            }
          }
        })
      },
      async fetchCategory() {
        let params = {
          type_id: this.filter.type,
          sort: 'kelas_kategori_title',
          dir: 'ASC'
        }
        
        this.category = [{
          label: 'Semua Kategori',
          value: '',
          sub: []
        }]

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/category_nested`, { params })
        .then((response) => {
          if (response.status === 200) {
            let data = response.data.results
            for (let i in data) {
              this.category.push({
                label: data[i].kelas_kategori_title,
                value: data[i].kelas_kategori_id,
                sub: data[i].kelas_kategori_child
              })
            }
          }
        })
      },
      async fetchMentor() {
        await this.$axios.$get(`${process.env.PREFIX_PROXY}/class/lists/mentor`)
        .then((response) => {
          if (response.status === 200) {
            this.mentor = response.data.results
          }
        })
      },
      reset() {
        this.filter = {
          dialog: false,
          label_type: 'Semua Tipe',
          type: '',
          label_category: 'Semua Kategori',
          category: '',
          category_sub: '',
          title: '',
          mentor: '',
          sort: 'Terbaru',
          premium: '',
          price: [0, 0],
          discount: false
        }

        this.fetch()
      },
      async fetch(pagination = { row: 20, page: 1 }) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        
        this.process.run = true

        let price = this.filter.price[0] === this.filter.price[1] ? '' : `${this.filter.price[0]}::${this.filter.price[1]}`,
            sort = this.filter.sort === 'Terbaru' ? 'kelas_create_datetime' : this.filter.sort === 'Review Terbanyak' ? 'kelas_total_rating' : 'kelas_harga',
            dir = this.filter.sort === 'Terbaru' || this.filter.sort === 'Termahal' || this.filter.sort === 'Review Terbanyak' ? 'DESC' : 'ASC'
        let params = {
          limit: pagination.row,
          page: pagination.page,
          sort: this.filter.sort === '' ? '' : sort,
          dir: this.filter.sort === '' ? '' : dir,
          id_mentor: this.filter.mentor,
          filter: [
            {
              type: 'string',
              field: 'kelas_type_id',
              value: this.filter.type
            },
            {
              type: 'string',
              field: 'kelas_kategori_id',
              value: this.filter.category
            },
            {
              type: 'string',
              field: 'kelas_kategori_child_id',
              value: this.filter.category_sub
            },
            {
              type: 'string',
              field: 'kelas_harga',
              comparison: this.filter.premium === '0' ? '=' : this.filter.premium === '1' ? '>' : '',
              value: this.filter.premium === '' ? '' : 0
            },
            {
              type: 'string',
              field: 'kelas_nama',
              value: this.filter.title,
              comparison: '<>'
            },
            {
              type: 'list',
              field: 'kelas_harga',
              value: price,
              comparison: 'bet'
            },
            {
              type: 'numeric',
              field: 'kelas_diskon_value',
              value: this.filter.discount ? 0 : '',
              comparison: this.filter.discount ? '>' : ''
            }
          ]
        }

        this.list = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/wishlist/my`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.process.run = false
            response.data.results.filter(v => {
              v.kelas_deskripsi = v.kelas_deskripsi.replace(/<\/?[^>]+>/ig, '')
              this.list.push(v)
            })
            this.pagination = response.data.pagination
          }
        })
      }
    }
  }
</script>

<style scoped>
  .search-style.v-text-field--outlined >>> fieldset {
    border-color: #dadada;
    border-left: 0px;
  }
  .on-hover-left {
    position: absolute;
    top: 0px;
    right: -395px;
    z-index: 9;
    border-radius: 5px !important;
  }
  .on-hover-left.arrow-left:after {
    content: "";
    position: absolute;
    left: -10px;
    top: 126px;
    border-top: 10px solid transparent;
    border-right: 10px solid #ffffff;
    border-left: none;
    border-bottom: 10px solid transparent;
  }
  .on-hover-left.arrow-left:before {
    content: "";
    position: absolute;
    left: -11px;
    top: 125px;
    border-top: 11px solid transparent;
    border-right: 11px solid #dadada;
    border-left: none;
    border-bottom: 11px solid transparent;
  }


  .on-hover-right {
    position: absolute;
    top: 0px;
    left: -395px;
    z-index: 9;
    border-radius: 5px !important;
  }
  .on-hover-right.arrow-right:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 126px;
    border-top: 10px solid transparent;
    border-right: none;
    border-left: 10px solid #ffffff;
    border-bottom: 10px solid transparent;
  }
  .on-hover-right.arrow-right:before {
    content: "";
    position: absolute;
    right: -11px;
    top: 125px;
    border-top: 11px solid transparent;
    border-right: none;
    border-left: 11px solid #dadada;
    border-bottom: 11px solid transparent;
  }
</style>

<style>
  .tabs-stream .v-tabs-slider {
    background-color: currentColor;
    height: 100%;
    width: 100%;
    border-radius: 20px 20px 0px 0px !important;
  }
  .expand-stream .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 40px;
  }
  .list-group-stream .v-list-group__header {
    padding: 0px;
  }
</style>