<template>
  <div>
    <DialogAuth :dialogauth="dialog" @close="dialog = ''" />

    <v-app-bar 
      app
      fixed
      :flat="$route.name === 'streaming' || $route.name === 'streaming-special'"
      :class="{
        'px-16': rps !== 'xs' && rps !== 'sm',
        'header': $route.name !== 'streaming' && $route.name !== 'streaming-special',
        'header-streaming': $route.name === 'streaming'
      }">
      <v-container v-if="rps !== 'xs' && rps !== 'sm'">
        <v-row justify="center">
          <v-col cols="2" class="d-flex align-center justify-start">
            <nuxt-link to="/home">
              <v-img
                width="175"
                height="auto"
                :src="require('@/assets/logo/logo_bidan_tagline.png')">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#FF3177">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </nuxt-link>
          </v-col>

          <v-col cols="10" class="d-flex align-center justify-end">
            <!-- <v-btn
              v-for="(item, index) in menu"
              :key="index"
              rounded
              text
              color="white"
              class="body-2 menu text-capitalize"
              :to="item.link">
              <span :class="$route.path === item.link ? 'text-first' : 'text-third'">
                {{ item.label }}
              </span>
            </v-btn>

            <v-btn
              rounded
              text
              color="white"
              class="body-2 menu text-capitalize"
              href="https://keluarga.bidankita.com/article/free"
              target="_blank">
              <span class="text-third">
                Artikel
              </span>
            </v-btn> -->

            <v-btn
              v-for="(item, index) in menu"
              :key="index"
              rounded
              text
              color="white"
              class="body-2 menu text-capitalize"
              :to="!item.blank ? item.link : ''"
              :href="item.blank ? item.link : ''"
              :target="item.blank ? '_blank' : ''">
              <span :class="$route.path === item.link ? 'text-first' : 'text-third'">
                {{ item.label }}
              </span>
            </v-btn>

            <v-divider
              class="mx-2"
              vertical
              color="white"
              style="min-height: 15px !important; height: 25px; margin-top: auto; margin-bottom: auto; opacity: 50%;">
            </v-divider>

            <v-btn 
              rounded 
              small 
              elevation="0"
              class="body-2 text-capitalize white--text ml-5 mr-3" 
              color="background-first"
              width="80"
              @click="dialog = 'register'">
              Daftar
            </v-btn>
            
            <v-btn 
              rounded 
              small 
              text
              class="body-2 text-capitalize color-first" 
              color="white"
              width="100"
              @click="dialog = 'login'">
              <span class="color-first">
                <v-icon small class="mr-1">mdi-account-outline</v-icon>
                Masuk
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      
      <v-row justify="center" v-if="rps === 'xs' || rps === 'sm'">
        <v-col cols="9" class="d-flex align-center justify-start">
          <nuxt-link to="/home">
            <v-avatar size="50" width="150" tile>
              <v-img
                :src="require('@/assets/logo/logo_bidan_tagline.png')">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#FF3177">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </nuxt-link>
        </v-col>

        <v-col cols="3" class="d-flex align-center justify-end">
          <v-menu 
            v-model="selected"
            offset-y 
            :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                elevation="0"
                class="caption text-capitalize white--text" 
                color="background-first"
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list dense flat>
              <v-list-item-group color="#FF3177">
                <!-- <v-list-item
                  v-for="(item, index) in menu"
                  :key="index"
                  :to="item.link"
                  @click="selected = false">
                  <v-list-item-icon class="mr-2">
                    <v-icon small v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  href="https://keluarga.bidankita.com/article"
                  target="_blank"
                  @click="selected = false">
                  <v-list-item-icon class="mr-2">
                    <v-icon small>mdi-newspaper-variant-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Artikel
                  </v-list-item-title>
                </v-list-item> -->

                <v-list-item
                  v-for="(item, index) in menu"
                  :key="index"
                  :to="!item.blank ? item.link : ''"
                  :href="item.blank ? item.link : ''"
                  :target="item.blank ? '_blank' : ''"
                  @click="selected = false">
                  <v-list-item-icon class="mr-2">
                    <v-icon small v-text="item.icon"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            
            <v-list dense flat>
              <v-list-item-group color="#FF3177">
                <v-list-item
                  @click="selected = false">
                  <v-list-item-title>
                    <v-btn 
                      rounded 
                      small 
                      elevation="0"
                      class="caption text-capitalize white--text" 
                      color="background-first"
                      width="100"
                      @click="dialog = 'register'">
                      Daftar
                    </v-btn>
                    <v-btn 
                      rounded 
                      small 
                      elevation="0"
                      class="caption text-capitalize color-first" 
                      color="white"
                      width="100"
                      @click="dialog = 'login'">
                      <v-icon small class="mr-2">mdi-account-outline</v-icon>
                      Masuk
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        dialog: '',
        linkLogin: '',
        selected: false,
        menu: [
          {
            label: "Beranda",
            link: '/home',
            icon: "mdi-home-circle-outline",
            blank: false
          },
          {
            label: "E-Learning",
            link: "https://elearning.bidankita.com/elearning",
            icon: "mdi-google-classroom",
            blank: true
          },
          {
            label: "Kelas",
            link: "/streaming",
            icon: "mdi-cast-education",
            blank: false
          },
          {
            label: "Agenda",
            link: "https://elearning.bidankita.com/agenda",
            icon: "mdi-calendar-month",
            blank: true
          },
          {
            label: "Artikel",
            link: "https://keluarga.bidankita.com/article/free",
            icon: "mdi-newspaper",
            blank: true
          }
        ],
        sosmed: [
          require("@/assets/sosmed/ic_share_fb.svg"),
          require("@/assets/sosmed/ic_share_ig.svg"),
          require("@/assets/sosmed/ic_share_tw.svg")
        ]
      }
    },
    components: {
      DialogAuth: () => import('@/components/dialog/auth')
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      base() {
        return process.env.HOST_NAME_PORTAL_PROD
      }
    },
    mounted () {
      // if (window.location.hostname != 'elearning.bidankita.com'){
      //     this.linkLogin = `https://dev-portal.bidankita.com/login?ref=${window.location.href}`
      // }else{
      //     this.linkLogin = `https://keluarga.bidankita.com/login?ref=${window.location.href}`
      // }
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) { 
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        } 
      };
      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }
      document.addEventListener('scroll', debounce(storeScroll), { passive: true })
      storeScroll()
    }
  }
</script>

<style>
.header {
  background-color: #FFFFFF !important;
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.05) !important;
}
.header-streaming {
  background-color: #FFFFFF !important;
}
@media screen and (min-width: 1000px) and (max-width: 1390px) {
  .p-h {
    padding-left: 11px !important;
    padding-right: 11px !important;
  }
}
</style>